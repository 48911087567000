import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CheckoutSuccess from './checkout';
import { API_URL } from '../../config/index';

import axios from 'axios';

import activeStep from '../../actions/activeStep';
import productSummary from '../../actions/productSummary';

const CheckoutForm = () => {
  window.addEventListener('pageshow', function (event) {
    var historyTraversal =
      event.persisted ||
      (typeof window.performance != 'undefined' &&
        window.performance.navigation.type === 2);
    if (historyTraversal) {
      // Handle page restore.
      window.location.reload();
    }
  });
  const stripe = useStripe();
  const elements = useElements();
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [show, setShow] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    stripe
      .confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: 'http://localhost:3000/checkout',
        },
        redirect: 'if_required',
      })
      .then(async (res) => {
        if (res.error) {
          alert(res.error.message);
          navigate(-1)
          // dispatch(activeStep(0));
          // navigate('/');
        } else if (res.paymentIntent.status === 'succeeded') {
          let headersList = {
            'Content-Type': 'application/json',
          };

          let bodyContent = JSON.stringify({
            id: product.orderId,
          });

          let reqOptions = {
            url: API_URL + 'order/updatePaymentStatus',
            method: 'PATCH',
            headers: headersList,
            data: bodyContent,
          };

          let response = await axios.request(reqOptions);
          console.log(product);
          console.log(response);
          dispatch(activeStep(0));
          navigate('/final');
          // alert("Payment Successed")
          // setShow(true)
        } else {
          alert(res.paymentIntent.status);
          navigate(-1)
          // dispatch(activeStep(0));
          // navigate('/');
        }
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {/* {show && <CheckoutSuccess />} */}
      {errorMessage && (
        <div style={{ color: '#df1b41', marginTop: '24px' }}>
          {errorMessage}
        </div>
      )}
      <button
        disabled={!stripe}
        className="bg-sky-500 mt-10 ml-[20%] text-white text-lg h-10 w-[50%]  rounded-lg "
        type="submit"
      >
        Pay $1.00
      </button>
    </form>
  );
};

export default CheckoutForm;
