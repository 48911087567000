import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import activeStep from '../../actions/activeStep';
import productSummary from '../../actions/productSummary';
import { API_URL } from '../../config/index';

const CheckoutSuccess = () => {
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const apiUrl = API_URL;

  const [label, setLabel] = useState('Uploading Image');
  const [orderId, setOrderId] = useState('');
  // const [imageUrls,setImageUrls]= useState([])
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    console.log('In');
    console.log(product.finalPrice);
    console.log("product===>",product);
    // if (product.shippingValues.state1 == 'Texas(TX)') {
    //   product.finalPrice =
    //     Math.round((product.finalPrice * 1.0825 + Number.EPSILON) * 100) / 100;
    // }
    let id = '';
    let bill_id = '';
    let ship_id = '';
    let imageUrls = [];
    let userId = '';
    axios.get(apiUrl + 'user/info?email=' + product.user.email).then((res) => {
      userId = res.data.userExist._id;
    });
    const placeOrder = () => {
      let embroidary = false;
      let backing = false;
      let color = false;
      const borderEdge =
        product.border.label === 'Single Felt'
          ? 1
          : product.border.label === 'Double Felt'
          ? 2
          : 3;
      let data;
      // axios.patch( apiUrl+"user/updateShipId", {
      //   email:product.user.email,
      //   shipId:id
      // },config)
      // .then(res => {
      //   if(res.status === 200){
      //     // console.log(res)
      //   }
      // })
      // .catch(e=>{
      //   console.log(e);
      //   console.log(e.data.message);
      // })
      // axios.patch( apiUrl+"user/updateBillId", {
      //   email:product.user.email,
      //   billId:id
      // },config)
      // .then(res => {
      //   if(res.status === 200){
      //       console.log(res)
      //       // setUploadPercentage(0)
      //     }
      //   })
      //   .catch(e=>{
      //     console.log(e);
      //   console.log(e.data.message);
      // })

      // console.log('user');

      if (product.embroidary.label === 'With Embroidery') {
        embroidary = true;
      }
      if (product.backing.label === 'Iron on Backing') {
        backing = true;
      }
      if (product.color.label === '4-6 Colors') {
        color = true;
      }
      // console.log('order');
      // console.log(product);
      if (product.instructions) {
        data = {
          shipId: ship_id,
          billId: bill_id,
          userId,
          notes: product.instructions,
          deliveryTime: product.deliveryMethod,
          category: product.category,
          imageUrls,
          skip:product.skipSample,
          // reasonToPurchase:product.reasonToPurchase,
          // quantity2 : product.quantity.size,
          // quantityOfProduct:product.quantityOfProduct,
          quantity: product.quantity.size,
          wide: product.wide,
          long: product.long,
          totalPrice: product.finalPrice,
          embroidary,
          backing,
          borderEdge,
          color,
        };
      } else {
        data = {
          shipId: ship_id,
          billId: bill_id,
          userId,
          // notes:product.instructions,
          deliveryTime: product.deliveryMethod,
          category: product.category,
          imageUrls,
          skip:product.skipSample,
          // reasonToPurchase:product.reasonToPurchase,
          // quantity2 : product.quantity.size,
          // quantityOfProduct:product.quantityOfProduct,
          quantity: product.quantity.size,
          wide: product.wide,
          long: product.long,
          totalPrice: product.finalPrice,
          embroidary,
          backing,
          borderEdge,
          color,
        };
      }
      // console.log(data);
      axios
        .post(apiUrl + 'order/add', data, config)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data.id);
            dispatch(productSummary({ ...product, orderId: res.data.id }));
            // console.log(product);
            // console.log(res);
            dispatch(activeStep(7));
            navigate('/checkout');
            // dispatch(activeStep(0));
            // navigate('/final');
          }
        })
        .catch((e) => {
          console.log(e);
          console.log(e.data.message);
        });
    };

    const uploadAddress = () => {
      // if(product.shippingValues.apartment.trim().length == 0) {
      //   delete product.shippingValues.apartment;
      // }

      // if(product.shippingValues.company.trim().length == 0) {
      //   delete product.shippingValues.company;
      // }
      const {
        firstname1,
        lastname1,
        address1,
        contact1,
        zip1,
        city1,
        state1,
        country1,
        apartment1,
        company1,
      } = product.shippingValues;
      const {
        firstname2,
        lastname2,
        address2,
        contact2,
        zip2,
        city2,
        state2,
        country2,
        apartment2,
        company2,
      } = product.billingValues;
      // console.log(product.shippingValues);
      // console.log(product.billingValues);
      var same = false;
      if (
        firstname1 === firstname2 &&
        lastname1 === lastname2 &&
        address1 === address2 &&
        contact1 === contact2 &&
        zip1 === zip2 &&
        city1 === city2 &&
        state1 === state2 &&
        country1 === country2 &&
        apartment1 === apartment2 &&
        company1 === company2
      ) {
        same = true;
      }
      if (same) {
        const address = {
          firstname: firstname1,
          lastname: lastname1,
          address: address1,
          contact: contact1,
          zip: zip1,
          city: city1,
          state: state1,
          country: country1,
        };
        if (apartment1) {
          address.apartment = apartment1;
        }
        if (company1) {
          address.company = company1;
        }
        axios.post(apiUrl + 'address/add', address, config).then((res) => {
          if (res.status === 200) {
            ship_id = res.data.id;
            bill_id = res.data.id;
            // console.log(ship_id);
            setUploadPercentage(80);
            setLabel('Placing Order');
            placeOrder();
          }
        });
      } else {
        const address_ship = {
          firstname: firstname1,
          lastname: lastname1,
          address: address1,
          contact: contact1,
          zip: zip1,
          city: city1,
          state: state1,
          country: country1,
        };
        if (apartment1) {
          address_ship.apartment = apartment1;
        }
        if (company1) {
          address_ship.company = company1;
        }
        axios.post(apiUrl + 'address/add', address_ship, config).then((res) => {
          if (res.status === 200) {
            ship_id = res.data.id;
            setUploadPercentage(60);

            const address_bill = {
              firstname: firstname2,
              lastname: lastname2,
              address: address2,
              contact: contact2,
              zip: zip2,
              city: city2,
              state: state2,
              country: country2,
            };
            if (apartment2) {
              address_bill.apartment = apartment2;
            }
            if (company2) {
              address_bill.company = company2;
            }
            axios
              .post(apiUrl + 'address/add', address_bill, config)
              .then((res) => {
                if (res.status === 200) {
                  bill_id = res.data.id;
                  // console.log(bill_id);
                  setUploadPercentage(80);
                  setLabel('Placing Order');
                  placeOrder();
                }
              });
          }
        });
      }
    };

    const config = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        // console.log( `${loaded}kb of ${total}kb | ${percent}%` );
        if (percent < 100) {
          // console.log(uploadPercentage);
          setUploadPercentage(Math.floor(percent / 2));
        }
      },
    };

    if (product.url.length > 0) {
      const imgUpload = new FormData();
      for (let i = 0; i < product.url.length; i++) {
        imgUpload.append('image', product.url[i]);
      }
      axios.post(apiUrl + 'order/img/upload', imgUpload, config).then((res) => {
        if (res.status === 200) {
          imageUrls = res.data.imageUrls;
          setUploadPercentage(50);
          setLabel('Processing Order');
          uploadAddress();
          // placeOrder();
        }
      });
    } else {
      setUploadPercentage(50);
      setLabel('Processing Order');

      uploadAddress();
      // placeOrder();
    }
  }, []);

  const handleClick = (e) => {
    dispatch(activeStep(0));
    navigate('/');
  };

  return (
    <div
      id="container"
      className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center  md:justify-center md:pt-2 z-50"
    >
      <div
        className={`bg-white ml-10 md:ml-0 p-2 rounded-2xl w-[80%] md:w-[50%] ${
          success ? 'lg:w-[60%]' : 'lg:w-[40%]'
        } `}
      >
        <p className="mt-4 mb-8 text-center font-semibold text-2xl">Processing</p>
        {!success && (
          <div className="text-lg text mx-auto h-20 w-[80%] rounded-xl">
            <div className="my-2">
              <svg
                role="status"
                className="inline mr-3 w-5 h-5 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              {label}...
            </div>
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-200">
              <div
                className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                style={{ width: `${uploadPercentage}%` }}
              >
                {' '}
                {uploadPercentage}%
              </div>
            </div>
          </div>
        )}
        {/* { success && <div className="text-center">
        <p className="text-[#029e02] mt-2">Order Placed Successful.</p>
        <p>Your order might take some time to process.</p>
        <p>Check your order status at your profile after about 10mins.</p>
        <p>
          To provide more information email us at support@periapperal.com
          <ion-icon name="mail" className="text-xl"></ion-icon>
           
        </p>
        <p className="text-gray-500 cursor-pointer hover:text-gray-800  focus:text-gray-800 duration-1000" onClick={()=>navigator.clipboard.writeText(orderId)}>Order Id : {orderId}</p>
       

        <button className="cursor-pointer mt-5 mb-2 border-2 p-2  hover:text-gray-600 border-slate-200 hover:font-medium" onClick={handleClick}>Continue shopping...</button>
      </div>
      } */}
        <p className="my-4 text-center text-md text-red-500 ">
          Do not close this window until processing*
        </p>
        {/* {!success && <p className="my-4 text-center text-md text-red-500 ">Do not close this window until order placed*</p>} */}
      </div>
    </div>
  );
};

export default CheckoutSuccess;
