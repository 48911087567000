import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import PreviewImages from "./PreviewImages";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const ImageViewer = ({ images }) => {
  const [preview, setPreview] = React.useState(null);
  // const [navigation, setNavigation] = React.useState(false);
  const [my_swiper, set_my_swiper] = React.useState({});
  const [toggle, setToggle] = React.useState(false);
  return (
    <>
      <Swiper
        cssMode={true}
        // navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className=" mySwiper h-[250px] w-[200px]  relative "
        onInit={(ev) => {
          set_my_swiper(ev);
        }}
      >
        {images?.length > 1 && toggle && (
          <div
            className="absolute top-1/2 p-1 bg-white rounded-full z-10 shadow-lg cursor-pointer"
            onMouseEnter={() => {
              setToggle(true);
            }}
            onClick={() => my_swiper.slidePrev()}
            onMouseLeave={() => setToggle(false)}
          >
            <AiOutlineLeft className="text-[#6b7280] w-6 h-6 font-bold" />
          </div>
        )}
        {images.map((el, i) => (
          <SwiperSlide
            className="h-[250px] w-[200px]"
            key={i}
            onClick={() => setPreview(images)}
            onMouseEnter={() => {
              setToggle(true);
            }}
            onMouseLeave={() => setToggle(false)}
          >
            <img
              src={el}
              alt="Patches"
              className="object-contain w-full h-full "
            />
          </SwiperSlide>
        ))}
        {images?.length > 1 && toggle && (
          <div
            className="absolute top-1/2 p-1 bg-white rounded-full z-10 shadow-lg cursor-pointer right-0"
            onClick={() => my_swiper.slideNext()}
            onMouseEnter={() => {
              setToggle(true);
            }}
            onMouseLeave={() => setToggle(false)}
          >
            <AiOutlineRight className="text-[#6b7280] w-6 h-6 font-bold" />
          </div>
        )}
      </Swiper>
      {preview !== null && (
        <PreviewImages setPreview={setPreview} preview={preview} />
      )}
    </>
  );
};

export default ImageViewer;
