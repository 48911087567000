import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { initialStateProductSummary } from "../../../../reducers/productSummaryReducer";
import productSummary from "../../../../actions/productSummary";
import { convertText } from "../../../../utils/tools";

const OrderDetail = ({ showMessage, setShowMessage }) => {
  const product = useSelector((state) => state.productSummary);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      {showMessage && (
        <div
          onClick={() => {
            navigate("/");
            dispatch(productSummary({ ...initialStateProductSummary }));
          }}
          className="bg-[#180e0e7e] inset-0 fixed z-[99999] flex items-center justify-center"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-[90%] mx-auto bg-white md:w-[450px] shadow-lg rounded-md"
          >
            <div className="flex items-center p-2 bg-gray-50  justify-between border-b-black border-[1px] border-b ">
              <p className="flex items-center justify-center">
                <TiTick color="green" size={25} />
                <span className="ml-2 text-xl font-semibold text-green-600">
                  Item has been added to your Cart
                </span>
              </p>
              <AiOutlineClose
                size={25}
                cursor="pointer"
                onClick={() => {
                  setShowMessage(false);
                  navigate("/");
                }}
              />
            </div>
            <div className="flex items-center w-full p-4 gap-x-5">
              <img
                src={URL.createObjectURL(product.editImage)}
                className="w-[30%]"
                alt=""
              />

              <div className="self-start px-2 ">
                <p className="font-bold text-[18px] leading-[120%]">
                  {convertText(id)} patches
                </p>
                <p>Height (in inches) : {product.long}</p>
                <p>Width (in inches): {product.wide}</p>
                <p>Qty : {product.quantity.size}</p>
                <p>Price : ${product.finalPrice.toFixed(2)}</p>
              </div>
            </div>
            <div className="flex items-center px-4 pb-4 gap-x-5">
              <button
                type="button"
                onClick={() => {
                  navigate("/patches");
                  dispatch(productSummary({ ...initialStateProductSummary }));
                }}
                className=" border-black border-[0.5px]  p-1 w-1/2 rounded-sm"
              >
                Continue Shopping
              </button>
              <button
                onClick={() => {
                  navigate("/cart");
                  dispatch(productSummary({ ...initialStateProductSummary }));
                }}
                className="bg-[#38A217] border-[#38A217] hover:bg-[#308317] border-[0.5px] text-white p-1 w-1/2 rounded-sm"
              >
                View Cart {">>"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderDetail;
