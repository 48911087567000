const initialState = {
    country:"",
    firstName:"",
    lastName:"",
    companyName:"",
    address:"",
    apartment:"",
    city:"",
    zip:"",
    phone:""

  };
  const Details = (state = initialState, action) => {
    switch (action.type) {
      case 'DETAILS':
        return action.payload;
      default:
        return state;
    }
  };
  
  export default Details;
  