import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import { FiEdit } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../config";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import global from "../../actions/global";
import { Spin, message } from "antd";
import { MdWarning } from "react-icons/md";
import ReCaptcha from "../Recaptcha";

const Login = () => {
  // const globalData = useSelector(state=>state.global)
  // const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isVerified, setIsVarified] = useState(false);
  const [isVerificationLinkExpired, setIsVerificationLinkExpired] =
    useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
    const to = Cookies.get('token');
    if(to){
     navigate('/') 
    }
  },[])

  const loginHandler = async (e) => {
    e.preventDefault();
    if (isVerified) {
      try {
        if (showWarning) {
          setShowWarning(false);
        }
        setIsProcessing(true);
        const { data } = await axios.post(API_URL + "auth/login", {
          email,
          password,
        });
        if (data?.message === "SUCCESS") {
          Cookies.set("token", data?.token, { expires: 1 / 24 });
          Cookies.set("email", email, { expires: 1 / 24 });
          message.success("Logged In successfully");
          try {
            if (data?.token) {
              const resp = await axios.get(`${API_URL}auth/myself`, {
                headers: { Authorization: `Bearer ${data?.token}` },
              });
              Cookies.set("authUserId", resp.data.userDetails._id);
              // console.log(resp?.data);
            }
          } catch (err) {
            // console.log(err);
            message.error(err?.response?.data?.message);
          }
          setIsProcessing(false);
          setShowWarning(false);
          setEmail("");
          setPassword("");
          navigate("/");
        }
        if (data?.message === "Not Verfied User") {
          setIsVerificationLinkExpired(true);
        }
        // console.log(data)
        setIsProcessing(false);
      } catch (err) {
        // console.log(err);

        if (
          err?.response?.data?.message === "Incorrect Password" ||
          err?.response?.data?.message === "User Not Exist..."
        ) {
          setShowWarning(true);
        } else {
          message.error(err?.response?.data?.message);
        }
        setIsProcessing(false);
        setEmail("");
        setPassword("");
      }
    } else {
    }
  };

  const forgetPasswordHandler = async () => {
    if (email) {
      setIsProcessing(true);
      try {
        const { data } = await axios.post(`${API_URL}auth/forgotpassword`, {
          email,
        });

        // console.log(data);
        if (data.message === "Email Sent...") {
          message.success("Email has been sent to reset password ");
        }
        setIsProcessing(false);
      } catch (err) {
        // console.log(err);
        message.error("Email Not Found");
        setIsProcessing(false);
      }
      setIsProcessing(false);
    } else {
      message.error("Enter Email");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const reVerifyHandler = async () => {
    try {
      setIsProcessing(true);
      const { data } = await axios.post(`${API_URL}auth/requestVerification`, {
        email,
      });
      setEmail("");
      setPassword("");
      // console.log(data);
      setIsProcessing(false);
      message.success("Verification email sent to your given email Id.");
      setIsVerificationLinkExpired(false);
    } catch (err) {
      message.error(err?.response?.data?.message);
      setIsProcessing(false);
      // console.log(err)
    }
  };

  return (
    <>
      <Navbar />
      <div className="min-h-[calc(100vh-56px)] sm:min-h-[calc(100vh-96px)] ">
        <form
          onSubmit={loginHandler}
          className=" py-4 pt-10  sm:px-20   w-[90%] sm:w-[80%] md:w-[60%] lg:w-[50%] max-w-[764px] mx-auto"
        >
          {showWarning && (
            <div className="border border-[#D70F22] text-justify flex items-center mb-4">
              <div className=" bg-red-200 p-4">
                <MdWarning size={50} color="#D70F22" />
              </div>
              <p className="text-[14px] p-4">
                The information you entered doesn't match our records. Please
                try again or click Forgot Password to reset.
              </p>
            </div>
          )}
          {isVerificationLinkExpired && (
            <>
              <div className="border border-[#D70F22] text-justify flex items-center mb-4">
                <div className=" bg-red-200 p-4 self-stretch items-stretch">
                  <MdWarning size={50} color="#D70F22" />
                </div>
                <p className="text-[14px] p-4">
                  Email address is not verified. Please click the below link to
                  resend the verification email
                </p>
              </div>
              <button
                type="button"
                disabled={isProcessing}
                onClick={reVerifyHandler}
                className="bg-blue-600 text-white px-4 py-2 rounded-md block mx-auto hover:bg-blue-700 disabled:bg-blue-400"
              >
                Verify Email
              </button>
            </>
          )}
          <div className="flex items-center ">
            <FiEdit color="#383838" />
            <p className="ml-3 text-2xl text-[#383838]">Login Account</p>
          </div>

          <input
            type="email"
            className=" border border-[#706a6a] px-3 py-3 w-full mt-8 rounded-md text-xl outline-none"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <input
            type="password"
            className=" border border-[#706a6a] px-3 py-3 w-full mt-4 rounded-md text-xl outline-none"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <p
            onClick={() => forgetPasswordHandler()}
            className="text-[#383838] cursor-pointer mt-3 text-right"
          >
            Forgot Password
          </p>
          <div className="mt-4 w-full">
            <ReCaptcha
              OnClick={(isvarify) => setIsVarified(isvarify)}
              reset={isProcessing}
            />
          </div>
          <button
            disabled={isProcessing || !isVerified}
            type="submit"
            className={`mt-6 bg-blue-600 disabled:bg-blue-400 text-white text-xl font-semiblod px-8 py-3 rounded-md hover:bg-blue-700 transition-all ease-out duration-100 block mx-auto`}
          >
            Login
          </button>

          <p className="text-center text-xl mt-3">
            New User ?
            <Link to="/register">
              <span className="text-blue-500 cursor-pointer fontt-bold">
                {" "}
                Sign Up
              </span>
            </Link>
          </p>
        </form>
      </div>
      <Footer />
      {isProcessing && (
        <div className="fixed top-0 left-0 right-0 bottom-0  flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default Login;
