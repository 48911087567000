import React, { useEffect, useState } from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import axios from 'axios'
import { API_URL } from '../../config'
import Cookies from 'js-cookie'
import { AiOutlineRight } from 'react-icons/ai'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom'

const MyOrders = () => {
    const [OrderData, setOrderData] = useState([]);
    const [processing, setProcessing] = useState(false)
    const [showDetailsList, setShowDetailsList] = useState([])
    const nav =useNavigate();
    useEffect(()=>{
        const token = Cookies.get('token');
        if(!token){
         nav('/')
        }
       },[])

    useEffect(() => {
        (async () => {
            try {
                setProcessing(true)
                const token = Cookies.get('token');
                const { data } = await axios.get(`${API_URL}order/history`,
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                console.log("history", data)
                setOrderData([...data]);
                setProcessing(false)
            } catch (err) {
                // console.log(err)
                setProcessing(false)
            }
            setProcessing(false)
        })()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    
    return (
        <>
            <Navbar />
            <div className='bg-gray-50'>
                <div className='min-h-screen w-[90%] mx-auto '>
                    <p className=' text-2xl font-semibold pt-5 '>My Orders</p>
                    <div className='w-full '>
                         {OrderData.length===0 && <p className='text-center mt-16 text-xl'>No order history available</p>}  
                        {OrderData.map((item, index) => (
                            <div key={index}>
                                <div
                                    key={item?._id}
                                    className={`bg-white shadow-md mt-10 flex justify-between border-l-8 border-l-blue-800 border-y border-y-gray-300`}
                                >

                                    <p className='text-[18px] py-3 px-3'>
                                        <span className='font-bold '>Online Order</span>
                                        <span className='ml-2'>{item?.orderNumber}</span>
                                    </p>

                                    <p className='text-[16px] py-3 px-3 '>
                                     Order Date{" "}:{" "}  {item?.date}
                                    </p>

                                    <p className='text-right text-[16px] text-blue-600 text-xl p-3 font-bold cursor-pointer'>
                                        <span
                                            className='flex items-center justify-end'
                                            onClick={() => {
                                                const index = showDetailsList.indexOf(item?.orderNumber);
                                                if (index !== -1) {
                                                    showDetailsList.splice(index, 1);
                                                } else {
                                                    showDetailsList.push(item?.orderNumber);
                                                }
                                                setShowDetailsList([...showDetailsList])
                                            }}>
                                            See details
                                            <AiOutlineRight />
                                        </span>
                                    </p>

                                </div>

                                {showDetailsList.includes(item?.orderNumber) &&
                                    <div className='border-y-gray-300  shadow-md'>
                                        {item.items.map((innerItem, ind) => <div key={ind} className='bg-white flex py-4 border border-y-gray-300 justify-between '>

                                            <div className='flex '>
                                                <p className='px-5 '>
                                                    <img
                                                        src={innerItem.imageUrls[0]}
                                                        width={50}
                                                        height={50}
                                                        className=''
                                                        alt=""
                                                    />
                                                </p>

                                                <div className=" text-[14px] leading-[120%]  ">
                                                    <p className="text-start font-bold">
                                                        Chenille patches
                                                    </p>
                                                    <p className="text-start">
                                                        Length: {innerItem?.long}, Width: {innerItem?.wide}
                                                    </p>
                                                    <p className='text-start'>
                                                        {innerItem?.embroidary ? "With Embroidery" : "No Embroidery"}, {innerItem?.backing ? "Iron on Backing" : "No Backing"},   {innerItem?.borderEdge === 1 ? "Single Felt" : innerItem?.borderEdge === 2 ? "Double Felt" : "Triple Felt"}
                                                    </p>
                                                    <p className='text-start'>
                                                        {!innerItem?.color ? "1-3 Color(s)" : "4-6 Colors"}
                                                    </p>
                                                    <p className=''>Qty: {innerItem?.quantity}</p>
                                                </div>
                                            </div>


                                            <p className=" text-center  pr-5 self-center">${innerItem?.totalPrice?.toFixed(2)}</p>
                                        </div >)}
                                        <div className='bg-white px-5 py-4'>
                                            <p className=' flex items-center justify-between '>
                                                <span className=''>Subtotal :</span>
                                                <span>${item?.subTotal}</span>
                                            </p>
                                            <div className='flex items-center justify-between '>
                                                <p className=''>
                                                    <span>Shipping{" "}
                                                    </span>
                                                    <span className='capitalize'>

                                                        {`(${item?.shippingMethod})`} :
                                                    </span>
                                                </p>

                                                <span>${item?.shippingCharges}</span>
                                            </div>
                                            <p className='flex items-center justify-between '>
                                                <span>Tax :</span>
                                                <span>${item?.tax}</span>
                                            </p>
                                            <p className='flex items-center justify-between '>
                                                <span>Total :</span>
                                                <span>${item?.orderTotal}</span>
                                            </p>

                                        </div>
                                    </div>
                                }

                            </div>

                        ))}


                    </div>
                    <div className='pb-10'></div>
                </div >
            </div>

            <Footer />
            {processing && (
                <div className="fixed top-0 left-0 right-0 bottom-0  flex items-center justify-center">
                    <Spin size="large" />
                </div>
            )}
        </>
    )
}

export default MyOrders
