/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import productSummary from "../../actions/productSummary";
import { FcCheckmark } from "react-icons/fc";
import { RiDeleteBinLine } from "react-icons/ri";
const UploadImage = ({ setImageUrl }) => {
  const inputRef = React.useRef(null);
  const [dragActive, setDragActive] = React.useState(false);
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else {
      setDragActive(false);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // console.log(e.target.files);
    }
  };
  const onButtonClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };
  const handelUploadImage = (e) => {
    const { files } = e.target;

    if (files.length !== 0) {
      dispatch(
        productSummary({
          ...product,
          originalImage: files[0],
          editImage: files[0],
        })
      );
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      setImageUrl(e.target.result);
    };

    reader.readAsDataURL(files[0]);
  };
  return (
    <div className="flex flex-col mt-6">
      <div className="text-xl text-gray-700 uppercase tracking-[0.25em]">Upload artwork</div>
      <div className="flex flex-col ">
        <div className="relative">
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            onChange={handelUploadImage}
            required
            style={{ position: "absolute", width: "1px", height: "1px", opacity: 0, top: "100%", left: "12%" }}
            accept="image/png, image/jpeg,image/jpg,image/ai,image/eps,image/psd,image/tif,image/tiff,image/pdf"

          />
          <button
            onClick={onButtonClick}
            type="button"
            className="w-[274px] self-start  hover:bg-blue-700 bg-blue-500 py-1 text-white transition-all duration-300 ease-in-out px-5  text-xl  rounded-md whitespace-nowrap mt-3"
          >
            Browse Files
          </button>
        </div>
        <label htmlFor="input-file-upload " className="">
          <div className="w-full">
            <p className="w-full text-sm">
              Allowed extensions *.png *.jpg *.jpeg *.webp *.svg
            </p>
            <p className="w-full text-sm">
              Max file size 25 MB
            </p>
          </div>
        </label>
        {dragActive && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              borderRadius: "1rem",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
      {product.editImage && (
        <p className="font-semibold text-green-600">{product.editImage.name}</p>
      )}
      {product.editImage && (
        <div className="flex items-center justify-between w-full max-w-sm mt-2">
          <img
            src={URL.createObjectURL(product.editImage)}
            className="object-contain w-16 h-16"
            alt=""
          />
          <div className="flex justify-end w-full">
            <FcCheckmark size={25} />
            <RiDeleteBinLine
              cursor="pointer"
              size={25}
              onClick={() => {
                dispatch(
                  productSummary({
                    ...product,
                    editImage: null,
                    originalImage: null,
                  })
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
