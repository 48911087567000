import React from "react";
import { AiFillCaretLeft } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";

const Navigation = () => {
  const { id } = useParams();
  return (
    <div className="flex flex-col items-center justify-center mt-10 mb-20 sm:flex-row gap-y-5 ">
      <Link to={`/patch/${id}?page=upload`}>
        <button
          className="flex items-center px-12 text-2xl text-white bg-green-700 rounded-full hover:bg-green-600 w-60 h-14"
          type="button"
        >
          <AiFillCaretLeft className="mr-2" />
          <span>Previous</span>
        </button>
      </Link>
      <button
        className="px-12 ml-2 text-2xl text-white rounded-full bg-sky-700 hover:bg-sky-600 h-14 w-60"
        type="submit"
      >
        <span>Continue</span>
      </button>
    </div>
  );
};

export default Navigation;
