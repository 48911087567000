import React, { useEffect } from "react";
import "./getstarted.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Progressbar from "../../components/Progressbar/Progressbar";
import dog from "../../components/images/dog.jpg";
import Front from "../../components/images/Front.png";
import Jackets from "../../components/images/Jackets.png";
import Footer from "../../components/footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import activeStep from "../../actions/activeStep";
import productSummary from "../../actions/productSummary";

export default function Getstarted() {


  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    navigate("/")
    window.scrollTo(0, 0);
  }, []);

  const handleStep1 = (prod) => {
    const name = prod;
    let img = "";
    switch (prod) {
      case "Chennile Patches":
        img = dog;
        break;
      case "Garments":
        img = Front;
        break;
      case "Jackets":
        img = Jackets;
        break;
      default:
        img = Jackets;
    }
    const n = name.split(" ");

    const category = n[n.length - 1];
    dispatch(productSummary({ ...product, name, img, category }));
    dispatch(activeStep(2));
    // if (prod == "Hoodies") {
      // navigate("/hoodies");
    // } else if (prod == "Jackets") {
      // navigate("/jackets");
    // } else {
      navigate("/info");
    // }
  };

  return (
    <div className="bg-brand text-black">
      {/*-----navbar-----*/}
      <div className="container h-40 bg-white  lg:mx-auto lg:px-2 ">
        <Navbar />
      </div>
      <div className="flex flex-col" id="steps_container">
        <div className=" flex flex-row xl:mx-40">
          <Progressbar />
        </div>

        <div className="flex flex-row  md:w-2/12 h-32 mx-20 my-10">
          <div className="flex flex-col  my-5">
            <span className="mx-auto my-auto font-sans font-semibold text-2xl lg:text-3xl xl:text-4xl  uppercase">
              Step-1
            </span>
            <div className="text-sm my-auto text-gray-500 font-medium lg:text-lg ">
              Select The Product
            </div>
          </div>
        </div>

        <div className="sm:flex sm:flex-row lg:h-screen6">
          <div className="sm:flex sm:flex-row  w-full ">
            <div
              onClick={() => handleStep1("Chennile Patches")}
              className="w-full mb-5 sm:mb-0 sm:w-1/3 h-"
            >
              <div className="flex flex-col  bg-gray-200 mx-auto rounded-xl justify-between h-4/6 sm:h-5/6 lg:h-full w-4/5">
                <div className="uppercase h-auto lg:h-1/3 sm:w-2/4 mx-auto ">
                  <div className="lg:text-3xl text-xl font-extrabold my-6 md:my-5 lg:my-14 xl:mx-10">
                    <p id="Chennile Patches" className="cursor-pointer ">
                      Chenille patches
                    </p>
                  </div>
                </div>

                <div className="uppercase mb-4 h-1/4 sm:h-1/2">
                  <img
                    className="h-48 sm:h-full lg:h-full w-5/6 mx-auto xl:w-4/6"
                    src={dog}
                  ></img>
                </div>
              </div>
            </div>

            <div
              onClick={() => handleStep1("Garments")}
              className="w-full mb-5 sm:mb-0 sm:w-1/3 h-"
            >
              <div className="flex flex-col bg-gray-200 mx-auto rounded-xl justify-between h-4/6 sm:h-5/6 lg:h-full w-4/5">
                <div className="uppercase h-auto lg:h-1/3 sm:w-2/4 mx-auto ">
                  <div className="lg:text-3xl text-xl font-extrabold my-6 md:my-5 lg:my-14 xl:mx-10">
                    <p id="Hoodies" className="cursor-pointer">
                      Garments
                    </p>
                  </div>
                </div>

                <div className="uppercase mb-4 h-1/4 sm:h-1/2">
                  <img
                    className="h-48 sm:h-full lg:h-full w-5/6 mx-auto xl:w-4/6"
                    src={Front}
                  ></img>
                </div>
              </div>
            </div>

            <div
              onClick={() => handleStep1("Jackets")}
              className=" w-full mb-5 sm:mb-0 sm:w-1/3 h-"
            >
              <div className="flex flex-col bg-gray-200 mx-auto rounded-xl justify-between h-4/6 sm:h-5/6 lg:h-full w-4/5">
                <div className="uppercase h-auto lg:h-1/3 sm:w-2/4 mx-auto ">
                  <div className="lg:text-3xl text-xl font-extrabold my-6 md:my-5 lg:my-14 xl:mx-10">
                    <p id="Jackets" className="cursor-pointer ">
                      Jackets
                    </p>
                  </div>
                </div>

                <div className="uppercase mb-4 h-1/4 sm:h-1/2">
                  <img
                    className=" h-48 sm:h-full lg:h-full w-5/6 mx-auto xl:w-4/6"
                    src={Jackets}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24"></div>
      <Footer />
    </div>
  );
}
