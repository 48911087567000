import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserInfo, postUserInfo, patchUserInfo } from "../../api/form";
import Navbar from "../navbar/Navbar";
import Progressbar from "../../components/Progressbar/Progressbar";
import { useSelector, useDispatch } from "react-redux";
import activeStep from "../../actions/activeStep";
import productSummary from "../../actions/productSummary";
import { useForm } from "react-hook-form";
import Footer from "../footer/Footer";
import axios from "axios";
import { API_URL } from "../../config/index";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import Cookies from "js-cookie";
import ReCaptcha from "../Recaptcha";

export default function Information() {
  const apiUrl = API_URL;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [customQuantity, setCustomQuantity] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const active = useSelector((state) => state.activeStep);
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  /////////////////////////////////////////////////////////////////////////////////

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isVerified, setIsVarified] = useState("");

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(API_URL + "auth/login", {
        email,
        password,
      });
      if (data.message === "SUCCESS") {
        Cookies.set("token", data?.token, { expires: 1 / 24 });
        Cookies.set("email", email, { expires: 1 / 24 });
        if (data?.token) {
          const resp = await axios.get(`${API_URL}auth/myself`, {
            headers: { Authorization: `Bearer ${data?.token}` },
          });
          Cookies.set("authUserId", resp.data.userDetails._id);
          //  console.log(resp.data)
        }
        setEmail("");
        setPassword("");
        // navigate("/");
        dispatch(activeStep(3));
        navigate("/upload");
      }
      // console.log(data);
    } catch (err) {
      // console.log(err);
      alert("Wrong Email or password");
      setEmail("");
      setPassword("");
    }
  };

  const forgetPasswordHandler = async () => {
    if (email) {
      try {
        const { data } = await axios.post(`${API_URL}auth/forgotpassword`, {
          email,
        });

        // console.log(data);
        if (data.message === "Email Sent...") {
          alert("Email has been sent to reset password ");
        }
      } catch (err) {
        // console.log(err);
        alert("Email Not Found");
      }
    } else {
      alert("Enter Email");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    (async () => {
      const token = Cookies.get("token");
      //  console.log(token);

      if (token) {
        const { data } = await axios.get(`${API_URL}auth/myself`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // console.log(data);

        if (data?.userDetails?.email) {
          setIsLoggedIn(true);
          setValue("firstname", data?.userDetails?.firstname);
          setValue("lastname", data?.userDetails?.lastname);
          setValue("email", data?.userDetails?.email);
        }
      }
    })();
  }, []);

  //   useEffect(() => {
  //     console.log(active)
  //    if(active!==2){
  //    navigate('/select')
  //     }
  //  }, []);

  const set = (label) => {
    return ({ target: { value } }) => {
      setValue(label, value);
    };
  };

  const checkUser = async () => {
    const email = localStorage.getItem("email");
    if (email) {
      const userInfo = await getUserInfo(email);
      if (userInfo.status === 200) {
        // console.log(userInfo.data)
        const { firstname, lastname, email, contact, _id } =
          userInfo.data.userExist;
        setValue("firstname", firstname);
        setValue("lastname", lastname);
        setValue("email", email);
        setValue("contact", contact);
        Cookies.set("userId", _id);
      }
    }
  };

  useEffect(() => {
    checkUser();
    window.scrollTo(0, 0);
  }, [product.category, product.name]);

  const onSubmit = async (data) => {
    // e.preventDefault();
    if (isVerified) {
      const {
        firstname,
        lastname,
        email,
        contact,
        customQuantity,
        quantityOfProduct,
        type,
      } = data;
      let userInfo = "";

      userInfo = await getUserInfo(email.replace('+', '%2B'));
      if (userInfo.status === 200) {
        userInfo = await patchUserInfo(data);
        // console.log("patch",userInfo?.data)
        Cookies.set("userId", userInfo?.data?.id);
      } else {
        userInfo = await postUserInfo(data);
        // console.log(userInfo?.data?.id)
        Cookies.set("userId", userInfo?.data?.id);
      }

      if (customQuantity >= 50) {
        dispatch(
          productSummary({
            ...product,
            quantityOfProduct: customQuantity,
            user: data,
          })
        );
      } else {
        dispatch(productSummary({ ...product, quantityOfProduct, user: data }));
      }

      localStorage.setItem("email", email);
      // console.log(product);

      if (product.name === "Chennile Patches") {
        dispatch(activeStep(3));
        navigate("/upload");
      } else {
        userInfo = await getUserInfo(email);
        let quantity = "";
        if (customQuantity >= 50) {
          quantity = customQuantity;
        } else {
          quantity = quantityOfProduct;
        }

        const data = {
          userId: userInfo.data.userExist._id,
          category: product.name,
          quantity,
          subCategory: type,
          // need to add type for jackets and garments
        };

        axios
          .post(apiUrl + "order/emailOrder/", data)
          .then((res) => {
            if (res.status === 200) {
              // console.log(res.data);
              // dispatch(activeStep(0));
              navigate("/final");
            }
          })
          .catch((e) => {
            // console.log(e);
            // console.log(e.data);
          });
      }
    }
  };

  const jacketTypes = [
    "Varsity",
    "Poly Satin",
    "Leather",
    "Poly Twill",
    "Cotton Twill",
  ];

  const hoodiesTypes = ["Hoodies", "Sweatshirt"];



  return (
    <>
      <Navbar />
      <div className="text-black bg-brand ">
        {/* <div className="container h-40 px-1 bg-white lg:mx-auto lg:px-2">
        <Navbar />
      </div> */}
        <div className="flex flex-col" id="steps_container">
          <div className="flex flex-row xl:mx-40">
            <Progressbar />
          </div>

          <div className="flex flex-row mx-5 mt-10 mb-0 md:mx-20 md:my-0 ">
            <div className="flex flex-col w-2/5 my-10 md:w-2/6 ">
              <span className="mx-auto my-auto font-sans text-xl font-semibold uppercase md:text-2xl lg:text-3xl xl:text-4xl">
                Step-2
              </span>
              <div className="mx-auto my-auto text-xs text-gray-500 capitalize md:text-sm w-3/2 lg:text-lg ">
                Enter Your Information
              </div>
            </div>
            <div className="flex flex-col w-3/5 h-32 mx-auto mt-5 md:w-1/2 ">
              <span className="my-4 ml-5 text-lg font-bold uppercase w-10/11 md:ml-0 md:text-2xl">
                Get Started with your custom{" "}
                {product.category ? product.category : product.name}
              </span>
              {/* <span className="ml-5 text-sm text-gray-500 md:ml-0 md:w-4/5 "> For participating in the 7th Annual Charleston Sports Fest Given
              this day 13th of June in the year 2020 
            </span> */}
            </div>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center gap-y-5 "
          >
            {!isLoggedIn &&
              (product.name === "Chennile Patches" || active === 1) && (
                <>
                  <div className="w-full ">
                    <button
                      type="button"
                      onClick={() => setShowPopUp(true)}
                      className="self-start block px-10 py-2 mx-auto text-2xl font-semibold text-white transition-all duration-300 ease-in-out bg-blue-600 rounded-md hover:bg-blue-700"
                    >
                      Login{" "}
                    </button>
                  </div>
                  <p className="text-xl font-bold ">Or </p>
                </>
              )}

            <div
              className={` flex flex-col mx-auto w-[90%]  ${
                isLoggedIn ? "w-3/2  mx-auto" : "sm:w-[70%] md:mx-[unset]"
              }  `}
            >
              <p className="text-2xl font-bold text-center ">Enter Details</p>
              <div className="flex items-center mx-auto mt-5 uppercase gap-x-2 ">
                <span className=" md:text-md font-sans w-[100px] inline-block font-medium">
                  First Name
                </span>
                <div className="h-10 my-2 ">
                  <input
                    className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
                    name="firstname"
                    type="text"
                    placeholder="First Name"
                    onChange={set("firstname")}
                    {...register("firstname", {
                      required: "First Name is required",
                      minLength: {
                        value: 2,
                        message: "Atleast 2 characters",
                      },
                    })}
                  ></input>
                  <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
                    {errors?.firstname?.message}
                  </p>
                </div>
              </div>

              <div className="flex items-center mx-auto my-5 uppercase gap-x-2">
                <span className=" font-medium md:text-md font-sans w-[100px] inline-block">
                  Last Name
                </span>
                <div className="h-10 my-2 ">
                  <input
                    className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
                    name="lastname"
                    type="text"
                    placeholder="Last Name"
                    onChange={set("lastname")}
                    {...register("lastname", {
                      required: "Last Name is required",
                      minLength: {
                        value: 2,
                        message: "Atleast 2 characters",
                      },
                    })}
                  ></input>
                  <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
                    {errors?.lastname?.message}
                  </p>
                </div>
              </div>

              <div className="flex items-center mx-auto uppercase gap-x-2">
                <span className=" font-medium md:text-md font-sans w-[100px] inline-block">
                  Email
                </span>
                <div className="h-10 my-2 ">
                  <input
                    className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={set("email")}
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                        message: "Enter a valid email",
                      },
                    })}
                  ></input>
                  <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
                    {errors?.email?.message}
                  </p>
                </div>
              </div>

              <div className="flex items-center mx-auto my-5 uppercase gap-x-2">
                <span className=" font-medium md:text-md font-sans w-[100px] inline-block">
                  Phone
                </span>
                <div className="h-10 my-2 ">
                  <input
                    className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
                    name="contact"
                    type="number"
                    placeholder="Phone"
                    onChange={set("contact")}
                    {...register("contact", {
                      required: "Contact is required",
                      minLength: {
                        value: 10,
                        message: "Min 10 characters",
                      },
                      maxLength: {
                        value: 11,
                        message: "Max 11 characters",
                      },
                    })}
                  ></input>
                  <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
                    {errors?.contact?.message}
                  </p>
                </div>
              </div>

              {/* <div className="flex flex-col mx-auto uppercase">
              <span className="mx-2 font-sans md:font-black md:text-md">
                Reason to purchase
              </span>
              <div className="h-10 my-2 ">
                <select
                  className="block w-full px-4 py-2 pr-8 leading-tight bg-gray-200 border border-white rounded-full shadow appearance-none hover:border-gray-200 focus:outline-none focus:shadow-outline"
                  name="reasonToPurchase"
                  {...register("reasonToPurchase",{
                                        required: "This field is required" 
                                    })} 
                >
                  <option value="" selected disabled hidden>
                    ---Choose here---
                  </option>
                  <option>options</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>
                <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">{errors.reasonToPurchase?.message}</p>
              </div>
            </div> */}
              {product.name === "Jackets" && (
                <>
                  <div className="flex items-center mx-auto my-5 uppercase gap-x-2">
                    <span className="self-start font-medium md:text-md font-sans w-[100px] inline-block">
                      Select Type
                    </span>
                    <div className=" w-[202px]">
                      <div className="ml-2">
                        {jacketTypes.map((jac, index) => (
                          <div
                            key={jac}
                            className={`flex gap-x-4  ${
                              index === jacketTypes.length - 1 ? "mb-0" : "mb-3"
                            } `}
                          >
                            <input
                              checked={jac === watch("type")}
                              value={jac}
                              type="radio"
                              className=""
                              name="type"
                              onChange={() => setValue("type", jac)}
                              {...register("type", {
                                required: "Type is required",
                              })}
                            />
                            <label className="capitalize">{jac}</label>
                          </div>
                        ))}
                        <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
                          {errors?.type?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {product.name === "Garments" && (
                <>
                  <div className="flex items-center mx-auto my-2 uppercase gap-x-2">
                    <span className="self-start font-medium md:text-md font-sans w-[100px] inline-block">
                      Select Type
                    </span>
                    <div className=" w-[202px]">
                      <div className="ml-2">
                        {hoodiesTypes.map((hod, index) => (
                          <div
                            key={hod}
                            className={`flex gap-x-4  ${
                              index === hoodiesTypes.length - 1
                                ? "mb-0"
                                : "mb-3"
                            } `}
                          >
                            <input
                              checked={hod === watch("type")}
                              value={hod}
                              type="radio"
                              className=""
                              name="type"
                              onChange={() => setValue("type", hod)}
                              {...register("type", {
                                required: "Type is required",
                              })}
                            />
                            <label className="capitalize">{hod}</label>
                          </div>
                        ))}
                        <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
                          {errors?.type?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {!customQuantity && !(product.name === "Chennile Patches") && (
                <div className="flex flex-col mx-auto my-2 uppercase">
                  <span className="mx-2 font-sans md:font-black md:text-md">
                    Quantity of product
                  </span>
                  <div className="h-10 my-2 ">
                    <select
                      className="block w-full px-4 py-2 pr-8 leading-tight bg-gray-200 border border-white rounded-full shadow appearance-none hover:border-gray-200 focus:outline-none focus:shadow-outline"
                      name="quantityOfProduct"
                      {...register("quantityOfProduct", {
                        required: "This field is required",
                      })}
                    >
                      <option value="" selected disabled hidden>
                        ---Choose here---
                      </option>
                      <option>25</option>
                      <option>50</option>
                      <option>75</option>
                      <option>100</option>
                      <option>150</option>
                      <option>200</option>
                    </select>
                    <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
                      {errors.quantityOfProduct?.message}
                    </p>
                  </div>
                </div>
              )}

              {!(product.name === "Chennile Patches" || active === 1) && (
                <div className="flex flex-col mx-auto my-5 uppercase">
                  <span className="font-sans md:font-black md:text-md">
                    <input
                      type="radio"
                      name="custom"
                      value="yes"
                      checked={customQuantity}
                      onClick={() => setCustomQuantity(!customQuantity)}
                    ></input>
                    <label htmlFor="custom" className="ml-4">
                      Custom quantity
                    </label>
                  </span>
                  {customQuantity && (
                    <div className="h-10 my-2 ">
                      <input
                        className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
                        name="customQuantity"
                        type="number"
                        placeholder="Custom Quantity"
                        onChange={set("customQuantity")}
                        {...register("customQuantity", {
                          required: "Custom Quantity is required",
                          min: {
                            value: 50,
                            message: "Min Quantity 50",
                          },
                          // maxLength: {
                          //     value: 11,
                          //     message: "Max 11 characters",
                          // }
                        })}
                      ></input>
                      <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
                        {errors.customQuantity?.message}
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div className="flex items-center justify-center w-full mt-4">
                <ReCaptcha
                  OnClick={(isvarify) => setIsVarified(isvarify)}
                  // reset={isProcessing}
                />
              </div>

              {!(product.name === "Chennile Patches" || active === 1) && (
                <div className="items-center w-full my-10 text-center uppercase md:text-md">
                  Note:- PLEASE SEND THE TECH PACK AND ART WORK TO{" "}
                  <span
                    className="font-semibold cursor-pointer"
                    onClick={() =>
                      navigator.clipboard.writeText("info@periapparel.com")
                    }
                  >
                    INFO@PERIAPPAREL.COM
                  </span>{" "}
                  AND SOMEONE WILL GET IN TOUCH WITH YOU REGARDING order.
                </div>
              )}

              <div className={`md:flex md:items-center ${product.name === "Chennile Patches"?"mt-5":""}`}>
                <div className="md:w-3/5 md:mx-auto ">
                  {/* <p className="pl-6 mt-2 mb-2 text-sm text-red-500 md:text-md">{errorMessage}</p> */}
                  <button
                    disabled={!isVerified}
                    className="self-start block px-10 py-2 mx-auto text-2xl font-semibold text-white transition-all duration-300 ease-in-out bg-blue-600 rounded-md disabled:bg-blue-400 hover:bg-blue-700"
                    type="submit"
                  >
                    {product.name === "Chennile Patches" || active === 1
                      ? "Enter"
                      : "Submit"}
                  </button>
                </div>
              </div>

              <div className="flex w-full h-32 mt-5 text-justify mb-44 md:my-5 md:text-sm md:px-10">
                By signing up, I confirm that I have read and agreed to
                Periapparel's User Agreement & Terms of Service. I also agree to
                signing up via text, you consent to receive marketing text
                messages from Periapperal at the number provided, including
                messages sent by auto dialer. Consent is not a condition of any
                purchase. You can unsubscribe at any time by replying STOP
              </div>
            </div>
          </form>
        </div>
        <Footer />
      </div>
      {showPopUp && (
        <div
          onClick={(e) => {
            setShowPopUp(false);
          }}
          className="z-[99999] flex items-center justify-center fixed top-0 left-0 right-0 bottom-0"
        >
          <form
            // onSubmit={loginHandler}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className=" z-[999] rounded-xl py-4 pt-10  sm:px-20   w-[90%] sm:w-[80%] md:w-[60%] lg:w-[50%] max-w-[764px] mx-auto bg-white shadow-2xl"
          >
            <div className="flex items-center ">
              <FiEdit color="#383838" />
              <p className="ml-3 text-2xl text-[#383838]">Login Account</p>
            </div>

            <input
              type="email"
              className=" border border-[#706a6a] px-3 py-3 w-full mt-8 rounded-md text-xl outline-none"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <input
              type="password"
              className=" border border-[#706a6a] px-3 py-3 w-full mt-4 rounded-md text-xl outline-none"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <p
              onClick={() => forgetPasswordHandler()}
              className="text-[#383838] cursor-pointer mt-3 text-right"
            >
              Forgot Password
            </p>
            <button
              onClick={loginHandler}
              type="button"
              className="block px-8 py-4 mx-auto mt-6 text-xl text-white uppercase transition-all duration-100 ease-out bg-blue-600 rounded-md font-semiblod hover:bg-blue-700"
            >
              Login
            </button>

            <p className="mt-3 text-xl text-center">
              New User ?
              <Link to="/register">
                <span className="text-blue-500 cursor-pointer fontt-bold">
                  {" "}
                  Sign Up
                </span>
              </Link>
            </p>
          </form>
        </div>
      )}
    </>
  );
}
