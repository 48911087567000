import React from 'react'
import {AiOutlineClose} from 'react-icons/ai'
import emailverified from './emailverified.png'

const MessageBox = ({close, message, emailverify}) => {
  return (
    <div 
    onClick={()=>close()}
    className='fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[#0f09095e]'>
        <div 
        onClick={(e)=>e.stopPropagation()}
        className='bg-[#F6F6F6] rounded-md shadow-lg  w-[90%] sm:w-[400px] min-h-[200px] p-3 py-4'>
            <AiOutlineClose size={30} color="black" className='ml-auto ' cursor={"pointer"}
            onClick={()=>close()}
            />
            {emailverify &&
            <img 
            src={emailverified} 
            alt="Email Verified"
            className='w-[90%] mx-auto'
            />
             }
            <p className='mt-3 text-xl text-center font-bold'>{message}</p>
            
        </div>
    </div>
  )
}

export default MessageBox
