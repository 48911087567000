import React from "react";
import ImageComponent from "./ImageComponent";


const Mapped = ({
  defaultImages,
  type,
  setShowPopup,
  setPopUp,
  loadMoreHandler,
  data
}) => {
  const totalCount = data.filter((item) => item.subCategory === type).length;
  return (
    <>
      {defaultImages?.[type]?.map((item, index) => (
        <div
          key={item._id}
          className=" w-[100%] sm:w-[47%]  md:w-[30%] lg:w-[23%] "
        >
          <h2 className="h-10 text-xl text-semibold">
            {index === 0 && item?.subCategory}
          </h2>

          <div className="">
            <ImageComponent
              item={item}
              setShowPopup={setShowPopup}
              setPopUp={setPopUp}
            />

            {/* <p className="text-[14px] mt-4 text-red-600 font-bold capitalize">{item?.title}</p> */}

            {/* <pre className=" text-[14px] text-red-600 font-sans ">{item?.[i].description}</pre> */}
          </div>
          {totalCount !== defaultImages?.[type]?.length && (
            <>
              {index === defaultImages?.[type]?.length - 1 && (
                <button
                  onClick={() =>
                    loadMoreHandler(
                      item?.subCategory,
                      defaultImages?.[type]?.length === 4
                        ? 12
                        : defaultImages?.[type]?.length + 12
                    )
                  }
                  className="block px-4 py-2 mt-4 ml-auto text-white bg-black rounded-md"
                >
                  Load More
                </button>
              )}
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default Mapped;
