import React from "react";
import { useSelector, useDispatch } from "react-redux";
import productSummary from "../../../../actions/productSummary";
import { quantityPriceEmbroidery, quantityPriceVeganLeather } from "../../../utils";

const Selection = () => {
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col gap-16 mt-8">
      {/* ..................SELECT BACKING.............................. */}

      <div
        className="flex gap-10 "
        onChange={(e) => {
          let price = 0;
          const factor = product.quantity.size;
          if (e.target.value === "Iron on Backing") {
            price =
            factor *
            parseFloat(
              (
                quantityPriceVeganLeather[
                  Math.round(
                    parseFloat(product.wide) * parseFloat(product.long)
                  )
                ] * 0.05
              ).toFixed(2)
            );
          }
          if (e.target.value === "Velcro Backing") {
            price =
              factor *
              parseFloat(
                (
                  quantityPriceEmbroidery[
                    Math.round(
                      parseFloat(product.wide) * parseFloat(product.long)
                    )
                  ] * 0.1
                ).toFixed(2)
              );
          }

          dispatch(
            productSummary({
              ...product,
              other: [
                ...product.other.filter(
                  (item) =>
                    !["Standard", "Iron on Backing", "Velcro Backing"].includes(
                      item
                    )
                ),
                e.target.value,
              ],
              backing: {
                ...product.backing,
                label: e.target.value,
                price,
              },
              finalPrice:
                e.target.value === "Standard"
                  ? product.finalPrice - product.backing.price
                  : product.finalPrice - product.backing.price + price,
            })
          );
        }}
      >
        <div className=" font-bold text-gray-700 uppercase tracking-[0.25em] max-w-sm w-full">
          SELECT BACKING
        </div>
        <div className="flex flex-col gap-4 mt-1">
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="backing"
              checked={product.backing.label === "Standard" && true}
              value="Standard"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="backing" className="ml-4 w-max">
              Standard
            </div>
          </div>
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="backing"
              checked={product.backing.label === "Iron on Backing" && true}
              value="Iron on Backing"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="backing" className="ml-4 w-max">
              Iron on Backing
            </div>
          </div>
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="backing"
              checked={product.backing.label === "Velcro Backing" && true}
              value="Velcro Backing"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="backing" className="ml-4 w-max">
              Velcro Backing
            </div>
          </div>
        </div>
      </div>

      {/* ..................COLORS SELECTION.............................. */}
      <div
        className="flex gap-10 "
        onChange={(e) => {
          let price = 0;
          const factor = product.quantity.size;
          if (e.target.value === "5-8 Colors") {
            price = factor * 0.25;
          }
          if (e.target.value === "9-12 Colors") {
            price = factor * 0.5;
          }
          dispatch(
            productSummary({
              ...product,
              other: [
                ...product.other.filter(
                  (item) =>
                    !["1-4 Colors", "5-8 Colors", "9-12 Colors"].includes(item)
                ),
                e.target.value,
              ],
              color: {
                ...product.color,
                label: e.target.value,
                price,
              },
              finalPrice:
                e.target.value === "1-4 Colors"
                  ? product.finalPrice - product.color.price
                  : product.finalPrice - product.color.price + price,
            })
          );
        }}
      >
        <div className=" font-bold text-gray-700 uppercase tracking-[0.25em] max-w-sm w-full">
          Select Colors
        </div>
        <div className="flex flex-col gap-4 mt-1">
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="color"
              checked={product.color.label === "1-4 Colors" && true}
              value="1-4 Colors"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="color" className="ml-4 w-max">
              1-4 Colors
            </div>
          </div>
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="color"
              value="5-8 Colors"
              checked={product.color.label === "5-8 Colors" && true}
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="color" className="ml-4 w-max">
              5-8 Colors
            </div>
          </div>
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="color"
              value="9-12 Colors"
              checked={product.color.label === "9-12 Colors" && true}
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="color" className="ml-4 w-max">
              9-12 Colors
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Selection;
