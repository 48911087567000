/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import PreviewEditImage from "./PreviewEditImage";
import ImageEditor from "./ImageEditor";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FormatArtWork = ({ imageUrl, setImage, setViewImage ,patchPriceFormat }) => {
  const [toggleEditImage, setToggleEditImage] = React.useState(true);
  const imgRef = React.useRef(null);
  const product = useSelector((state) => state.productSummary);
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  const width = (product.wide * 41.8).toFixed(1);
  const height = (product.long * 41.8).toFixed(1);

  const [crop, setCrop] = React.useState({
    x: 0,
    y: 0,
    width: width,
    height: height,
    unit: "px",
  });
  const [scale, setScale] = React.useState(1);

  const [fitImage, setFitImage] = React.useState(
    imageDimensions.width >= imageDimensions.height ? height : width
  );

  return (
    <div className="">
      <div className="flex items-center justify-between max-w-2xl mt-15">
        <p className="text-3xl font-bold ">Edit Image</p>
        {toggleEditImage ? (
          <FaChevronUp
            className="w-8 h-8 bg-[#e5e7eb] p-1 rounded cursor-pointer"
            onClick={() => setToggleEditImage(false)}
          />
        ) : (
          <FaChevronDown
            className="w-8 h-8 bg-[#e5e7eb] p-1 rounded cursor-pointer"
            onClick={() => setToggleEditImage(true)}
          />
        )}
      </div>
      {toggleEditImage && (
        <div className="flex flex-col gap-6 mt-8 lg:flex-row">
          <ImageEditor
            imgRef={imgRef}
            imageUrl={imageUrl}
            imageDimensions={imageDimensions}
            setImageDimensions={setImageDimensions}
            width={width}
            height={height}
            crop={crop}
            fitImage={fitImage}
            setFitImage={setFitImage}
            setCrop={setCrop}
            scale={scale}
            setScale={setScale}
            patchPriceFormat={patchPriceFormat}
          />

          {/* <p>
          Original - Width: {imageDimensions.width}(
          {pixelsToInches(imageDimensions.width)}inch) , Height:{" "}
          {imageDimensions.height}({pixelsToInches(imageDimensions.height)}inch)
        </p>

        <p>
          Selected - Width: {width}({pixelsToInches(width)}inch) , Height:{" "}
          {height}({pixelsToInches(height)}inch)
        </p> */}

          <PreviewEditImage
            imgRef={imgRef}
            crop={crop}
            setImage={setImage}
            setViewImage={setViewImage}
            fitImage={fitImage}
            setFitImage={setFitImage}
          />
        </div>
      )}
    </div>
  );
};

export default FormatArtWork;
