import React, {useEffect} from "react";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";

const Tc = () => {
useEffect(()=>{
 window.scrollTo(9, 0)
},[])
  return (
    <>
      <Navbar />
      <div className="min-h-screen tc">
        <h2>Terms of use and Privacy Policy -</h2>
        <p className="">
          These Terms of Use govern your use of this website. By using this
          website, you accept these Terms of Use in full. If you disagree with
          these Terms of Use or any part of these Terms of Use, you must not use
          this website. This website uses cookies. By using periapparel.com
          (“the Website”) and agreeing to these Terms of Use, you consent to the
          Website’s use of cookies in accordance with the terms of the Website’s
          Privacy Policy.
        </p>
        <br />
        <hr />
        <h2 className="">License to Use Website</h2>
        <p>
          Unless otherwise stated, the Website and/or its licensors own the
          intellectual property rights of the Website and material on the
          Website. Subject to the license below, all these intellectual property
          rights are reserved. You may view, download (for caching purposes
          only) and print pages, user manuals, or other product information from
          the Website for your own personal use, subject to the restrictions set
          out below and elsewhere in these Terms of Use.
        </p>
        <br />
          <strong className="text-2xl font-bold">You must not:</strong>
        <br/>
        <ul>
          <li>
            Republish material from this website (including republication on
            another website), sell, rent or sub-license material from the
            website.
          </li>
          <li>Show any material from the website in public.</li>
          <li>
            Reproduce, duplicate, copy, or otherwise exploit material on the
            Website for a commercial purpose.
          </li>
          <li>Edit or otherwise modify any material on the website.</li>
          <li>
            Redistribute material from this website except for content
            specifically and expressly made available for redistribution
          </li>
        </ul>
        <br />
        <br />
        <hr />
        <h2>User Information</h2>
        <p className="">
          Peri Apparel LLC values and protects the public's (your) privacy and
          places strict controls on the gathering and use of sensitive
          information and confidential information. Sensitive information and
          confidential information is not disclosed, made available, or
          otherwise used for purposes other than those specified at the time of
          collection, except with your consent or as authorized by law or
          regulation. To better protect your privacy the following notice
          explains the information we collect, how it is used, how it is
          safeguarded, and how to contact us if you have any concerns.
          <br />
          As part of the order process, the following information is collected
          from shoppers:
        </p>
        <br />
        <ul>
          <li>Name</li>
          <li>Shipping/Billing Address</li>
          <li>Email Address</li>
          <li>Phone Number</li>
          <li>Credit/Debit Card Information</li>
        </ul>
        <br />
        <br />
        <hr />
        <h2>How That Information Is Used </h2>
        <p className="">
          Peri Apparel LLC, only collects information used to fulfill order. We
          print your Name, Shipping/Billing Address, email address and phone
          number when we print your orders out for manufacturing. The users name
          and email address is used to send special offers in the future and the
          customer has the right to opt out of such email marketing at any time.
          We may also contact you regarding questions about your order during
          the manufacturing and shipping process. Orders
        </p>
        <p>
          We request information from you on our order form. To buy from us, you
          must provide contact information (like name and shipping address) and
          financial information (like credit card number, expiration date). This
          information is used for billing purposes and to fill your orders. If
          we have trouble processing an order, we'll use this information to
          contact you.
        </p>
        <br />
        <br />
        <hr />
        <h2>Cookies</h2>
        <p className="">
          We use "cookies" on this site. A cookie is a piece of data stored on a
          site visitor's hard drive to help us improve your access to our site
          and identify repeat visitors to our site. For instance, when we use a
          cookie to identify you, you would not have to log in a password more
          than once, thereby saving time while on our site. Cookies can also
          enable us to track and target the interests of our users to enhance
          the experience on our site. Usage of a cookie is in no way linked to
          any personally identifiable information on our site.
        </p>
        <br />
        <br />
        <hr />
        <h2>Web Analytics Data</h2>
        <p className="">
          Peri Apparel LLC website collects and store information each time you
          access them to enable us to measure the total number of visitors, and
          certain non-personal statistical information. This helps us make our
          websites more accessible and useful to visitors.
        </p>
        <p className="">
          If you browse through our sites reading, printing, or downloading
          information, no sensitive information, and no confidential information
          about you is collected.
        </p>
        <br />
        <ul>
          <li>
            The information we collect when you visit Peri Apparel LLC sites
            consists of:
          </li>
          <li>
            The Internet Protocol (IP) address from which you are accessing the
            site(s)
          </li>
          <li>
            {" "}
            The domain name of the Internet Service Provider (ISP) you are using
            to access the site(s){" "}
          </li>
          <li> The date and time you visited the site(s)</li>
          <li> The web pages or services you accessed at the site(s)</li>
        </ul>
        <br />
        Peri Apparel LLC uses Analytics to measure traffic on our websites.Some
        of Peri Apparel LLC sites may also use JavaScript to collect site
        traffic and activity, as well as to measure the performance of our
        servers and network and aid in the ordering process. Our Commitment To
        The Security of Your Data We take precautions to protect your
        information. When you submit sensitive information via the website, your
        information is protected both online and offline. Wherever we collect
        sensitive information (such as credit card data), that information is
        encrypted and transmitted to us in a secure way. You can verify this by
        looking for a lock icon in the address bar and looking for "https" at
        the beginning of the address of the Web page. While we use encryption to
        protect sensitive information transmitted online, we also protect your
        information offline. Only employees who need the information to perform
        a specific job (for example, billing or customer service) are granted
        access to personally identifiable information. The computers/servers in
        which we store personally identifiable information are kept in a secure
        environment.
        <br />
        <br />
        <hr />
        <h2>Acceptable Use</h2>
        You must not use the Website in any way that causes or may cause damage
        to the Website; impairs the availability or accessibility of the
        Website; in any way which is unlawful, illegal, fraudulent, or harmful;
        or in connection with any unlawful, illegal, fraudulent, or harmful
        purpose or activity. You must not use the Website to copy, store, host,
        transmit, send, use, publish, or distribute any material which consists
        of (or is linked to) any spyware, computer virus, Trojan horse, worm,
        keystroke logger, rootkit, or other malicious computer software. You
        must not conduct any systematic or automated data collection activities
        (including but not limited to scraping, data mining, data extraction,
        and data harvesting) on or in relation to the Website without the
        Website’s express written consent. You must not use the Website to
        transmit or send unsolicited commercial communications. You must not use
        the Website for any purposes related to marketing without the Website’s
        express written consent.
        <br />
        <br />
        <hr />
        <h2>User Content</h2>
        In these Terms of Use, “your user content” means material (including
        without limitation text, images, audio material, video material and
        audio-visual material) that you submit to this website, for whatever
        purpose. You grant the Website a worldwide, irrevocable, non-exclusive,
        royalty-free license to use, reproduce, adapt, publish, translate, and
        distribute your user content in any existing or future media. You also
        grant the Website the right to sub-license these rights, and the right
        to take action against the infringement of these rights. Your user
        content must not be illegal or unlawful, must not infringe any third
        party’s legal rights, and must not be capable of giving rise to legal
        action, whether against you, the Website, or a third party (in each case
        under any applicable law). You must not submit any user content to the
        Website that is or has ever been the subject of any threatened or actual
        legal proceedings, or other similar complaint. The Website reserves the
        right to edit or remove any material submitted to this website, or
        stored on the Website’s servers, or hosted or published on the Website.
        Notwithstanding the Website’s rights under these Terms of Use in
        relation to user content, the Website does not take responsibility for
        monitoring the submission of such content to or the publication of such
        content on the Website.
        <br />
        <br />
        <hr />
        <h2>Warranty information</h2>
        The Website is provided “as is” without any guarantees or warranties,
        express or implied. The Website makes no guarantees or warranties in
        relation to the Website, or the information and materials provided on
        the Website. Without prejudice to the generality of the foregoing
        paragraph, periapparel.com does not warrant that: • This website will be
        constantly available, or available at all. • The information on this
        website is complete, true, accurate or non-misleading. Nothing on this
        website constitutes, or is meant to constitute, advice of any kind. If
        you require advice in relation to any legal, financial, or medical
        matter you should consult an appropriate professional.
        <br />
        <br />
        <hr />
        <h2>Limitations of Liability</h2>
        The Website will not be liable to you (whether under contract law, tort
        law, or otherwise) in relation to the contents of, use of, or otherwise
        in connection with the Website: • To the extent that the website is
        provided free-of-charge, for any direct loss; • For any indirect,
        special or consequential loss; • For any business losses, loss of
        revenue, income, profits or anticipated savings, loss of contracts or
        business relationships, loss of reputation or goodwill, or loss or
        corruption of information or data. These limitations of liability apply
        even if periapparel.com has been expressly advised of the potential
        loss.
        <br />
        <br />
        <hr />
        <h2>Exceptions</h2>
        Nothing in this website disclaimer will exclude or limit any warranty
        implied by law that it would be unlawful to exclude or limit, and
        nothing in this website disclaimer will exclude or limit
        periapparel.com’s liability in respect of any:
        <br/>
        <ul>
          <li> Death or personal injury caused by the Website’s negligence.</li>
          <li>
            {" "}
            Fraud or fraudulent misrepresentation on the part of the Website.
          </li>
          <li>
            {" "}
            Matter which it would be illegal or unlawful for periapparel.com to
            exclude or limit, or to attempt or purport to exclude or limit, its
            liability.{" "}
          </li>
        </ul>
        <br />
        <br />
        <hr />
        <h2>Reasonableness</h2>
        By using the Website, you agree that the exclusions and limitations of
        liability set out in this disclaimer are reasonable. If you do not think
        they are reasonable, you must not use this website.
        <br />
        <br />
        <hr />
        <h2>Other Parties</h2>
        You accept that, as a limited liability entity, the Website has an
        interest in limiting the personal liability of its officers and
        employees. You agree that you will not bring any claim personally
        against the Website’s officers or employees in respect of any losses you
        suffer in connection with the Website. Without prejudice to the
        foregoing paragraph, you agree that the limitations of warranties and
        liability set out in this disclaimer will protect the Website’s
        officers, employees, agents, subsidiaries, successors, assignees, and
        sub-contractors as well as the Website. Unenforceable Provisions If any
        provision of this disclaimer is, or is found to be, unenforceable under
        applicable law, that will not affect the enforceability of the other
        provisions of this disclaimer.
        <br />
        <br />
        <hr />
        <h2>Indemnity</h2>
        You hereby indemnify the Website and undertake to keep the Website
        indemnified against any losses, damages, costs, liabilities, and
        expenses (including but not limited to legal expenses and any amounts
        paid by the Website to a third party in settlement of a claim or dispute
        on the advice of the Website’s legal advisers) incurred or suffered by
        the Website arising out of any breach by you of any provision of these
        Terms of Use, or arising out of any claim that you have breached any
        provision of these Terms of Use.
        <br />
        <br />
        <hr />
        <h2>Breaches</h2>
        Without prejudice to the Website’s other rights under these Terms of
        Use, if you breach these Terms of Use in any way, the Website may take
        such action as the Website deems appropriate to deal with the breach,
        including suspending your access to the Website, prohibiting you from
        accessing the Website, blocking computers using your IP address from
        accessing the Website, contacting your internet service provider to
        request that they block your access to the Website, and/or bringing
        court proceedings against you.
        <br />
        <br />
        <hr />
        <h2>Assignment</h2>
        The Website may transfer, sub-contract or otherwise deal with the
        Website’s rights and/or obligations under these Terms of Use without
        notifying you or obtaining your consent. You may not transfer,
        sub-contract, or otherwise deal with your rights and/or obligations
        under these Terms of Use.
        <br />
        <br />
        <hr />
        <h2>Severability</h2>
        If a provision of these Terms of Use is determined by any court or other
        competent authority to be unlawful and/or unenforceable, the other
        provisions will continue in effect. If any unlawful and/or unenforceable
        provision would be lawful or enforceable if part of it were deleted,
        that part will be deemed to be deleted, and the rest of the provision
        will continue in effect.
        <br />
        <br />
        <hr />
        <h2>Entire Agreement</h2>
        These Terms of Use, together with the Website’s Privacy Policy,
        constitute the entire agreement between you and the Website in relation
        to your use of the Website, and supersede all previous agreements in
        respect to your use of the Website.
        <br />
        <br />
        <hr />
        <h2>Law and Jurisdiction</h2>
        <p className="">
          These Terms of Use will be governed by and construed in accordance
          with US law, and any disputes relating to these Terms of Use will be
          subject to the exclusive jurisdiction of the courts of Texas, USA.
        </p>
        <br />
        <br />
        <hr />
        <h2>Changes to Our Terms of Use</h2>
        <p className="">
          If we decide to change our Terms of Use, we will update the Terms of
          Use modification date below. Last updated May 01, 2023 .{" "}
        </p>
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
};

export default Tc;
