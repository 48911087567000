import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import Footer from './footer/Footer';
import Navbar from './navbar/Navbar';

const NotFound = () => {
    const navigate=useNavigate();
    useEffect(()=>{
     navigate('/select')
    },[])
  return (
    <div>
      <><Navbar/><div className='flex flex-col items-center justify-center min-h-screen '>
          <h2 className='text-4xl font-semibold '>404 Page Not Found</h2>
          
          <Link to="/select" className='text-xl mt-2 text-blue-700 underline'>Select Product</Link>
          </div>
          <Footer/>
          </>
    </div>
  )
}

export default NotFound
