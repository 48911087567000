import React, { useEffect } from "react";
import { useDebounceEffect } from "../../utils/useDebounceEffect";
import { canvasPreview } from "../../utils/canvasPreview";
import { convertCanvasToFile } from "../../utils/tools";
import { useSelector, useDispatch } from "react-redux";
import productSummary from "../../actions/productSummary";
const PreviewEditImage = ({ imgRef, crop, fitImage, setFitImage }) => {
  const previewCanvasRef = React.useRef(null);
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  useDebounceEffect(
    async () => {
      if (
        crop?.width &&
        crop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, crop);
        dispatch(
          productSummary({
            ...product,
            editImage: convertCanvasToFile(
              previewCanvasRef.current,
              product.originalImage.name,
              product.originalImage.type
            ).file,
          })
        );
      }
    },
    100,
    [crop, fitImage, imgRef]
  );
  useEffect(() => {
    setFitImage((prev) => prev);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!!crop && (
        <div className="flex flex-col items-center w-full lg:w-[50%] gap-8 justify-start">
          <p className="text-xl font-bold">Art Selection Preview</p>
          <canvas
            ref={previewCanvasRef}
            className=" w-full  max-w-sm  max-h-[500px]   object-contain"
          />

          <div className="flex items-center gap-3">
            <input
              type="checkbox"
              className="w-5 h-5 border"
              required
              name="verify"
              value="yes"
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Image should be approved before proceeding to next step."
                )
              }
              onChange={(e) => {
                e.target.setCustomValidity("");
                e.target.reportValidity();
              }}
            />
            <p className="font-semibold text-gray-700 uppercase tracking-[0.25em]">
              Approve Image
            </p>
          </div>
          <a
            href={URL.createObjectURL(product.editImage)}
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 font-semibold text-blue-500 underline rounded "
          >
            Download approved image
          </a>
        </div>
      )}
    </>
  );
};

export default PreviewEditImage;
