import React from "react";
import { API_URL } from "../../../../config";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { FiEdit } from "react-icons/fi";

const Login = ({ setShowPopUp }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");


  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(API_URL + "auth/login", {
        email,
        password,
      });
      if (data.message === "SUCCESS") {
        Cookies.set("token", data?.token, { expires: 1 / 24 });
        Cookies.set("email", email, { expires: 1 / 24 });
        if (data?.token) {
          const resp = await axios.get(`${API_URL}auth/myself`, {
            headers: { Authorization: `Bearer ${data?.token}` },
          });
          Cookies.set("authUserId", resp.data.userDetails._id);
          //  console.log(resp.data)
        }
        setEmail("");
        setPassword("");
      }
    } catch (err) {
      // console.log(err);
      alert("Wrong Email or password");
      setEmail("");
      setPassword("");
    }
  };

  const forgetPasswordHandler = async () => {
    if (email) {
      try {
        const { data } = await axios.post(`${API_URL}auth/forgotpassword`, {
          email,
        });

        // console.log(data);
        if (data.message === "Email Sent...") {
          alert("Email has been sent to reset password ");
        }
      } catch (err) {
        // console.log(err);
        alert("Email Not Found");
      }
    } else {
      alert("Enter Email");
    }
  };

  return (
    <div
      onClick={(e) => {
        setShowPopUp(false);
      }}
      className="z-[99999] flex items-center justify-center fixed top-0 left-0 right-0 bottom-0"
    >
      <form
        // onSubmit={loginHandler}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" z-[999] rounded-xl py-4 pt-10  sm:px-20   w-[90%] sm:w-[80%] md:w-[60%] lg:w-[50%] max-w-[764px] mx-auto bg-white shadow-2xl"
      >
        <div className="flex items-center ">
          <FiEdit color="#383838" />
          <p className="ml-3 text-2xl text-[#383838]">Login Account</p>
        </div>

        <input
          type="email"
          className=" border border-[#706a6a] px-3 py-3 w-full mt-8 rounded-md text-xl outline-none"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <input
          type="password"
          className=" border border-[#706a6a] px-3 py-3 w-full mt-4 rounded-md text-xl outline-none"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <p
          onClick={() => forgetPasswordHandler()}
          className="text-[#383838] cursor-pointer mt-3 text-right"
        >
          Forgot Password
        </p>
        <button
          onClick={loginHandler}
          type="button"
          className="block px-8 py-4 mx-auto mt-6 text-xl text-white uppercase transition-all duration-100 ease-out bg-blue-600 rounded-md font-semiblod hover:bg-blue-700"
        >
          Login
        </button>

        <p className="mt-3 text-xl text-center">
          New User ?
          <Link to="/register">
            <span className="text-blue-500 cursor-pointer fontt-bold">
              {" "}
              Sign Up
            </span>
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Login;
