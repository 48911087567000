const initialState = {
       id:"",
       isLoggedIn:false,
       orderId:""     
  };
  const global = (state = initialState, action) => {
    switch (action.type) {
      case 'GLOBAL_DATA':
        return action.payload;
      default:
        return state;
    }
  };
  
  export default global;
  