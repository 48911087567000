import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import axios from "axios";
import { API_URL } from "../../config";
import { motion } from "framer-motion";
import { AiOutlineClose, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
// import data from "./data";
import Mapped from "./Mapped";
import activeStep from "../../actions/activeStep";
import productSummary from "../../actions/productSummary";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";


const hoodiesTypes = [
  "Hoodies",
  "Sweatshirt"
]

const Garments = () => {
  const [allImages, setAllImages] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popUp, setPopUp] = useState({
    currentIndex: 0,
    images: [],
  });
  const [defaultImages, setDefaultImages] = useState({
    Hoodies: [],
    Sweatshirt: [],
  });
  // const [categories, setCategories] = useState([]);
  const [bannerImage, setBannerImage] = useState('');

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const product = useSelector((state) => state.productSummary);

  const setDefaultHandler = (data) => {
    const Hoodies = data
      .filter((item) => item.subCategory === "Hoodies")
      .slice(0, 4);
    const Sweatshirt = data
      .filter((item) => item.subCategory === "Sweatshirt")
      .slice(0, 4);


    setDefaultImages({
      Hoodies: [...Hoodies],
      Sweatshirt: [...Sweatshirt]
    });
  };


  const navigation = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categories = searchParams.getAll("categories");
  const url = location.search;


  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      const { data } = await axios.get(`${API_URL}patch/images/category`, {
        params: { category: "hoodies" },
      });
      console.log(data);

      let dummyArr = [];
      // console.log(data);
      for (const property in data) {
        let newArr = [];
        for (let i = 0; i < data[property].length; i++) {
          // console.log(data[property].length);
          newArr.push(data[property][i].url);
        }
        dummyArr.push({
          title: property,
          _id: Math.random(),
          images: newArr,
          description: data[property][0]?.description
            ? data[property][0]?.description
            : "",
          subCategory: data[property][0]?.subCategory
            ? data[property][0]?.subCategory
            : "",
        });
        console.log(dummyArr)
        // console.log(`${property}: ${data[property]}`);
        setAllImages([...dummyArr]);
        setDefaultHandler(dummyArr);
      }
    })();

  }, []);

  // const loadMoreHandler = (sub_Category, number) => {
  //   const cate = [sub_Category];
  //   setCategories([...cate]);
  //   const filteredImgs = allImages
  //     .filter((item) => item.subCategory === sub_Category)
  //     .slice(0, number);
  //   defaultImages[sub_Category] = [...filteredImgs];
  //   setDefaultImages({ ...defaultImages });
  // };

  useEffect(() => {
    const getImage = async () => {
      try {

        const { data } = await axios.get(`${API_URL}patch/images`, {
          params: { category: "home", title: "Garments Banner" },
        });

        setBannerImage(data[0]?.url)


      } catch (err) {

      }
    };
    getImage();
  }, []);

  const handleClick = () => {
    const name = "Garments";

    const n = name.split(" ");

    const category = n[n.length - 1];
    dispatch(productSummary({ ...product, name, category }));
    dispatch(activeStep(2));
    navigate("/info");
  };

  return (
    <>
      <Navbar />
      <div
        className="bg-gray-500 max-w-[1300px] mx-auto relative overflow-hidden bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: "url('" + bannerImage + "')", height: "350px" }}
      >
        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed">
          <div className="flex justify-center items-center h-full">
            <div className="text-center text-white px-6 md:px-12">
              <h1 className="text-6xl font-bold mt-0 mb-6">Garments</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center font-sans mt-10 text-lg w-[90%] sm:w-[75%] leading-8 text-center mx-auto ">
        We design and manufacture high quality premium garments. All our garments can be customized to the requirement. From the design to retail shelf, we collaborate with our customers to make premium garments. We specialize in heavy weight hoodies and crewnecks with custom print, embroidery and chenille decorations.
      </div>

      <div className="flex justify-center mt-5 py-2">
        <button
          className="hover:bg-blue-800 transition-all ease-linear duration-300 rounded-md px-12 py-2 font-semibold bg-blue-700 text-white     text-[22px]"
          onClick={handleClick}
        >
          Inquire Order
        </button>
      </div>

      <div className="min-h-screen">
        {/* <h2 className="text-[#757575] text-3xl text-center font-bold">
          Garments
        </h2> */}

        <div className="flex min-h-screen my-10">
          <div className="hidden md:block w-[20%] border-r-gray-300 border-r-4 min-w-[200px]">
            <ul className="ml-[20%]">
              <li className="text-xl font-bold">Categories</li>
              {hoodiesTypes.map((t) => (
                <li key={t} className="mt-3 text-gray-500 ">
                  <input
                    type="checkbox"
                    className="mr-2 cursor-pointer"
                    value={t}
                    checked={categories.includes(t)}
                    // onChange={addRemoveToList}
                    onChange={() => {
                      if (categories.includes(t)) {
                        navigation(
                          `/garments${location.search.replace(`&categories=${t}`, "")}`
                        );
                      } else {
                        navigation(
                          `/garments?${url.replace("?", "")}&categories=${t}`
                        );
                      }
                    }}
                  />
                  {t}
                </li>
              ))}
            </ul>
          </div>
          <div className="md:w-[80%] py-4 px-4">
            <div className="">
              {hoodiesTypes.map((t, index) => (
                <div className="relative w-full  text-gray-500  flex flex-wrap gap-x-4 ">
                  {(categories.includes(t) || categories.length == 0) && (
                    <Mapped
                      key={index}
                      defaultImages={defaultImages}
                      type={t}
                      setShowPopup={setShowPopup}
                      setPopUp={setPopUp}
                      // loadMoreHandler={loadMoreHandler}
                      data={allImages}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {showPopup && (
        <>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowPopup(false);
            }}
            className="z-[99999] fixed top-0 left-[0%] right-[0%] bottom-0 flex items-center justify-center bg-black/80 "
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowPopup(false);
              }}
              className="z-[9999] fixed top-4 right-4  "
            >
              <AiOutlineClose size={40} color="white" />
            </button>
            <div
              className="bg-white "
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <motion.img
                src={popUp.images[popUp?.currentIndex]}
                className="w-[80%] mx-auto sm:w-[90%] md:w-[100%] max-w-[600px] max-h-[80vh] object-contain"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (popUp.currentIndex === 0) {
                  setPopUp({ ...popUp, currentIndex: popUp.images.length - 1 });
                }
                popUp?.currentIndex > 0 &&
                  setPopUp({ ...popUp, currentIndex: popUp.currentIndex - 1 });
              }}
              className="z-[999]  fixed sm:w-[10%]  top-0 left-0 md:w-[150px] bottom-0 bg-black/10  flex items-center justify-center"
            >
              <AiOutlineLeft size={50} color="white" cursor={"pointer"} />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (popUp.currentIndex === popUp.images.length - 1) {
                  setPopUp({ ...popUp, currentIndex: 0 });
                }
                popUp?.currentIndex < popUp.images.length - 1 &&
                  setPopUp({ ...popUp, currentIndex: popUp.currentIndex + 1 });
              }}
              className="z-[999] fixed top-0 sm:w-[10%] md:w-[150px] right-0  bottom-0 bg-black/10  flex items-center justify-center"
            >
              <AiOutlineRight size={50} color="white" cursor={"pointer"} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Garments;
