import React from "react";
import "./progressbar.css";
import { useSelector, useDispatch } from "react-redux";
import activeStep from "../../actions/activeStep";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function Progressbar() {
  const { pathname } = useLocation();
  const active = useSelector((state) => state.activeStep);
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // console.log("pathname",pathname);

  // console.log("active",active);
  useEffect(() => {
    if (pathname === "/shipping-info") {
      dispatch(activeStep(6));
    } else if (pathname === "/upload") {
      dispatch(activeStep(3));
    } else if (pathname === "/special") {
      dispatch(activeStep(4));
    } else if (pathname === "/customize") {
      dispatch(activeStep(5));
    } else if (pathname === "/info") {
      dispatch(activeStep(2));
    } else if (pathname === "/select") {
      dispatch(activeStep(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStep = (e) => {
    const step = parseInt(e.target.value);
    if (active > step) {
      dispatch(activeStep(step));
      switch (step) {
        case 1:
          navigate("/select");
          break;
        case 2:
          navigate("/info");
          break;
        case 3:
          navigate("/upload");
          break;
        case 4:
          navigate("/special");
          break;
        case 5:
          navigate("/customize");
          break;
        case 6:
          navigate("/shipping-info");
          break;
        default:
          navigate("/");
      }
    }
  };

  return (
    <div className="container sm:mx-20 ">
      <ul className="mx-2 progressbar sm:mx-32 sm:w-11/12 lg:w-full">
        <li
          className={`
            ${active >= 2 ? "active " : ""} ${
            pathname === "/select" ? "mainactive" : ""
          } uppercase mr-2 md:pr-0 text-xs md:text-md lg:text-xl`}
          value="1"
          onClick={handleStep}
        >
          select
        </li>

        <li
          className={`
          ${active >= 3 ? "active " : ""} ${
            pathname === "/info" ||
            pathname === "/hoodies" ||
            pathname === "/jackets"
              ? "mainactive"
              : ""
          } uppercase mr-2 md:pr-0 text-xs md:text-md lg:text-xl`}
          value="2"
          onClick={handleStep}
        >
          {product.name === "Chennile Patches" || active === 1
            ? "Info"
            : "Final"}
        </li>

        {product.name === "Chennile Patches" || active === 1 ? (
          <>
            <li
              className={`
              ${active >= 4 ? "active " : ""} ${
                pathname === "/upload" ? "mainactive" : ""
              } uppercase mr-2 md:pr-0 text-xs md:text-md lg:text-xl`}
              value="3"
              onClick={handleStep}
            >
              UPLOAD
            </li>

            {/* <li
              className={`
              ${active >= 5? "active " : "" } ${pathname=="/special"?"mainactive":""} uppercase mr-2 md:pr-0 text-xs md:text-md lg:text-xl`}
              value="4"
              onClick={handleStep}
            >
              <span className="pl-3 sm:pl-0">Special</span>
            </li> */}

            <li
              className={`
              ${active >= 6 ? "active " : ""} ${
                pathname === "/customize" ? "mainactive" : ""
              } uppercase mr-2 md:pr-0 text-xs md:text-md lg:text-xl`}
              value="5"
              onClick={handleStep}
            >
              Customize
            </li>

            <li
              className={`
               ${active >= 7 ? "active " : ""} ${
                pathname === "/shipping-info" ? "mainactive" : ""
              } uppercase mr-2 md:pr-0 text-xs md:text-md lg:text-xl`}
              value="6"
              onClick={handleStep}
            >
              Cart
            </li>
          </>
        ) : (
          ""
        )}
      </ul>
    </div>
  );
}
