import React from 'react'
import Navbar from './navbar/Navbar'
import Footer from './footer/Footer'

const PP = () => {
  return (
    <>
    <Navbar/>
    <div className='min-h-screen'>
      
    </div>
    <Footer/>
    </>
  )
}

export default PP
