import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Navbar from "../../components/navbar/Navbar";
import Progressbar from "../../components/Progressbar/Progressbar";
import dog from "../../components/images/dog.jpg";
import Front from "../../components/images/Front.png";
import Jackets from "../../components/images/Jackets.png";
import standard from "../../components/images/standard.png";
import expedite from "../../components/images/expedite.png";
import Footer from "../../components/footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import activeStep from "../../actions/activeStep";
import CheckoutSuccess from "../CheckoutPage/checkout";
import productSummary from "../../actions/productSummary";
import { useForm } from "react-hook-form";

export  const states = [
  "Alabama(AL)",
  "Alaska(AK)",
  "Arizona(AZ)",
  "Arkansas(AR)",
  "California(CA)",
  "Colorado(CO)",
  "Connecticut(CT)",
  "Delaware(DE)",
  "Florida(FL)",
  "Georgia(GA)",
  "Hawaii(HI)",
  "Idaho(ID)",
  "Illinois(IL)",
  "Indiana(IN)",
  "Iowa(IA)",
  "Kansas(KS)",
  "Kentucky(KY)",
  "Louisiana(LA)",
  "Maine(ME)",
  "Maryland(MD)",
  "Massachusetts(MA)",
  "Michigan(MI)",
  "Minnesota(MN)",
  "Mississippi(MS)",
  "Missouri(MO)",
  "Montana(MT)",
  "Nebraska(NE)",
  "Nevada(NV)",
  "New Hampshire (NH)",
  "New Jersey (NJ)",
  "New Mexico (NM)",
  "New York (NY)",
  "North Carolina (NC)",
  "North Dakota (ND)",
  "Ohio(OH)",
  "Oklahoma(OK)",
  "Oregon(OR)",
  "Pennsylvania(PA)",
  "Puerto Rico (PR)",
  "Rhode Island (RI)",
  "South Carolina (SC)",
  "South Dakota (SD)",
  "Tennessee(TN)",
  "Texas(TX)",
  "Utah(UT)",
  "Vermont(VT)",
  "Virginia(VA)",
  "Washington(WA)",
  "Washington DC (DC)",
  "West Virginia (WV)",
  "Wisconsin(WI)",
  "Wyoming(WY)",
];
export default function CustomizePage() {

useEffect(()=>{
   console.log("p==>",product);
   
   if(product?.deliveryMethod=="expedite"){
    setIsExpeditedOrder(true);
   }
   if(product?.skipSample){
    setSkipSample(true)
   }
   if(product?.shippingValues?.firstname1){
    setValue("firstname1", product.shippingValues.firstname1);
   }
   if(product?.shippingValues?.lastname1){
    setValue("lastname1", product.shippingValues.lastname1);
   }
   if(product?.shippingValues?.address1){
    setValue("address1", product.shippingValues.address1);
   }
   if(product?.shippingValues?.city1){
    setValue("city1",product.shippingValues.city1);
   }
   if(product?.shippingValues?.state1){
    setValue("state1",product.shippingValues.state1);
   }
   if(product?.shippingValues?.zip1){
    setValue('zip1',product?.shippingValues?.zip1)
   }
   if(product?.shippingValues?.country1){
    setValue('country1',product?.shippingValues?.country1)
   }
   if(product?.shippingValues?.contact1){
    setValue('contact1',product?.shippingValues?.contact1)
   }
   if (product?.shippingValues?.apartment1) {
    setValue("apartment1", product.shippingValues.apartment1);
  }
  if (product?.shippingValues?.company1) {
    setValue("company1", product?.shippingValues?.company1);
  }

   
   if(product?.billingValues?.firstname2){
    setValue("firstname2", product.billingValues.firstname2);
   }
   if(product?.billingValues?.lastname2){
    setValue("lastname2", product.billingValues.lastname2);
   }
   if(product?.billingValues?.address2){
    setValue("address2", product.billingValues.address2);
   }
   if(product?.billingValues?.city2){
    setValue("city2", product.billingValues.city2);
   }
   
   if(product?.billingValues?.state2){
    setValue("state2",product.billingValues.state2);
   }
   if(product?.billingValues?.zip2){
    setValue('zip2',product?.billingValues?.zip2)
   }
   if(product?.billingValues?.country1){
    setValue('country2',product?.billingValues?.country2)
   }
   if(product?.billingValues?.contact2){
    setValue('contact2',product?.billingValues?.contact2)
   }
   if (product?.billingValues?.apartment2) {
    setValue("apartment2", product.billingValues.apartment2);
  }
  if (product?.billingValues?.company2) {
    setValue("company2", product?.billingValues?.company2);
  }
  if(product.tax){
    setTax(product.tax)
  }
  
},[])

 
  const active = useSelector((state) => state.activeStep);
//   useEffect(() => {
//     console.log(active)
//    if(active!==6){
//    navigate('/select')
//     }
//  }, []);

  const product = useSelector((state) => state.productSummary);
 
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // if (active != 6) {
    //   const initialState = {
    //     name: "",
    //     img: "",
    //     category: "",
    //     wide: 0,
    //     long: 0,
    //     quantity: {
    //       size: 0,
    //       price: 0,
    //     },
    //     finalPrice: 0,
    //     embroidary: {
    //       label: "",
    //       price: 0,
    //     },
    //     backing: {
    //       label: "",
    //       price: 0,
    //     },
    //     border: {
    //       label: "",
    //       price: 0,
    //     },
    //   };
    //   dispatch(productSummary({ initialState }));
    //   dispatch(activeStep(0));
    //   navigate("/");
    // }
    if (localStorage.getItem("shippingAddress")) {
      const shipping = JSON.parse(localStorage.getItem("shippingAddress"));

      setValue("firstname1", shipping.firstname1);
      setValue("lastname1", shipping.lastname1);
      setValue("address1", shipping.address1);
      setValue("city1", shipping.city1);
      setValue("state1", shipping.state1);
      if (shipping.state1 === "Texas(TX)") {
        if (tax == 0) {
          setFinalPrice(
            Math.round((finalPrice * 1.0825 + Number.EPSILON) * 100) / 100
          );
          setTax(
            Math.round((finalPrice * 0.0825 + Number.EPSILON) * 100) / 100
          );
        }
      }
      setValue("zip1", shipping.zip1);
      setValue("contact1", shipping.contact1);
      setValue("country1", shipping.country1);
      if (shipping.apartment1) {
        setValue("apartment1", shipping.apartment1);
      }
      if (shipping.company1) {
        setValue("company1", shipping.company1);
      }
    }
    if (localStorage.getItem("billingAddress")) {
      const billing = JSON.parse(localStorage.getItem("billingAddress"));

      setValue("firstname2", billing.firstname2);
      setValue("lastname2", billing.lastname2);
      setValue("address2", billing.address2);
      setValue("city2", billing.city2);
      setValue("state2", billing.state2);
      setValue("zip2", billing.zip2);
      setValue("contact2", billing.contact2);
      setValue("country2", billing.country2);
      if (billing.apartment2) {
        setValue("apartment2", billing.apartment2);
      }
      if (billing.company2) {
        setValue("company2", billing.company2);
      }
    }
  }, []);

  const [finalPrice, setFinalPrice] = useState(product.finalPrice);
  const [tax, setTax] = useState(0);
  const [skipSample, setSkipSample] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(false);
  const [sameShippingAddress, setSameShippingAddress] = useState(false);
  const [billingAddress, setBillingAddress] = useState(false);
  const [IsexpeditedOrder, setIsExpeditedOrder] = useState(false);
  const handleSample = () => {
    setSkipSample(!skipSample);
  };

  const handleShippingAddress = () => {
    setShippingAddress(!shippingAddress);
  };

  const handleSameShippingAddress = () => {
    setSameShippingAddress(!sameShippingAddress);
    if (!sameShippingAddress) {
      // setBillingValues(shippingValues);
      const billing = getValues();
      setValue("firstname2", billing.firstname1);
      setValue("lastname2", billing.lastname1);
      setValue("address2", billing.address1);
      setValue("city2", billing.city1);
      setValue("state2", billing.state1);
      setValue("zip2", billing.zip1);
      setValue("contact2", billing.contact1);
      setValue("country2", billing.country1);
      if (billing.apartment1) {
        setValue("apartment2", billing.apartment1);
      }
      if (billing.company1) {
        setValue("company2", billing.company1);
      }
    }
  };

  const handleBillingAddress = () => {
    setBillingAddress(!billingAddress);
  };

  const onSubmit = (data) => {
    // e.preventDefault();
    // console.log(data);
    const {
      firstname1,
      lastname1,
      address1,
      contact1,
      zip1,
      city1,
      state1,
      country1,
      apartment1,
      company1,
      method,
      firstname2,
      lastname2,
      address2,
      contact2,
      zip2,
      city2,
      state2,
      country2,
      apartment2,
      company2,
    } = data;

    const shippingValues = {
      firstname1,
      lastname1,
      address1,
      contact1,
      zip1,
      city1,
      state1,
      country1,
      apartment1,
      company1,
    };

    const billingValues = {
      firstname2,
      lastname2,
      address2,
      contact2,
      zip2,
      city2,
      state2,
      country2,
      apartment2,
      company2,
    };
    // if (shippingValues.state1 == 'Texas(TX)') {
    //   console.log(finalPrice);
    //   setFinalPrice(
    //     (pre) => Math.round((pre * 1.0825 + Number.EPSILON) * 100) / 100
    //   );
    //   console.log(finalPrice);
    // }

    if (shippingAddress) {
      localStorage.setItem("shippingAddress", JSON.stringify(shippingValues));
    }
    // else{
    //   localStorage.removeItem('shippingAddress')
    // }
    if (billingAddress) {
      localStorage.setItem("billingAddress", JSON.stringify(billingValues));
    }
    // else{
    //   localStorage.removeItem('billingAddress')
    // }
    dispatch(
      productSummary({
        ...product,
        skipSample,
        shippingValues,
        billingValues,
        deliveryMethod: method,
        finalPrice,
        tax,
      })
    );
    console.log(product);
    setShow(true);
    // For inital time
    // dispatch(activeStep(7));
    // navigate('/checkout')
  };

  return (
    <div className="bg-brand text-black">
      {show && <CheckoutSuccess />}
      {/*-----navbar-----*/}
      <div className="container h-40 bg-white mx-auto px-2">
        <Navbar />
      </div>
      <div className="flex flex-col" id="steps_container">
        <div className=" flex flex-row xl:mx-40">
          <Progressbar />
        </div>
      </div>

      {/*----Step 6----*/}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid lg:grid-cols-2 grid-col-1 md:gap-14 lg:gap-20 lg:place-content-around mt-12">
          <div className="ml-2 sm:ml-12 md:ml-24 md:pl-1 pl-2 ">
            <div className="mt-12 pl-6 md:pl-12 font-sans font-semibold text-2xl lg:text-3xl xl:text-4xl  uppercase">
              Step-6
            </div>
            <div className="text-sm pl-10 md:pl-16 my-4 text-gray-500 font-medium lg:text-lg ">
              Final Step
            </div>
            <div className="font-semibold  ml-2 md:ml-10 text-lg md:text-xl text-gray-500 mt-14 uppercase tracking-[0.25em] ">
              Select Production Time
            </div>
            <div className="font-normal text-sm sm:text-base ml-2 md:ml-10 tracking-tight text-md mt-4 w-[90%] md:w-[55%] text-center">
              Production time is based on the time to manufacture and ship your
              products upon approval of digital mockup and physical sample. Your
              order arrival date will update on the right as you make your
              selections.
            </div>
            <div className="ml-4 md:ml-10 mt-10 grid grid-cols-2 w-[95%] sm:w-96">
              <div className="text-center w-40">
                <img src={standard} />
                <div className="font-semibold  text-gray-500 mt-2 text-xl uppercase tracking-[0.25em]">
                  Standard
                </div>
                <p className="text-md mb-4">(25-30 Days)</p>
                <input
                  required
                  onClick={(e) => {
                    if (e.target.value == "expedite") {
                      setIsExpeditedOrder(true);

                      setFinalPrice(
                        product.quantity.size * product.pricePerPatch +
                          product.embroidary.price +
                          product.quantity.size * product.pricePerPatch * 0.25 +
                          (skipSample ? 20.0 : 0)+tax
                      );
                    } else {
                      setIsExpeditedOrder(false);
                      setFinalPrice(
                        product.quantity.size * product.pricePerPatch +
                          product.embroidary.price +
                          (skipSample ? 20.0 : 0) + tax
                      );
                    }
                  }}
                  checked={!IsexpeditedOrder}
                  type="radio"
                  name="method"
                  value="standard"
                  {...register("method")}
                />
              </div>
              <div className="text-center w-40 justify-self-end">
                <img src={expedite} />
                <div className="font-semibold  text-gray-500 mt-4 text-xl uppercase tracking-[0.25em]">
                  Expedite
                </div>
                <p className="text-md mb-4">(14-18 Days)</p>
                <input
                  required
                  onClick={(e) => {
                    if (e.target.value == "expedite") {
                      setIsExpeditedOrder(true);

                      setFinalPrice(
                        product.quantity.size * product.pricePerPatch +
                          product.embroidary.price +
                          product.quantity.size * product.pricePerPatch * 0.25 +
                          (skipSample ? 20.0 : 0) +tax
                      );
                    } else {
                      setIsExpeditedOrder(false);
                      setFinalPrice(
                        product.quantity.size * product.pricePerPatch +
                          product.embroidary.price +
                          (skipSample ? 20.0 : 0)+tax
                      );
                    }
                  }}
                  checked={IsexpeditedOrder}
                  type="radio"
                  name="method"
                  value="expedite"
                  {...register("method")}
                />
              </div>
            </div>
            <div className="mt-16 w-4/5 ml-6 md:ml-12 h-fit border-[7px] border-brand-icon grid place-items-center">
              <div className="w-full font-semibold  text-gray-500 mt-3  md:ml-0 text-left  uppercase text-lg md:text-xl tracking-[0.2em] px-6">
                <input
                  type="checkbox"
                  name="skipSample"
                  value={skipSample}

                  className="mr-4"
                  // onClick={handleSample}
                  onClick={(e) => {
                    setSkipSample(!skipSample);
                    if (IsexpeditedOrder) {
                      setFinalPrice(
                        product.quantity.size * product.pricePerPatch +
                          product.embroidary.price +
                          product.quantity.size * product.pricePerPatch * 0.25 +
                          (skipSample ? 0 : 20.0)+tax
                      );
                    } else {
                      setFinalPrice(
                        product.quantity.size * product.pricePerPatch +
                          product.embroidary.price +
                          (skipSample ? 0 : 20.0)+tax
                      );
                    }
                  }}
                  checked={skipSample}
                  className="w-5 h-5 mr-1"
                />
                Make Sample
              </div>
              <div className=" font-normal text-sm sm:text-base tracking-tight text-md px-6 my-4 text-left">
                By enabling ‘Make Sample’ a sample patch will be manufactured
                and image(s) of the patch will be emailed for your approval
                within 5 business days. The production time of the patches will
                start from the time the sample patch is approved.
              </div>
            </div>
            <div className="mt-12 w-4/5 ml-6 md:ml-12 h-fit">
              <div className="font-semibold  text-gray-500 uppercase text-xl tracking-[0.25em]">
                Shipping Address
              </div>
              <div className="font-normal text-sm sm:text-base tracking-tight text-md my-6">
                Please note that we do ship to FPO and APO addresses, but do not
                ship to PO Boxes
              </div>
              <div className="">
                <input
                  style={{ border: "1.5px solid #A6A6A6" }}
                  className="border border-black rounded    placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2 w-full bg-transparent"
                  name="country1"
                  type="text"
                  readOnly="true"
                  value="United States"
                  placeholder="Country"
                  {...register("country1", {
                    required: "Country is required",
                    minLength: {
                      value: 2,
                      message: "Atleast 2 characters",
                    },
                    maxLength: {
                      value: 60,
                      message: "Max 60 characters",
                    },
                  })}
                ></input>
                <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                  {errors.country1?.message}
                </p>
              </div>
              <div className="mt-4 flex justify-between ">
                <div className="w-[48%]">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    className="w-full rounded appearance-none border bg-transparent  placeholder:tracking-[0.18em]  placeholder:text-gray-500 p-2"
                    name="firstname1"
                    type="text"
                    placeholder="First Name"
                    {...register("firstname1", {
                      required: "First Name is required",
                      minLength: {
                        value: 2,
                        message: "Atleast 2 characters",
                      },
                      maxLength: {
                        value: 50,
                        message: "Max 50 characters",
                      },
                    })}
                  ></input>
                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.firstname1?.message}
                  </p>
                </div>
                <div className="w-[48%]">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    className="w-full rounded appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2"
                    name="lastname1"
                    type="text"
                    placeholder="Last Name"
                    {...register("lastname1", {
                      required: "Last Name is required",
                      minLength: {
                        value: 2,
                        message: "Atleast 2 characters",
                      },
                      maxLength: {
                        value: 50,
                        message: "Max 50 characters",
                      },
                    })}
                  ></input>
                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.lastname1?.message}
                  </p>
                </div>
              </div>

              <div className="">
                <input
                  style={{ border: "1.5px solid #A6A6A6" }}
                  className="rounded appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2 mt-3 w-full resize-none"
                  name="company1"
                  type="text"
                  placeholder="Company Name (Optional)"
                  {...register("company1")}
                ></input>
              </div>
              <div className="flex justify-between">
                <div className="w-[72.5%] ">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    type="text"
                    className="rounded  appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500  p-2 mt-4 w-full resize-none "
                    name="address1"
                    placeholder="Address"
                    {...register("address1", {
                      required: "Address is required",
                      minLength: {
                        value: 5,
                        message: "Atleast 5 characters",
                      },
                      maxLength: {
                        value: 200,
                        message: "Max 200 characters",
                      },
                    })}
                  />
                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.address1?.message}
                  </p>
                </div>
                <div className="w-[25%]">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    className="rounded appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2 mt-4  w-full resize-none"
                    name="apartment1"
                    type="text"
                    placeholder="Apt/Suite"
                    {...register("apartment1")}
                  ></input>
                </div>
              </div>

              <div className="flex flex-col md:flex-row justify-between mt-3 ">
                <div className="w-full md:w-[40%]">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    className=" rounded w-[100%] appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2"
                    name="city1"
                    type="text"
                    placeholder="City"
                    {...register("city1", {
                      required: "City is required",
                      minLength: {
                        value: 2,
                        message: "Atleast 2 characters",
                      },
                      maxLength: {
                        value: 60,
                        message: "Max 60 characters",
                      },
                    })}
                  ></input>
                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.city1?.message}
                  </p>
                </div>
                <div className="w-full md:w-[30%] mt-4 md:mt-0">
                  <select
                    style={{ border: "1.5px solid #A6A6A6" }}
                    name="state1"
                    className=" bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2  w-full border-none outline-none"
                    {...register("state1", {
                      required: "Required",
                    })}
                    onClick={(e) => {
                      console.log(e.target.value);
                      let fp=product.quantity.size * product.pricePerPatch +
                      product.embroidary.price +
                      (IsexpeditedOrder?product.quantity.size * product.pricePerPatch * 0.25:0)  +(skipSample ? 20.0 : 0);

                      let withtax = ((fp* 1.0825 + Number.EPSILON) * 100
                      ) / 100
                     
                     
                      if (e.target.value == "Texas(TX)") {
                        
                         
                         
                          // setFinalPrice(
                          //   product.quantity.size * product.pricePerPatch +
                          //   product.embroidary.price +
                          //   (IsexpeditedOrder?product.quantity.size * product.pricePerPatch * 0.25:0)  +(skipSample ? 20.0 : 0)
                          // )
                         
                          setFinalPrice(
                            Math.round(withtax ));
                          setTax(
                            Math.round(
                              ((product.quantity.size * product.pricePerPatch +
                                product.embroidary.price +
                                (IsexpeditedOrder?product.quantity.size * product.pricePerPatch * 0.25:0)  +(skipSample ? 20.0 : 0)) * 0.0825 + Number.EPSILON) * 100
                            ) / 100
                          );
                        
                      } else {
                        console.log(tax);
                        setFinalPrice(fp);
                        setTax(0);
                        console.log(finalPrice);
                      }
                    }}
                  >
                    {states.map((state) => (
                      <option key={state} value={state} className="bg-white">
                        {state}
                      </option>
                    ))}
                  </select>

                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.state1?.message}
                  </p>
                </div>

                <div className="w-full  md:w-[25%] mt-3 md:mt-0">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    className="rounded appearance-none border w-[100%]  bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2 outline-none"
                    name="zip1"
                    type="number"
                    placeholder="ZIP"
                    {...register("zip1", {
                      required: "Zip iz required",
                      minLength: {
                        value: 5,
                        message: "Atleast 5",
                      },
                      maxLength: {
                        value: 10,
                        message: "Max 10",
                      },
                    })}
                  ></input>
                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.zip1?.message}
                  </p>
                </div>
              </div>

              <div className="mt-3 w-[100%] md:w-[40%] ">
                <input
                  style={{ border: "1.5px solid #A6A6A6" }}
                  className="rounded  appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2 w-full "
                  name="contact1"
                  type="number"
                  placeholder="Phone"
                  {...register("contact1", {
                    required: "Contact is required",
                    minLength: {
                      value: 10,
                      message: "Min 10 characters",
                    },
                    maxLength: {
                      value: 11,
                      message: "Max 11 characters",
                    },
                  })}
                ></input>
                <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                  {errors.contact1?.message}
                </p>
              </div>

              <div className="font-bold  text-gray-500 mt-8  w-max uppercase tracking-[0.2em]">
                <input
                  type="radio"
                  name="shippingAddress"
                  value="save"
                  className="mr-4"
                  onClick={handleShippingAddress}
                  checked={shippingAddress}
                />
                Save Shipping Address
              </div>
            </div>

            <div className="mt-16 w-4/5 ml-6 md:ml-12 h-fit">
              <div className="font-semibold  text-gray-500 uppercase text-xl tracking-[0.25em]">
                Billing Address
              </div>
              <div className="font-bold w-max text-gray-500 mt-10 uppercase tracking-[0.15em] md:tracking-[0.2em]">
                <input
                  type="radio"
                  name="sameshippingAddress"
                  value="same"
                  className="mr-4"
                  onClick={handleSameShippingAddress}
                  checked={sameShippingAddress}
                />
                Same As Shipping Address
              </div>
              <div className="font-normal text-sm sm:text-base tracking-tight text-md my-6">
                Please note that we do ship to FPO and APO addresses, but do not
                ship to PO Boxes
              </div>

              <div className="">
                <input
                  style={{ border: "1.5px solid #A6A6A6" }}
                  className="border border-black rounded    placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2 w-full bg-transparent"
                  name="country2"
                  type="text"
                  value="United States"
                  placeholder="Country"
                  readOnly="true"
                  {...register("country2", {
                    required: "Country is required",
                    minLength: {
                      value: 2,
                      message: "Atleast 2 characters",
                    },
                    maxLength: {
                      value: 60,
                      message: "Max 60 characters",
                    },
                  })}
                ></input>
                <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                  {errors.country2?.message}
                </p>
              </div>
              <div className="mt-4 flex justify-between">
                <div className="w-[48%]">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    className="w-full rounded appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2"
                    name="firstname2"
                    type="text"
                    placeholder="First Name"
                    {...register("firstname2", {
                      required: "First Name is required",
                      minLength: {
                        value: 2,
                        message: "Atleast 2 characters",
                      },
                      maxLength: {
                        value: 50,
                        message: "Max 50 characters",
                      },
                    })}
                  ></input>
                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.firstname2?.message}
                  </p>
                </div>
                <div className="w-[48%]">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    className="w-full rounded appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2"
                    name="lastname2"
                    type="text"
                    placeholder="Last Name"
                    {...register("lastname2", {
                      required: "Last Name is required",
                      minLength: {
                        value: 2,
                        message: "Atleast 2 characters",
                      },
                      maxLength: {
                        value: 50,
                        message: "Max 50 characters",
                      },
                    })}
                  ></input>
                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.lastname2?.message}
                  </p>
                </div>
              </div>

              <div className="">
                <input
                  style={{ border: "1.5px solid #A6A6A6" }}
                  className="rounded appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2 mt-3 w-full resize-none"
                  name="company2"
                  type="text"
                  placeholder="Company Name (Optional)"
                  {...register("company2")}
                ></input>
              </div>
              <div className="flex justify-between mt-4">
                <div className=" w-[72.5%]">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    type="text"
                    className="rounded  appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500  p-2  w-full resize-none"
                    name="address2"
                    placeholder="Address"
                    {...register("address2", {
                      required: "Address is required",
                      minLength: {
                        value: 5,
                        message: "Atleast 5 characters",
                      },
                      maxLength: {
                        value: 200,
                        message: "Max 200 characters",
                      },
                    })}
                  />
                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.address2?.message}
                  </p>
                </div>
                <div className="w-[25%]">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    className="rounded appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2  w-full resize-none"
                    name="apartment2"
                    type="text"
                    placeholder="Apt/Suite"
                    {...register("apartment2")}
                  ></input>
                </div>
              </div>

              <div className="flex flex-col md:flex-row justify-between mt-3 ">
                <div className="w-full md:w-[40%]">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    className=" rounded w-[100%] appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2"
                    name="city2"
                    type="text"
                    placeholder="City"
                    {...register("city2", {
                      required: "City is required",
                      minLength: {
                        value: 2,
                        message: "Atleast 2 characters",
                      },
                      maxLength: {
                        value: 60,
                        message: "Max 60 characters",
                      },
                    })}
                  ></input>
                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.city2?.message}
                  </p>
                </div>
                <div className="w-full md:w-[30%] mt-3 md:mt-0">
                  <select
                    style={{ border: "1.5px solid #A6A6A6" }}
                    name="state2"
                    className=" bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2  w-full border-none outline-none"
                    {...register("state2", {
                      required: "Required",
                    })}
                  >
                    {states.map((state) => (
                      <option key={state} value={state} className="bg-white">
                        {state}
                      </option>
                    ))}
                  </select>

                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.state2?.message}
                  </p>
                </div>

                <div className="w-full  md:w-[25%] mt-3 md:mt-0">
                  <input
                    style={{ border: "1.5px solid #A6A6A6" }}
                    className="rounded appearance-none border w-[100%]  bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 p-2 outline-none"
                    name="zip2"
                    type="number"
                    placeholder="ZIP"
                    {...register("zip2", {
                      required: "Zip iz required",
                      minLength: {
                        value: 5,
                        message: "Atleast 5",
                      },
                      maxLength: {
                        value: 10,
                        message: "Max 10",
                      },
                    })}
                  ></input>
                  <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                    {errors.zip2?.message}
                  </p>
                </div>
              </div>

              <div className="mt-3 w-[100%] md:w-[40%] ">
                <input
                  style={{ border: "1.5px solid #A6A6A6" }}
                  className="rounded appearance-none border bg-transparent  placeholder:tracking-[0.18em] placeholder:text-gray-500 
                  p-2 w-full "
                  name="contact2"
                  type="number"
                  placeholder="Phone"
                  {...register("contact2", {
                    required: "Contact is required",
                    minLength: {
                      value: 10,
                      message: "Min 10 characters",
                    },
                    maxLength: {
                      value: 11,
                      message: "Max 11 characters",
                    },
                  })}
                ></input>
                <p className="text-red-500 text-xs md:text-sm mt-2 pl-2">
                  {errors.contact2?.message}
                </p>
              </div>
              <div className="font-bold  text-gray-500 mt-8 uppercase w-max tracking-[0.2em]">
                <input
                  type="radio"
                  name="billingAddress"
                  value="save"
                  className="mr-4"
                  onClick={handleBillingAddress}
                  checked={billingAddress}
                />
                Save Billing Address
              </div>

              
            </div>
          </div>

          {/* card */}
          {product.category && (
            <div className="mt-20 md:mt-6 bg-gray-200 h- mx-auto h-fit pb-20  lg:w-[80%] md:w-8/12 w-5/6  rounded-[3rem] uppercase">
              {/* <img
              className="h-44 w-44 mt-20 mx-auto rounded-3xl"
              src={product.img}
            ></img> */}
              <div className="mt-8 font-semibold tracking-[.3rem] text-xl text-center">
                Order summary
              </div>
              <div className="mt-8 grid grid-cols-2 tracking-[.02rem]  sm:tracking-[.1rem] justify-around w-full px-4 sm:px-16">
                {/* <span className="justify-self-start ">Product</span> */}
                {/* <span className="justify-self-end sm:justify-self-end sm:pr-8 text-brand-planetarium cursor-pointer">
                  Edit
                </span> */}
                {/* <span className="mt-4  w-max">Category - {product.category}</span> */}
                {/* <span className="justify-self-end sm:justify-self-end sm:pr-8 text-brand-planetarium mt-2 cursor-pointer">
                  Edit
                </span> */}
              </div>
              <div className="pl-4 sm:pl-16 my-4 tracking-[.02rem] w-max sm:tracking-[.1rem]">
                Product - {product.name}
              </div>
              <div className="ml-4 sm:ml-16 mt-4 bg-brand-icon  h-[.3rem] w-11/12 sm:w-9/12"></div>
              {/* <div className="pl-4 sm:pl-16 mt-8 ">
              Embroidered {product.category} Customization
            </div> */}
              {/* <div className="pl-4 sm:pl-16 mt-4">75 %</div> */}

              <div className="pl-8 sm:pl-20 mt-4 grid grid-cols-2">
                <span className="normal-case">
                  Custom Size {product.long}in X {product.wide}in
                </span>
                <span className="justify-self-center">
                  ${product.customPrice.toFixed(2)}
                </span>
              </div>

              <div className="pl-8 sm:pl-20 mt-2 grid grid-cols-2 normal-case">
                <span className="w-max">Iron on Backing</span>
                <span className="justify-self-center">
                  ${product.backing.show.toFixed(2)}
                </span>
              </div>
              <div className="pl-8 sm:pl-20 mt-2 grid grid-cols-2 normal-case">
                <span className="w-max">{product.border.label}</span>
                <span className="justify-self-center">
                  ${product.border.show.toFixed(2)}
                </span>
              </div>
              <div className="pl-8 sm:pl-20 mt-2 grid grid-cols-2 normal-case">
                <span className="w-max">Color</span>
                <span className="justify-self-center">
                  ${product.color.show.toFixed(2)}
                </span>
              </div>
              <div className="pl-8 sm:pl-20 font-bold mt-2 grid grid-cols-2">
                <span className=" normal-case">Price per patch</span>
                <span className="justify-self-center">
                  ${product.pricePerPatch.toFixed(2)}
                </span>
              </div>
              <div className="pl-8 sm:pl-20 mt-8 grid grid-cols-2">
                <span className="normal-case">
                  Price for QTY {product.quantity.size}
                </span>
                <span className="justify-self-center">
                  ${(product.quantity.size * product.pricePerPatch).toFixed(2)}
                </span>
              </div>
              <div className="pl-8 sm:pl-20 mt-2 grid grid-cols-2 normal-case">
                <span className="w-max">Embroidery</span>
                <span className="justify-self-center">
                  ${product.embroidary.price.toFixed(2)}
                </span>
              </div>
              {IsexpeditedOrder && (
                <div className="pl-8 sm:pl-20 mt-2 grid grid-cols-2 normal-case">
                  <span className="w-max">Expedited Order</span>
                  <span className="justify-self-center">
                    $
                    {(
                      (product.quantity.size * product.pricePerPatch).toFixed(
                        2
                      ) * 0.25
                    ).toFixed(2)}
                  </span>
                </div>
              )}
              {skipSample && (
                <div className="pl-8 sm:pl-20 mt-2 grid grid-cols-2 normal-case">
                  <span className="w-max">Sample Patch</span>
                  <span className="justify-self-center">${"20.00"}</span>
                </div>
              )}

              <div className="pl-8 sm:pl-20 font-bold mt-6 grid grid-cols-2">
                <span className=" normal-case">Subtotal</span>
                <span className="justify-self-center">
                  $
                  {IsexpeditedOrder
                    ? (
                        product.quantity.size * product.pricePerPatch +
                        product.embroidary.price +
                        product.quantity.size * product.pricePerPatch * 0.25 +
                        (skipSample ? 20.0 : 0.0)
                      ).toFixed(2)
                    : (
                        product.quantity.size * product.pricePerPatch +
                        product.embroidary.price +
                        (skipSample ? 20.0 : 0.0)
                      ).toFixed(2)}
                </span>
              </div>
              {tax > 0 && (
                <>
                  {/* <div className="pl-8 sm:pl-20 font-bold mt-3 grid grid-cols-2">
                    <span className=" normal-case">Tax </span>
                    <span className="justify-self-center">8.25%</span>
                  </div> */}
                  <div className="pl-8 sm:pl-20 font-bold mt-3 grid grid-cols-2">
                    <span className=" normal-case">Tax Amount (8.25%)</span>
                    <span className="justify-self-center">${tax.toFixed(2)}</span>
                  </div>
                </>
              )}
              <div className="ml-4 sm:ml-16 mt-4 bg-brand-icon  h-[.3rem] w-11/12 sm:w-9/12"></div>
              <div className="pl-6 sm:pl-16 mt-10 grid grid-cols-2 font-bold text-xl">
                <span className="tracking-[.3rem] w-max">Total</span>
                <span className="justify-self-center ">
                  ${(+finalPrice).toFixed(2)}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="grid justify-center">
          <button
            className=" bg-sky-700 hover:bg-sky-600 text-white text-2xl  mt-16 mb-20 rounded-full h-14 w-max px-6"
            type="submit"
          >
            Continue To Payment
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
}
