import React from "react";
import { Link } from "react-router-dom";
import ImageViewer from "./ImageViewer";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Patch = ({ data }) => {
  const filterChenille = data?.filter((el) => el.category === "Chenille");
  const filterEmbroidery = data?.filter((el) => el.category === "Embroidery");
  const filterWoven = data?.filter((el) => el.category === "Woven");
  const filterVeganLeather = data?.filter(
    (el) => el.category === "Vegan Leather"
  );

  return (
    <div className="flex flex-col gap-4">
      {filterChenille.length !== 0 && (
        <div className="flex flex-col gap-4">
          <Link to="/chenille-patches" className="text-[#918188] text-2xl">
            Chenille
          </Link>
          <div className="flex overflow-x-scroll scrollbar scrollbar-thumb-[#e5e7eb] scrollbar-h-0 scrollbar-m md:scrollbar-h-2 scrollbar-thumb-rounded-lg pb-3 gap-2">
            {filterChenille
              .sort((a, b) => a.index - b.index)
              .map((el) => (
                <div className="flex flex-col  gap-2 " key={el._id}>
                  <ImageViewer images={el.images} />
                  <div className="">
                    <p className="text-[14px] mt-4 text-red-600 font-bold capitalize">
                      {el.name}
                    </p>
                    <pre className="text-[14px] text-red-600 font-sans ">
                      {el.description}
                    </pre>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {filterEmbroidery.length !== 0 && (
        <div className="flex flex-col gap-4">
          <Link
            to="/patch/embroidery?page=info"
            className="text-[#918188] text-2xl"
          >
            Embroidery
          </Link>
          <div className="flex overflow-x-scroll scrollbar scrollbar-thumb-[#e5e7eb] scrollbar-h-0 scrollbar-m md:scrollbar-h-2 scrollbar-thumb-rounded-lg pb-3 gap-2">
            {filterEmbroidery
              .sort((a, b) => a.index - b.index)
              .map((el) => (
                <div className="flex flex-col  gap-2" key={el._id}>
                  <ImageViewer images={el.images} />
                  <div className="">
                    <p className="text-[14px] mt-4 text-red-600 font-bold capitalize">
                      {el.name}
                    </p>
                    <pre className="text-[14px] text-red-600 font-sans ">
                      {el.description}
                    </pre>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {filterWoven.length !== 0 && (
        <div className="flex flex-col gap-4">
          <Link to="/patch/woven" className="text-[#918188] text-2xl">Woven</Link>
          <div className="flex overflow-x-scroll scrollbar scrollbar-thumb-[#e5e7eb] scrollbar-h-0 scrollbar-m md:scrollbar-h-2 scrollbar-thumb-rounded-lg pb-3 gap-2">
            {filterWoven
              .sort((a, b) => a.index - b.index)
              .map((el) => (
                <div className="flex flex-col  gap-2" key={el._id}>
                  <ImageViewer images={el.images} />
                  <div className="">
                    <p className="text-[14px] mt-4 text-red-600 font-bold capitalize">
                      {el.name}
                    </p>
                    <pre className="text-[14px] text-red-600 font-sans ">
                      {el.description}
                    </pre>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {filterVeganLeather.length !== 0 && (
        <div className="flex flex-col gap-4">
          <Link to={`/patch/vegan-leather?page=info`} className="text-[#918188] text-2xl">Vegan Leather</Link>
          <div className="flex overflow-x-scroll scrollbar scrollbar-thumb-[#e5e7eb] scrollbar-h-0 scrollbar-m md:scrollbar-h-2 scrollbar-thumb-rounded-lg pb-3 gap-2">
            {filterVeganLeather
              .sort((a, b) => a.index - b.index)
              .map((el) => (
                <div className="flex flex-col  gap-2" key={el._id}>
                  <ImageViewer images={el.images} />
                  <div className="">
                    <p className="text-[14px] mt-4 text-red-600 font-bold capitalize">
                      {el.name}
                    </p>
                    <pre className="text-[14px] text-red-600 font-sans ">
                      {el.description}
                    </pre>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Patch;
