/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import productSummary from "../../../../actions/productSummary";

const ProductSize = ({ setCrop, setFitImage, imageRatio , patchPriceFormat }) => {
  const options = Array.from(
    { length: (15 - 3) / 0.5 + 1 },
    (_, index) => 3 + index * 0.5
  );
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  const handelChange = (value, type, data) => {
    dispatch(
      productSummary({
        ...product,
        ...data,
        finalPrice: patchPriceFormat({
          quantity: product.quantity,
          wide: type === "wide" ? value : product.wide,
          long: type === "long" ? value : product.long,
        }).finalPrice,
        quantity: {
          ...product.quantity,
          price: patchPriceFormat({
            quantity: product.quantity,
            wide: type === "wide" ? value : product.wide,
            long: type === "long" ? value : product.long,
          }).finalPrice,
        },
        customPrice: patchPriceFormat({
          quantity: product.quantity,
          wide: type === "wide" ? value : product.wide,
          long: type === "long" ? value : product.long,
        }).pricePerPatch,
        pricePerPatch: patchPriceFormat({
          quantity: product.quantity,
          wide: type === "wide" ? value : product.wide,
          long: type === "long" ? value : product.long,
        }).pricePerPatch,
      })
    );
    const width = type === "wide" ? value * 41.8 : product.wide * 41.8;
    const height = type === "long" ? value * 41.8 : product.long * 41.8;
    const patchRatio = width / height;

    setFitImage(
      imageRatio >= patchRatio
        ? type === "long"
          ? value * 41.8
          : product.long * 41.8
        : type === "wide"
        ? value * 41.8
        : product.wide * 41.8
    );
    setCrop({
      x: 0,
      y: 0,
      width: width,
      height: height,
      unit: "px",
    });
  };
  return (
    <div className="space-y-2">
      <p className="text-sm">Patch Size (in inches)</p>
      <div className="flex gap-4 ">
        <div className="flex flex-col items-center justify-center gap-2">
          <select
            className="bg-[#e5e7eb]  rounded text-center font-semibold w-20 p-1"
            value={Number(product.wide)}
            onChange={(e) =>
              handelChange(e.target.value, "wide", { wide: e.target.value })
            }
          >
            <option disabled>Select Width</option>
            {options.map((el, i) => (
              <option key={i} value={el}>
                {el}
              </option>
            ))}
          </select>
          <p className="text-sm">Width</p>
        </div>
        <div className="flex flex-col items-center justify-center gap-2">
          <select
            className="bg-[#e5e7eb] p-1 rounded text-center font-semibold w-20"
            value={Number(product.long)}
            onChange={(e) =>
              handelChange(e.target.value, "long", { long: e.target.value })
            }
          >
            <option disabled>Select Height</option>
            {options.map((el, i) => (
              <option key={i} value={el} className="bg-white">
                {el}
              </option>
            ))}
          </select>
          <p className="text-sm">Height</p>
        </div>
      </div>
    </div>
  );
};

export default ProductSize;
