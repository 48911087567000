import React, { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const ImageComponent = ({ item, setShowPopup, setPopUp }) => {
  const [i, setI] = useState(0);

  return (
    <>
      <div
        onClick={() => {
          setShowPopup(true);
          setPopUp({ currentIndex: i, images: item?.images });
        }}
        className="relative showOnHover "
      >
        <AiOutlineLeft
          onClick={(e) => {
            e.stopPropagation();
            if (i === 0) {
              setI(item.images.length - 1);
            }
            i > 0 && setI(i - 1);
          }}
          size={12}
          // color="white"
          cursor={"pointer"}
          className="absolute w-6 h-6 -translate-y-1/2 bg-white rounded-full left-2 top-1/2 ttt"
        />

        <img
          src={item?.images[i]}
          className="mx-auto w-full  object-contain h-[300px]"
          alt=""
        />

        <div className="flex justify-center w-full mt-2 gap-x-2">
          {item?.images?.map((circle, index) => (
            <div
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                setI(index);
              }}
              className={`w-2 h-2 rounded-full cursor-pointer  ${
                index === i ? "bg-[rgb(169,230,255)]" : "bg-[#BDC1C6]"
              }`}
            ></div>
          ))}
        </div>
        <AiOutlineRight
          onClick={(e) => {
            e.stopPropagation();
            if (i === item.images.length - 1) {
              setI(0);
            }
            i < item.images.length - 1 && setI(i + 1);
          }}
          size={12}
          // color="white"
          cursor={"pointer"}
          className="absolute w-6 h-6 -translate-y-1/2 bg-white rounded-full right-2 top-1/2 ttt"
        />
      </div>
      <p className="text-[14px] mt-4 text-red-600 font-bold capitalize">
        {item?.title}
      </p>

      <pre className=" text-[14px] text-red-600 font-sans ">
        {item?.description[i]}
      </pre>
    </>
  );
};

export default ImageComponent;
