import React, { useState } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { FiEdit } from "react-icons/fi";
import { API_URL } from "../../config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spin, message } from "antd";

const ForgotPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const nav = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const resetPasswordHandler = async (e) => {
    e.preventDefault();
    if (newPassword === confirmNewPassword) {
      const regx =
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
      const passwordValidation = regx.test(newPassword);
      if (!passwordValidation) {
        messageApi.open({
          type: "error",
          content:
            "Password should contain atleast one uppercase letter , 1 special character , 1 numeric value and atleast 8 characters long  ",
          duration: 10,
        });
        return;
      }

      const token = searchParams.get("token");

      if (token) {
        setIsProcessing(true);
        try {
          const { data } = await axios.post(
            API_URL + "auth/changePassword",
            {
              newPassword,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setIsProcessing(false);
          if (data?.message === "Password Updated...") {
            message.success("password Reset Successfully");
            nav("/login");
          }
        } catch (err) {
          // console.log(err);
          setIsProcessing(false);
          message.error("Wrong token");
        }
        setIsProcessing(false);
      }
    } else {
      message.error("passord and confirm password not matched ");
    }
  };

  return (
    <div>
      {contextHolder}
      <Navbar />
      <div className="min-h-[calc(100vh-56px)] sm:min-h-[calc(100vh-96px)] ">
        <form
          onSubmit={resetPasswordHandler}
          className=" py-4 pt-10  sm:px-20   w-[90%] sm:w-[80%] md:w-[60%] lg:w-[50%] max-w-[764px] mx-auto"
        >
          <div className="flex items-center ">
            <FiEdit color="#383838" />
            <p className="ml-3 text-2xl text-[#383838]">Reset Password</p>
          </div>

          <input
            type="password"
            className=" border border-[#706a6a] px-3 py-3 w-full mt-8 rounded-md text-xl outline-none"
            placeholder="New Pasword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />

          <input
            type="password"
            className=" border border-[#706a6a] px-3 py-3 w-full mt-4 rounded-md text-xl outline-none"
            placeholder="Confirm New Pasword"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            required
          />

          <button
            disabled={isProcessing}
            type="submit"
            className="mt-6 disabled:bg-blue-500 bg-blue-600 text-white text-xl font-semiblod px-8 py-3 rounded-md hover:bg-blue-700 transition-all ease-out duration-100 block mx-auto"
          >
            Reset
          </button>
        </form>
      </div>
      <Footer />
      {isProcessing && (
        <div className="fixed top-0 left-0 right-0 bottom-0  flex items-center justify-center z-[99999]">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
