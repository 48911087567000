import React, { useEffect, useState } from "react";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import { API_URL } from "../config";
import axios from "axios";
import { Spin, message } from "antd";

const VerifyEmail = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [email, setEmail] = useState("");
  useEffect(()=>{
   const useremail = localStorage.getItem('useremail');
   if(useremail) setEmail(useremail)  
  },[])
  const reVerifyHandler = async (e) => {
    e.preventDefault();
    try {
      setIsProcessing(true);
      const { data } = await axios.post(`${API_URL}auth/requestVerification`, {
        email,
      });
      // setEmail("");

      // console.log(data);
      setIsProcessing(false);
      message.success("Verification email sent to your given email Id.");
    } catch (err) {
      message.error(err?.response?.data?.message);
      setIsProcessing(false);
      // console.log(err)
    }
  };
  return (
    <>
      <Navbar />
      <div className="min-h-[calc(100vh-56px)] sm:min-h-[calc(100vh-96px)] ">
        <form
          onSubmit={reVerifyHandler}
          className="py-4  sm:px-20   w-[90%] sm:w-[90%] md:w-[80%] lg:w-[57%] max-w-[764px] mx-auto"
        >
          <p className="text-xl mt-10 text-justify">
            Activation link expired, please click the below link to resend the
            verification email.
          </p>
          {/* <input
            type="email"
            className=" border border-[#706a6a] px-3 py-3 w-full mt-8 rounded-md text-xl outline-none"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          /> */}
          <button
            disabled={isProcessing}
            className="bg-blue-600 mt-4 text-white px-8 py-3 text-xl disabled:bg-blue-400 rounded-md block mx-auto"
          >
           Resend Verify Email
          </button>
        </form>
      </div>
      <Footer />
      {isProcessing && (
        <div className="fixed top-0 left-0 right-0 bottom-0  flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
