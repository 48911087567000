import React from "react";
import { useLocation } from "react-router-dom";
import Info from "../common/info/Info";
import UploadArtwork from "../common/upload/UploadArtwork";
import { patchPriceFormatEmbroidery } from "../../../utils/tools";
import Customize from "./customize/Customize";

const Embroidery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page");
  return (
    <div className="flex flex-col">
      {page === "info" && <Info />}
      {page === "upload" && (
        <UploadArtwork patchPriceFormat={patchPriceFormatEmbroidery} />
      )}
      {page === "customize" && <Customize />}
    </div>
  );
};

export default Embroidery;
