import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import Cookies from "js-cookie";
import { states } from "../PaymentPage/PaymentPage";
import { useForm } from "react-hook-form";
import { Spin } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm2 from "../CheckoutPage/CheckoutPage2";
import stripelogo from "../images/stripe.svg";

const Checkout = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const userId = Cookies.get("userId");
  const authUserId = Cookies.get("authUserId");
  const token = Cookies.get("token");

  const [cartData, setCartData] = useState([]);
  const [processing, setProcessing] = useState(false);
  const nav = useNavigate();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [state, setState] = useState("Alabama(AL)");
  const [listofOrderId, setListofOrderId] = useState([]);
  const [shipid, setShipid] = useState(
    sessionStorage.getItem("shipid") ? sessionStorage.getItem("shipid") : ""
  );
  const [billid, setBillid] = useState(
    sessionStorage.getItem("billid") ? sessionStorage.getItem("billid") : ""
  );
  const [step, setStep] = useState(1);
  const [isBillingAddressIsSame, setIsBillingAddressIsSame] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState("standard");
  const [makeSample, setMakeSample] = useState(true);

  const appearance = {
    theme: "flat",

    variables: {
      spacingUnit: "5px",
      spacingGridRow: "40px",
      spacingGridColumn: "35px",
      borderRadius: "8px",
      colorDanger: "#df1b41",
    },
    //   colorPrimary: '#0570de',
    //   colorBackground: '#ffffff',
    //   colorText: '#30313d',
    //   fontFamily: 'Ideal Sans, system-ui, sans-serif',
    //   spacingUnit: '2px',
    //   spacingTab:'4px',

    //   // See all possible variables below
    // }
  };
  useEffect(() => {
    fetch(`${API_URL}publicKey`).then(async (r) => {
      const response = await r.json();
      // console.log(response);
      setStripePromise(loadStripe(response.key));
    });
  }, []);

  const getCartData = async () => {
    const token = Cookies.get("token");
    if (token) {
      try {
        setProcessing(true);
        const { data } = await axios.get(`${API_URL}order/getCartOrders`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCartData([...data.cart]);
        setProcessing(false);
        // console.log(data);
        const list = data.cart.map((item) => item._id);
        setListofOrderId([...list]);
        const subto = data.cart.reduce(function (acc, obj) {
          return acc + obj?.totalPrice;
        }, 0);
        // console.log(subto)
        setSubtotal(subto);
      } catch (err) {
        // console.log(err);
        setProcessing(false);
      }
    } else {
      const userId = Cookies.get("userId");
      if (userId) {
        try {
          const { data } = await axios.post(`${API_URL}order/getGuestCart`, {
            id: userId,
          });
          setCartData([...data.cart]);
          // console.log(data);
          const list = data.cart.map((item) => item._id);
          setListofOrderId([...list]);
          const subto = data.cart.reduce(function (acc, obj) {
            return acc + obj?.totalPrice;
          }, 0);
          // console.log(subto)
          setSubtotal(subto);
        } catch (err) {
          // console.log(err);
        }
      }
    }
  };

  useEffect(() => {
    getCartData();
  }, []);

  // console.log(errors)

  const onSubmit = async (data) => {
    // e.preventDefault();
    // console.log(data);
    const {
      firstname,
      lastname,
      email,
      address,
      contact,
      zip,
      city,
      apartment,
      company,
    } = data;

    try {
      setProcessing(true);
      const response = await axios.post(API_URL + "address/add", {
        country: "United States",
        firstname,
        lastname,
        email,
        address,
        contact,
        zip,
        city,
        state,
        ...(apartment && { apartment }),
        ...(company && { company }),
      });
      // console.log(response?.data)
      setShipid(response?.data?.id);
      setStep(2);
      setProcessing(false);
      // console.log(Math.round((+subtotal + +tax + Number.EPSILON) * 100) / 100);
    } catch (err) {
      // console.log(err)
      setProcessing(false);
    }
  };

  const onSubmit2 = async (data) => {
    // console.log(data);
    try {
      setProcessing(true);
      const response = await axios.post(API_URL + "address/add", {
        country: "United States",
        firstname: data.firstname2,
        lastname: data.lastname2,
        email: data.email2,
        address: data.address2,
        contact: data.contact2,
        zip: data.zip2,
        city: data.city2,
        state: data.state2,
        ...(data.apartment2 && { apartment: data.apartment2 }),
        ...(data.company2 && { company: data.company2 }),
      });
      setBillid(response?.data?.id);
      setProcessing(false);
      setStep(3);
      // console.log(response?.data?.id)
    } catch (err) {
      // console.log(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log("listofOrderId", listofOrderId)

  useEffect(() => {
    if (subtotal) {
      (async () => {
        const token = Cookies.get("token");
        try {
          if (token) {
            const { data } = await axios.get(`${API_URL}auth/myself`, {
              headers: { Authorization: `Bearer ${token}` },
            });
            if (data.userDetails.shipId) {
              const addressResponse = await axios.get(
                `${API_URL}address/fetch`,
                {
                  params: { _id: data.userDetails.shipId },
                }
              );
              if (addressResponse.data.addressExist._id) {
                if (addressResponse.data.addressExist.state === "Texas(TX)") {
                  // console.log("Add Tax");
                  setTax(
                    (
                      (+subtotal +
                        +(deliveryTime === "expedite"
                          ? +(subtotal.toFixed(2) * 0.2)
                          : +0)) *
                      0.0825
                    ).toFixed(2)
                  );
                } else {
                  // console.log("remove tax")
                }
                setState(addressResponse.data.addressExist.state);
                const {
                  address,
                  city,
                  company,
                  contact,
                  firstname,
                  lastname,
                  email,
                  zip,
                  apartment,
                } = addressResponse.data.addressExist;
                setValue("firstname", firstname);
                setValue("lastname", lastname);
                setValue("email", email);
                setValue("address", address);
                setValue("city", city);
                setValue("company", company);
                setValue("contact", contact);
                setValue("zip", zip);
                setValue("company", company);
                setValue("apartment", apartment);
              }
            }
          }
        } catch (err) {
          // console.log(err);
        }
      })();
    }
  }, [subtotal]);

  // console.log("tax====>", tax)
  // console.log("isBillingAddressIsSame", isBillingAddressIsSame)

  useEffect(() => {
    // console.log("=>", getValues())
    if (isBillingAddressIsSame) {
      setValue("firstname2", getValues().firstname);
      setValue("lastname2", getValues().lastname);
      setValue("email2", getValues().email);
      setValue("address2", getValues().address);
      setValue("contact2", getValues().contact);
      setValue("zip2", getValues().zip);
      setValue("city2", getValues().city);
      setValue("apartment2", getValues().apartment);
      setValue("company2", getValues().company);
      setValue("state2", state);
    } else {
      setValue("firstname2", "");
      setValue("lastname2", "");
      setValue("email2", "");
      setValue("address2", "");
      setValue("contact2", "");
      setValue("zip2", "");
      setValue("city2", "");
      setValue("apartment2", "");
      setValue("company2", "");
      setValue("state2", states[0]);
    }
  }, [isBillingAddressIsSame]);

  useEffect(() => {
    sessionStorage.setItem("shipid", shipid);
  }, [shipid]);

  useEffect(() => {
    sessionStorage.setItem("shipid", billid);
  }, [billid]);

  const goTofinalStepHandler = async () => {
    setProcessing(true);
    const amt = Number(
      +subtotal.toFixed(2) +
        (deliveryTime === "expedite"
          ? +(subtotal.toFixed(2) * 0.2).toFixed(2)
          : +0) +
        +tax
    ).toFixed(2);

    // console.log("amt====>", amt);

    await axios
      .post(API_URL + "payment", {
        amount: Math.round(amt * 100) / 100,
      })
      .then((res) => {
        if (res) {
          setClientSecret(res.data.client_secret);

          setStep(4);
          setProcessing(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setProcessing(false);
      });
  };
  useEffect(() => {
    setTax(
      (
        (+subtotal +
          +(deliveryTime === "expedite" ? +(subtotal.toFixed(2) * 0.2) : +0)) *
        0.0825
      ).toFixed(2)
    );
  }, [deliveryTime, subtotal]);
  return (
    <>
      <Navbar />
      <div className="">
        <div className="w-[90%] mx-auto min-h-screen ">
          <p className="pt-5 text-2xl font-semibold ">Checkout</p>

          <div className="flex flex-col gap-x-10 sm:flex-row gap-y-10 ">
            {/* tab bar for Shipping, billing Review and payment  */}
            <div className="w-full sm:w-[60%] bg-white">
              <div className="self-start  w-full flex items-center gap-x-8 border-b-[1px] border-b-[#AEB0B7]">
                <p
                  onClick={() => step > 1 && setStep(1)}
                  className={`${
                    step > 1 ? "cursor-pointer" : ""
                  } py-2   flex items-center gap-x-2 font-semibold text-xl  ${
                    step === 1
                      ? "border-b-green-600 border-b-[2px] text-green-600"
                      : "text-blue-600"
                  } `}
                >
                  <span
                    className={` ${
                      step === 1 ? "bg-green-600" : "bg-blue-600"
                    }  rounded-full text-white w-7 h-7 flex items-center justify-center font-semibold text-[15px]`}
                  >
                    1
                  </span>
                  Shipping
                </p>

                <p
                  onClick={() => step > 2 && setStep(2)}
                  className={`${step > 2 ? "cursor-pointer" : ""}  py-2    ${
                    step >= 2
                      ? step === 2
                        ? "text-green-600"
                        : "text-blue-600 "
                      : "text-[#AEB0B7]"
                  }   flex ${
                    step === 2 ? "border-b-green-600 border-b-[2px] " : ""
                  } items-center gap-x-2 font-semibold text-xl `}
                >
                  <span
                    className={`${
                      step >= 2
                        ? step === 2
                          ? "bg-green-600"
                          : "bg-blue-600"
                        : "bg-[#AEB0B7]"
                    } rounded-full  text-white w-7 h-7 flex items-center justify-center font-semibold text-[15px]`}
                  >
                    2
                  </span>
                  Billing
                </p>

                <p
                  onClick={() => step > 3 && setStep(3)}
                  className={`${step > 3 ? "cursor-pointer" : ""} py-2 ${
                    step >= 3
                      ? step === 3
                        ? "text-green-600"
                        : "text-blue-600 "
                      : "text-[#AEB0B7]"
                  } flex ${
                    step === 3 ? "border-b-green-600 border-b-[2px]" : ""
                  } items-center gap-x-2 font-semibold text-xl `}
                >
                  <span
                    className={`${
                      step >= 3
                        ? step === 3
                          ? "bg-green-600"
                          : "bg-blue-600"
                        : "bg-[#AEB0B7]"
                    } rounded-full text-white w-7 h-7 flex items-center justify-center font-semibold text-[15px]`}
                  >
                    3
                  </span>
                  Review
                </p>

                <p
                  className={` py-2 ${
                    step === 4 ? "text-green-600 " : "text-[#AEB0B7]"
                  } flex ${
                    step >= 4 ? "border-b-green-600 border-b-[2px]" : ""
                  } items-center gap-x-2 font-semibold text-xl `}
                >
                  <span
                    className={`${
                      step === 4 ? "bg-green-600" : "bg-[#AEB0B7]"
                    } rounded-full text-white w-7 h-7 flex items-center justify-center font-semibold text-[15px]`}
                  >
                    4
                  </span>
                  Payment
                </p>
              </div>
              {step === 1 && (
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="self-start w-full p-4 bg-white shadow-md rounded-xl"
                >
                  {
                    //    (!shipid && clientSecret.length<=0 )&&

                    <div className="">
                      <div className="flex mt-4 gap-x-5">
                        <div className="w-1/2">
                          <div
                            className={`border  ${
                              errors?.firstname?.message
                                ? "border-red-600"
                                : "border-black"
                            } relative  w-full rounded-[4px]`}
                          >
                            <p
                              className={`text-xs ${
                                errors?.firstname?.message
                                  ? "text-red-600"
                                  : "text-black"
                              } bg-white -top-[20%] left-2 leading-[100%] px-2  absolute `}
                            >
                              First Name*
                            </p>
                            <input
                              className="w-full outline-none p-[6px] rounded-[4px]"
                              alt=""
                              name="firstname"
                              type="text"
                              {...register("firstname", {
                                required: "First Name is required",
                                minLength: {
                                  value: 2,
                                  message: "Atleast 2 characters",
                                },
                                maxLength: {
                                  value: 50,
                                  message: "Max 50 characters",
                                },
                              })}
                            />
                          </div>
                          <p className="text-xs text-red-600">
                            {errors?.firstname?.message}
                          </p>
                        </div>
                        <div className="w-1/2">
                          <div
                            className={`border  ${
                              errors?.lastname?.message
                                ? "border-red-600"
                                : "border-black"
                            } relative  w-full rounded-[4px]`}
                          >
                            <p
                              className={`text-xs bg-white ${
                                errors?.lastname?.message
                                  ? "text-red-600"
                                  : "text-black"
                              } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                            >
                              Last Name*
                            </p>
                            <input
                              className="w-full outline-none p-[6px] rounded-[4px]"
                              alt=""
                              {...register("lastname", {
                                required: "Last Name is required",
                                minLength: {
                                  value: 2,
                                  message: "Atleast 2 characters",
                                },
                                maxLength: {
                                  value: 50,
                                  message: "Max 50 characters",
                                },
                              })}
                            />
                          </div>
                          <p className="text-xs text-red-600">
                            {errors?.lastname?.message}
                          </p>
                        </div>
                      </div>
                      <div
                        className={` mt-4 border ${
                          errors?.email?.message
                            ? "border-red-600"
                            : "border-black"
                        } relative   rounded-[4px]`}
                      >
                        <p
                          className={`text-xs bg-white ${
                            errors?.email?.message
                              ? "text-red-600"
                              : "text-black"
                          } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                        >
                          Email Address*
                        </p>
                        <input
                          className="w-full outline-none p-[6px] rounded-[4px]"
                          type="email"
                          alt=""
                          defaultValue={localStorage.getItem('email')}
                          {...register("email", {
                            required: "email is required",
                            minLength: {
                              value: 5,
                              message: "Atleast 5 characters",
                            },
                            maxLength: {
                              value: 60,
                              message: "Max 60 characters",
                            },
                          })}
                        />
                      </div>
                      <p className="text-xs text-red-600">
                        {errors?.email?.message}
                      </p>
                      <div
                        className={` mt-4 border ${
                          errors?.address?.message
                            ? "border-red-600"
                            : "border-black"
                        } relative   rounded-[4px]`}
                      >
                        <p
                          className={`text-xs bg-white ${
                            errors?.address?.message
                              ? "text-red-600"
                              : "text-black"
                          } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                        >
                          Address*
                        </p>
                        <input
                          className="w-full outline-none p-[6px] rounded-[4px]"
                          alt=""
                          {...register("address", {
                            required: "address is required",
                            minLength: {
                              value: 5,
                              message: "Atleast 5 characters",
                            },
                            maxLength: {
                              value: 60,
                              message: "Max 60 characters",
                            },
                          })}
                        />
                      </div>
                      <p className="text-xs text-red-600">
                        {errors?.address?.message}
                      </p>

                      <div className="flex mt-4 gap-x-5">
                        <div className="w-1/2">
                          <div
                            className={`border ${
                              errors?.zip?.message
                                ? "border-red-600"
                                : "border-black"
                            } relative  w-full rounded-[4px]`}
                          >
                            <p
                              className={`text-xs bg-white ${
                                errors?.zip?.message
                                  ? "text-red-600"
                                  : "text-black"
                              } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                            >
                              Zip Code*
                            </p>
                            <input
                              className="w-full outline-none p-[6px] rounded-[4px]"
                              alt=""
                              {...register("zip", {
                                required: "Zip Code is required",
                                minLength: {
                                  value: 5,
                                  message: "Atleast 5 characters",
                                },
                                maxLength: {
                                  value: 10,
                                  message: "Max 10 characters",
                                },
                              })}
                            />
                          </div>
                          <p className="text-xs text-red-600">
                            {errors?.zip?.message}
                          </p>
                        </div>
                        <div className="w-1/2">
                          <div
                            className={`border ${
                              errors?.apartment?.message
                                ? "border-red-600"
                                : "border-black"
                            } relative  w-full rounded-[4px]`}
                          >
                            <p
                              className={`text-xs bg-white ${
                                errors?.apartment?.message
                                  ? "text-red-600"
                                  : "text-black"
                              } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                            >
                              Apt/Suit
                            </p>
                            <input
                              className="w-full outline-none p-[6px] rounded-[4px]"
                              alt=""
                              {...register("apartment", {
                                minLength: {
                                  value: 2,
                                  message: "Atleast 2 characters",
                                },
                                maxLength: {
                                  value: 50,
                                  message: "Max 50 characters",
                                },
                              })}
                            />
                          </div>
                          <p className="text-xs text-red-600">
                            {errors?.apartment?.message}
                          </p>
                        </div>
                      </div>

                      <div className="flex mt-4 gap-x-5">
                        <div className="w-1/2">
                          <div
                            className={`border ${
                              errors?.city?.message
                                ? "border-red-600"
                                : "border-black"
                            } relative  w-full rounded-[4px]`}
                          >
                            <p
                              className={`text-xs ${
                                errors?.city?.message
                                  ? "text-red-600"
                                  : "text-black"
                              } bg-white -top-[20%] left-2 leading-[100%] px-2  absolute `}
                            >
                              City*
                            </p>
                            <input
                              className="w-full outline-none p-[6px] rounded-[4px]"
                              alt=""
                              {...register("city", {
                                required: "City is required",
                                minLength: {
                                  value: 2,
                                  message: "Atleast 2 characters",
                                },
                                maxLength: {
                                  value: 50,
                                  message: "Max 50 characters",
                                },
                              })}
                            />
                          </div>
                          <p className="text-xs text-red-600">
                            {errors?.city?.message}
                          </p>
                        </div>

                        <div className="w-1/2">
                          <div className="border border-black relative  w-full rounded-[4px]">
                            <p className="text-xs bg-white -top-[20%] left-2 leading-[100%] px-2  absolute ">
                              State*
                            </p>
                            <select
                              onChange={(e) => {
                                if (e.target.value === "Texas(TX)") {
                                  setTax((subtotal * 0.0825).toFixed(2));
                                } else {
                                  setTax(0);
                                }
                                setState(e.target.value);
                              }}
                              value={state}
                              className="w-full outline-none p-[6px] rounded-[4px]"
                              alt=""
                            >
                              {states.map((item) => (
                                <option
                                  value={item}
                                  key={item}
                                  disabled={shipid && clientSecret.length > 0}
                                >
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="flex mt-4 gap-x-5">
                        <div className="w-1/2">
                          <div
                            className={`border ${
                              errors?.contact?.message
                                ? "border-red-600"
                                : "border-black"
                            } relative  w-full rounded-[4px]`}
                          >
                            <p
                              className={`text-xs ${
                                errors?.contact?.message
                                  ? "text-red-600"
                                  : "text-black"
                              } bg-white -top-[20%] left-2 leading-[100%] px-2  absolute `}
                            >
                              Mobile Number*
                            </p>
                            <input
                              className="w-full outline-none p-[6px] rounded-[4px]"
                              alt=""
                              {...register("contact", {
                                required: "Contact is required",
                                minLength: {
                                  value: 10,
                                  message: "Atleast 10 characters",
                                },
                                maxLength: {
                                  value: 11,
                                  message: "Max 11 characters",
                                },
                              })}
                            />
                          </div>
                          <p className="text-xs text-red-600">
                            {errors?.contact?.message}
                          </p>
                        </div>
                        <div className="w-1/2">
                          <div className=" border border-black relative  w-full rounded-[4px]">
                            <p className="text-xs bg-white -top-[20%] left-2 leading-[100%] px-2  absolute ">
                              Company
                            </p>
                            <input
                              className="w-full outline-none p-[6px] rounded-[4px]"
                              alt=""
                              {...register("company", {})}
                            />
                          </div>
                        </div>
                      </div>

                      <p className="mt-5 text-justify">
                        For custom chenille patches, a sample patch will be
                        manufactured for each patch and image(s) of the
                        patch(es) will be emailed for your approval within 5
                        business days. The production time of the patches will
                        start from the time the sample patch (digital mockup
                        and/or physical sample) is approved.
                      </p>

                      <h2 className="mt-5 text-xl font-bold">
                        Shipping Method:
                      </h2>

                      <p className="text-justify">
                        Shipping timeline is based on the time to manufacture
                        and ship your products upon approval of digital mockup
                        and/or physical sample.
                      </p>
                      <div className="mt-5 ml-4">
                        <input
                          value={"standard"}
                          type="radio"
                          className=""
                          name="shippingmethod"
                          onChange={(e) => setDeliveryTime(e.target.value)}
                          checked={deliveryTime == "standard"}
                        />
                        <label className="ml-4">
                          Standard shipping (25-30 days)
                        </label>
                      </div>
                      <div className="mt-4 ml-4">
                        <input
                          value={"expedite"}
                          type="radio"
                          className=""
                          name="shippingmethod"
                          onChange={(e) => setDeliveryTime(e.target.value)}
                          checked={deliveryTime === "expedite"}
                        />
                        <label className="ml-4">
                          Expedite shipping (15-20 days)
                        </label>
                      </div>

                      {/* <div className='flex items-center mt-4 gap-x-2'>

                                                <input
                                                    value={isBillingAddressIsSame}
                                                    type='checkbox'
                                                    className='h-5 cursor-pointer accent-blue-600'
                                                    onChange={(e) => setIsBillingAddressIsSame(e.target.checked)}
                                                />

                                                {isBillingAddressIsSame}

                                                <span className='text-black'> Use This Address for Billing</span>
                                            </div> */}
                      <button
                        disabled={processing}
                        className="mt-4 text-white font-bold px-8 border  py-3   bg-green-600 hover:bg-green-700 transition-all duration-300 ease-out rounded-[24px] mx-auto block"
                      >
                        {/* {(shipid && clientSecret.length > 0) ? "Saved" : "Confirm"} */}
                        Continue To Billing
                      </button>
                    </div>
                  }
                </form>
              )}

              {step === 2 && (
                <div className="">
                  <form
                    onSubmit={handleSubmit(onSubmit2)}
                    className="self-start w-full p-4 bg-white shadow-md rounded-xl"
                  >
                    <div className="flex items-center gap-x-4">
                      <input
                        value={isBillingAddressIsSame}
                        type="checkbox"
                        className="h-5 cursor-pointer "
                        onChange={(e) =>
                          setIsBillingAddressIsSame(e.target.checked)
                        }
                      />
                      <span className="">Same As Shipping Address</span>
                    </div>

                    {
                      //    (!shipid && clientSecret.length<=0 )&&

                      <div className="">
                        <div className="flex mt-4 gap-x-5">
                          <div className="w-1/2">
                            <div
                              className={`border  ${
                                errors?.firstname2?.message
                                  ? "border-red-600"
                                  : "border-black"
                              } relative  w-full rounded-[4px]`}
                            >
                              <p
                                className={`text-xs ${
                                  errors?.firstname2?.message
                                    ? "text-red-600"
                                    : "text-black"
                                } bg-white -top-[20%] left-2 leading-[100%] px-2  absolute `}
                              >
                                First Name*
                              </p>
                              <input
                                // readOnly={shipid && clientSecret.length > 0}
                                className="w-full outline-none p-[6px] rounded-[4px]"
                                alt=""
                                name="firstname2"
                                type="text"
                                {...register("firstname2", {
                                  required: "First Name is required",
                                  minLength: {
                                    value: 2,
                                    message: "Atleast 2 characters",
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: "Max 50 characters",
                                  },
                                })}
                              />
                            </div>
                            <p className="text-xs text-red-600">
                              {errors?.firstname2?.message}
                            </p>
                          </div>
                          <div className="w-1/2">
                            <div
                              className={`border  ${
                                errors?.lastname2?.message
                                  ? "border-red-600"
                                  : "border-black"
                              } relative  w-full rounded-[4px]`}
                            >
                              <p
                                className={`text-xs bg-white ${
                                  errors?.lastname2?.message
                                    ? "text-red-600"
                                    : "text-black"
                                } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                              >
                                Last Name*
                              </p>
                              <input
                                // readOnly={shipid && clientSecret.length > 0}
                                className="w-full outline-none p-[6px] rounded-[4px]"
                                alt=""
                                {...register("lastname2", {
                                  required: "Last Name is required",
                                  minLength: {
                                    value: 2,
                                    message: "Atleast 2 characters",
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: "Max 50 characters",
                                  },
                                })}
                              />
                            </div>
                            <p className="text-xs text-red-600">
                              {errors?.lastname2?.message}
                            </p>
                          </div>
                        </div>

                        <div
                        className={` mt-4 border ${
                          errors?.email2?.message
                            ? "border-red-600"
                            : "border-black"
                        } relative   rounded-[4px]`}
                      >
                        <p
                          className={`text-xs bg-white ${
                            errors?.email2?.message
                              ? "text-red-600"
                              : "text-black"
                          } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                        >
                          Email Address*
                        </p>
                        <input
                          className="w-full outline-none p-[6px] rounded-[4px]"
                          type="email"
                          alt=""
                          {...register("email2", {
                            required: "email is required",
                            minLength: {
                              value: 5,
                              message: "Atleast 5 characters",
                            },
                            maxLength: {
                              value: 60,
                              message: "Max 60 characters",
                            },
                          })}
                        />
                      </div>
                      <p className="text-xs text-red-600">
                        {errors?.email2?.message}
                      </p>

                        <div
                          className={` mt-4 border ${
                            errors?.address2?.message
                              ? "border-red-600"
                              : "border-black"
                          } relative   rounded-[4px]`}
                        >
                          <p
                            className={`text-xs bg-white ${
                              errors?.address2?.message
                                ? "text-red-600"
                                : "text-black"
                            } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                          >
                            Address*
                          </p>
                          <input
                            className="w-full outline-none p-[6px] rounded-[4px]"
                            alt=""
                            // readOnly={shipid && clientSecret.length > 0}
                            {...register("address2", {
                              required: "address is required",
                              minLength: {
                                value: 5,
                                message: "Atleast 5 characters",
                              },
                              maxLength: {
                                value: 60,
                                message: "Max 60 characters",
                              },
                            })}
                          />
                        </div>
                        <p className="text-xs text-red-600">
                          {errors?.address2?.message}
                        </p>
                        <div className="flex mt-4 gap-x-5">
                          <div className="w-1/2">
                            <div
                              className={`border ${
                                errors?.zip2?.message
                                  ? "border-red-600"
                                  : "border-black"
                              } relative  w-full rounded-[4px]`}
                            >
                              <p
                                className={`text-xs bg-white ${
                                  errors?.zip2?.message
                                    ? "text-red-600"
                                    : "text-black"
                                } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                              >
                                Zip Code*
                              </p>
                              <input
                                // readOnly={shipid && clientSecret.length > 0}
                                className="w-full outline-none p-[6px] rounded-[4px]"
                                alt=""
                                {...register("zip2", {
                                  required: "Zip Code is required",
                                  minLength: {
                                    value: 5,
                                    message: "Atleast 5 characters",
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: "Max 10 characters",
                                  },
                                })}
                              />
                            </div>
                            <p className="text-xs text-red-600">
                              {errors?.zip2?.message}
                            </p>
                          </div>
                          <div className="w-1/2">
                            <div
                              className={`border ${
                                errors?.apartment2?.message
                                  ? "border-red-600"
                                  : "border-black"
                              } relative  w-full rounded-[4px]`}
                            >
                              <p
                                className={`text-xs bg-white ${
                                  errors?.apartment2?.message
                                    ? "text-red-600"
                                    : "text-black"
                                } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                              >
                                Apt/Suit
                              </p>
                              <input
                                // readOnly={shipid && clientSecret.length > 0}
                                className="w-full outline-none p-[6px] rounded-[4px]"
                                alt=""
                                {...register("apartment2", {
                                  minLength: {
                                    value: 2,
                                    message: "Atleast 2 characters",
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: "Max 50 characters",
                                  },
                                })}
                              />
                            </div>
                            <p className="text-xs text-red-600">
                              {errors?.apartment2?.message}
                            </p>
                          </div>
                        </div>

                        <div className="flex mt-4 gap-x-5">
                          <div className="w-1/2">
                            <div
                              className={`border ${
                                errors?.city2?.message
                                  ? "border-red-600"
                                  : "border-black"
                              } relative  w-full rounded-[4px]`}
                            >
                              <p
                                className={`text-xs ${
                                  errors?.city2?.message
                                    ? "text-red-600"
                                    : "text-black"
                                } bg-white -top-[20%] left-2 leading-[100%] px-2  absolute `}
                              >
                                City*
                              </p>
                              <input
                                // readOnly={shipid && clientSecret.length > 0}
                                className="w-full outline-none p-[6px] rounded-[4px]"
                                alt=""
                                {...register("city2", {
                                  required: "City is required",
                                  minLength: {
                                    value: 2,
                                    message: "Atleast 2 characters",
                                  },
                                  maxLength: {
                                    value: 50,
                                    message: "Max 50 characters",
                                  },
                                })}
                              />
                            </div>
                            <p className="text-xs text-red-600">
                              {errors?.city2?.message}
                            </p>
                          </div>

                          <div className="w-1/2">
                            <div
                              className={`border ${
                                errors?.state2?.message
                                  ? "border-red-600"
                                  : "border-black"
                              } relative  w-full rounded-[4px]`}
                            >
                              <p
                                className={`text-xs ${
                                  errors?.state2?.message
                                    ? "text-red-600"
                                    : "text-black"
                                } bg-white -top-[20%] left-2 leading-[100%] px-2  absolute `}
                              >
                                State*
                              </p>
                              <select
                                defaultValue={states[0]}
                                {...register("state2", {
                                  required: "State is required",
                                })}
                                className="w-full outline-none p-[6px] rounded-[4px]"
                                alt=""
                              >
                                {states.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <p className="text-xs text-red-600">
                              {errors?.state2?.message}
                            </p>
                          </div>
                        </div>

                        <div className="flex mt-4 gap-x-5">
                          <div className="w-1/2">
                            <div
                              className={`border ${
                                errors?.contact2?.message
                                  ? "border-red-600"
                                  : "border-black"
                              } relative  w-full rounded-[4px]`}
                            >
                              <p
                                className={`text-xs ${
                                  errors?.contact2?.message
                                    ? "text-red-600"
                                    : "text-black"
                                } bg-white -top-[20%] left-2 leading-[100%] px-2  absolute `}
                              >
                                Mobile Number*
                              </p>
                              <input
                                // readOnly={shipid && clientSecret.length > 0}
                                className="w-full outline-none p-[6px] rounded-[4px]"
                                alt=""
                                {...register("contact2", {
                                  required: "Contact is required",
                                  minLength: {
                                    value: 10,
                                    message: "Atleast 10 characters",
                                  },
                                  maxLength: {
                                    value: 11,
                                    message: "Max 11 characters",
                                  },
                                })}
                              />
                            </div>
                            <p className="text-xs text-red-600">
                              {errors?.contact2?.message}
                            </p>
                          </div>
                          <div className="w-1/2">
                            <div className=" border border-black relative  w-full rounded-[4px]">
                              <p className="text-xs bg-white -top-[20%] left-2 leading-[100%] px-2  absolute ">
                                Company
                              </p>
                              <input
                                // readOnly={shipid && clientSecret.length > 0}
                                className="w-full outline-none p-[6px] rounded-[4px]"
                                alt=""
                                {...register("company2", {})}
                              />
                            </div>
                          </div>
                        </div>

                        <button
                          disabled={processing}
                          className="mt-4 text-white font-bold px-8 border  py-3  bg-green-600 hover:bg-green-700 transition-all duration-300 ease-out rounded-[24px] mx-auto block"
                        >
                          {/* {(shipid && clientSecret.length > 0) ? "Saved" : "Confirm"} */}
                          Continue to review order
                        </button>
                      </div>
                    }
                  </form>
                </div>
              )}

              {step == 3 && (
                <div className="">
                  {cartData.length > 0 && (
                    <>
                      <div className="flex pb-10 mt-4 border-b gap-x-8 border-b-gray-200">
                        <div className="">
                          <h2 className="font-bold">Shipping Address :</h2>
                          <div className="">
                            <p className="">
                              {getValues()?.firstname} {getValues()?.lastname}
                            </p>
                            <p className="">{getValues()?.company}</p>
                            <p className="">
                              {getValues()?.address} {getValues()?.apartment}
                            </p>
                            <p className="">
                              {getValues()?.city} {getValues()?.zip}
                            </p>
                            <p className="">{"United States"}</p>
                            <p className="">{getValues()?.contact}</p>
                          </div>
                        </div>

                        <div className="">
                          <h2 className="font-bold">Billing Address :</h2>
                          <div className="">
                            <p className="">
                              {getValues()?.firstname2} {getValues()?.lastname2}
                            </p>
                            <p className="">{getValues()?.company2}</p>
                            <p className="">
                              {getValues()?.address2} {getValues()?.apartment2}
                            </p>
                            <p className="">
                              {getValues()?.city} {getValues()?.zip2}
                            </p>
                            <p className="">{"United States"}</p>
                            <p className="">{getValues()?.contact2}</p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        <h2 className="font-bold">
                          {cartData.length > 1 ? "Items" : "Item"}
                        </h2>
                        <div className="mt-5">
                          {cartData.map((item) => (
                            <div
                              key={item._id}
                              className="flex justify-between w-full mb-5"
                            >
                              <div className="flex items-center gap-x-4 w-full">
                                <img
                                  src={item?.imageUrls[0]}
                                  alt=""
                                  className="w-[80px] h-[80px] object-contain"
                                />
                                <div className=" text-[14px] leading-[120%]  ">
                                  <p className="font-bold text-start">
                                    {item.category === "Patches"
                                      ? "Chenille"
                                      : item.category}{" "}
                                    patches
                                  </p>
                                  <p className=" text-start">
                                    Height (in inches) : {item.long} , Width (in inches) : {item.wide}
                                  </p>
                                  {item.category === "Patches" ? (
                                    <>
                                      <p className="text-start">
                                        Length: {item.long}, Width: {item.wide}
                                      </p>
                                      <p className="text-start">
                                        {item.embroidary
                                          ? "With Embroidery"
                                          : "No Embroidery"}
                                        ,{" "}
                                        {item.backing
                                          ? "Iron on Backing"
                                          : "No Backing"}
                                        ,{" "}
                                        {item.borderEdge === 1
                                          ? "Single Felt"
                                          : item.borderEdge === 2
                                          ? "Double Felt"
                                          : "Triple Felt"}
                                      </p>

                                      <p className="text-start">
                                        {!item?.color
                                          ? "1-3 Color(s)"
                                          : "4-6 Colors"}
                                      </p>
                                    </>
                                  ) : (
                                    <p>{item.other.join(", ")}</p>
                                  )}
                                  <p>Quantity: {item?.quantity}</p>
                                </div>
                              </div>

                              <div className="">
                                ${Number(item?.totalPrice).toFixed(2)}
                              </div>

                              <div className=""></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                  <button
                    onClick={goTofinalStepHandler}
                    className="mt-4 text-white font-bold  border  py-3  bg-green-600 hover:bg-green-700 transition-all duration-300 ease-out rounded-[24px] mb-5 px-8 mx-auto block"
                  >
                    {" "}
                    Proceed to Payment
                  </button>

                  <img
                    src={stripelogo}
                    alt="Stripe"
                    className="max-w-[180px] mb-10 mx-auto block"
                  />
                </div>
              )}

              {step === 4 && (
                <div className=" p-4 w-[100%]  bg-white rounded-xl shadow-md ">
                  {/* <p className='font-bold'>2. Payment Information</p> */}
                  {/* {console.log("amount===>", Number(+subtotal.toFixed(2)
                                        +
                                        (deliveryTime === "expedite" ?
                                            +(subtotal.toFixed(2) * 0.2).toFixed(2) : +0) +
                                        +tax).toFixed(2))} */}
                  {clientSecret.length > 0 && shipid && (
                    <Elements
                      stripe={stripePromise}
                      options={{ clientSecret, appearance }}
                    >
                      <CheckoutForm2
                        amount={Number(
                          +subtotal.toFixed(2) +
                            (deliveryTime === "expedite"
                              ? +(subtotal.toFixed(2) * 0.2).toFixed(2)
                              : +0) +
                            +tax
                        ).toFixed(2)}
                        listofOrderId={listofOrderId}
                        shipid={shipid}
                        billid={billid}
                        deliveryTime={deliveryTime}
                        makeSample={makeSample}
                        tax={tax}
                        shipping={
                          deliveryTime === "expedite"
                            ? +(subtotal.toFixed(2) * 0.2).toFixed(2)
                            : 0
                        }
                      />
                    </Elements>
                  )}
                </div>
              )}
            </div>
            <div className="w-full mb-10 sm:mb-0 sm:w-[40%]  bg-slate-50 p-4 px-8 self-start">
              {/* <div className='w-full md:w-[30%] bg-white p-4 rounded-xl shadow-md self-start'> */}
              <p className="py-2 uppercase font-bold border-b-[0.5px] border-b-gray-400">
                Order Summary
              </p>
              <p className="flex justify-between py-2">
                <span>Subtotal :</span>
                {cartData.length > 0 && <span>${subtotal.toFixed(2)}</span>}
              </p>
              <p className="flex justify-between py-2">
                <span className="capitalize">{`Shipping (${deliveryTime}) :`}</span>
                {cartData.length > 0 && (
                  <span>
                    $
                    {deliveryTime === "expedite"
                      ? (subtotal.toFixed(2) * 0.2).toFixed(2)
                      : "0.00"}
                  </span>
                )}
              </p>
              <p className="flex justify-between pb-4  border-b-[0.5px] border-b-gray-400 py-2">
                <span>{`Taxes ${tax ? "(8.25%)" : ""} :`}</span>
                {cartData.length > 0 && <span>${tax ? tax : "0.00"}</span>}
              </p>
              <p className="flex justify-between py-2 font-bold">
                <span>Order Total :</span>
                {cartData.length > 0 && (
                  <span>
                    $
                    {Number(
                      +subtotal.toFixed(2) +
                        (deliveryTime === "expedite"
                          ? +(subtotal.toFixed(2) * 0.2)
                          : +0) +
                        +tax
                    ).toFixed(2)}
                  </span>
                )}
              </p>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {processing && (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default Checkout;
