import React from 'react'

const WovenBreadCrumb = () => {
  return (
    <div className="flex max-w-xl mx-auto ">
      <div className="flex flex-col items-center justify-center gap-2">
        <div
          className={`flex items-center justify-end p-4 text-white rounded-full px-[26px] bg-brand-planetarium`}
        >
          <p className="text-2xl font-extrabold">1</p>
        </div>
        <p className="text-lg">SELECT</p>
      </div>

      <div className={`w-[100px] h-1 mt-7 bg-brand-planetarium`}></div>

      <div className="flex flex-col items-center justify-center gap-2">
        <div
          className={`flex items-center justify-end p-4 text-white rounded-full px-[26px] bg-brand-success`}
        >
          <p className="text-2xl font-extrabold">2</p>
        </div>
        <p className="text-lg">FINAL</p>
      </div>
    </div>
  )
}

export default WovenBreadCrumb
