import React from "react";

const Instructions = () => {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-sm font-medium  uppercase md:pl-0 lg:text-lg">
        Instructions:
      </h3>
      <ol className="list-decimal px-6">
        <li>
          Select the desired patch size and quantity using the drop down option.
        </li>
        <li>
          Upload the artwork image file (supported file format: png, jpg, jpeg,
          webp, svg).
        </li>
        <li>To edit image:</li>
        <ul class="list-disc px-4">
          <li>
            Use zoom button to fit the uploaded image / artwork area to active
            patch area.
          </li>
          <li>Adjust the size of the patch width or height as required.</li>
          <li>
            Active patch area can be moved across the image by clicking and
            dragging the active area.
          </li>
        </ul>
        <li>
          Approve the image by checking the option below the preview window.
        </li>
      </ol>
    </div>
  );
};

export default Instructions;
