import React, { useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

// const TEST_SITE_KEY = "6LdcpVUeAAAAAHbo-xCNnccQ_vRGxWbESr7r4zg_";

const TEST_SITE_KEY = "6Lf56M8mAAAAAA_PdtVCkk3g50K3tkeAfDHmSbnF";

const ReCaptcha = ({ OnClick, reset }) => {
  const recaptchaRef = useRef();

  const onChange = (value) => {
    // console.log("====>", value, typeof value);
    OnClick(true);
  };

  useEffect(() => {
    recaptchaRef.current.reset();
    OnClick(false);
  }, [reset]);

  return (
    <ReCAPTCHA
      sitekey={TEST_SITE_KEY}
      ref={recaptchaRef}
      onChange={onChange}
      onErrored={() => OnClick(false)}
      onExpired={() => OnClick(false)}
    />
  );
};

export default ReCaptcha;
