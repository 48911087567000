import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import playbutton from "../../components/images/playbutton.png";
import kk from "../../components/images/kk.png";
import Banner from "../../components/images/Banner_1440.png";
import two from "../../components/images/two.png";
import three from "../../components/images/three.png";
import fours from "../../components/images/fours.png";
import fifth from "../../components/images/fifth.png";
import six from "../../components/images/six.png";
import seven from "../../components/images/seven.png";
import yoga from "../../components/images/yoga.png";
import four from "../../components/images/four.png";
import patches from "../../components/images/patches.png";
import hoodie from "../../components/images/hoodie.png";
import dog from "../../components/images/dog.jpg";
import Front from "../../components/images/Front.png";
import Jackets from "../../components/images/Jackets.png";
import Video from "../video/Video";
import { useSelector, useDispatch } from "react-redux";
import activeStep from "../../actions/activeStep";
import landing from "../../actions/landing";
import productSummary from "../../actions/productSummary";
import Popup from "../Popup/PopupForm";
import { MdFormatQuote } from "react-icons/md";
import Footer from "../../components/footer/Footer";
import Slider from "../Slider/Slider";
import gtag from "ga-gtag";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import { data } from "../Slider/dataSlider";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import LeftArrow from "../../components/Slider/icons/Left-arrow.png";
import RightArrow from "../../components/Slider/icons/Right-arrow.png";
import NewSlider from "../Slider/NewSlider";
import { motion } from "framer-motion";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import {
  AiFillCloseCircle,
  AiOutlineClose,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import axios from "axios";
import { API_URL } from "../../config";

export default function ChenilleLandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const active = useSelector((state) => state.activeStep);
  const product = useSelector((state) => state.productSummary);
  const [id, setId] = useState(0);
  const [types, setTypes] = useState("");
  const [show, setShow] = useState(false);
  const [translteX, setTranslateX] = useState(0);

  const [i, setI] = useState(0);

  const [index1, setIndex1] = useState(0);

  const [data, setData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [bannerImage, setBannerImage] = useState('')

  useEffect(() => {
    // <!-- Event snippet for Chenille Patches conversion page -->
    gtag("event", "conversion", {
      send_to: "AW-10852884839/xkG3CNqmhoUYEOfCh7co",
    });
  }, []);

  const handleClick = () => {
    const name = "Chennile Patches";

    const n = name.split(" ");

    const category = n[n.length - 1];
    dispatch(productSummary({ ...product, name, category }));
    dispatch(activeStep(2));
    navigate("/patch/chenille?page=upload");
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(`${API_URL}patch/images/category`, {
          params: { category: "chenile patches" },
        });
        let dummyArr = [];
        // console.log(data);
        for (const property in data) {
          let newArr = [];
          for (let i = 0; i < data[property].length; i++) {
            // console.log(data[property].length);
            newArr.push(data[property][i].url);
          }
          dummyArr.push({
            title: property,
            _id: Math.random(),
            images: newArr,
          });
          // console.log(`${property}: ${data[property]}`);
          setData([...dummyArr]);
        }
        // console.log(dummyArr);
      } catch (err) {
        // console.log(err);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        let dummyArr = [];
        const { data } = await axios.get(`${API_URL}patch/images`, {
          params: { category: "home", title: "Gallery" },
        });
        for (let i = 0; i < data.length; i++) {
          dummyArr.push(data[i].url);
        }
        // setData([...data]);
        // console.log("gllery->", data);
        // console.log("gallery", dummyArr);
        setGalleryData([...dummyArr]);
      } catch (err) {
        // console.log(err);
      }
    };
    getData();
  }, []);


  useEffect(() => {
    const getImage = async () => {
      try {

        const { data } = await axios.get(`${API_URL}patch/images`, {
          params: { category: "home", title: "Patch Banner" },
        });

        setBannerImage(data[0]?.url)


      } catch (err) {

      }
    };
    getImage();
  }, []);

  return (
    <div className="max-w-[1300px] mx-auto">
      <Navbar />
      <div
        className="bg-gray-500 max-w-[1300px] mx-auto relative overflow-hidden bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: "url('" + bannerImage + "')", height: "350px" }}
      >
        <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed">
          <div className="flex justify-center items-center h-full">
            <div className="text-center text-white px-6 md:px-12">
              <h1 className="text-6xl font-bold mt-0 mb-6">Chenille Patches</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center font-sans mt-10 text-lg w-[50%] leading-8 text-center mx-auto ">
        Chenille patches are an American classic and it makes a garment all
        American, unique, and fashionable. Chenille patches elevates the look
        and feel of your brand, logo or names on the garment.
      </div>
      <div className="flex justify-center mt-5 py-2">
        <button
          className="hover:bg-blue-800 transition-all ease-linear duration-300 rounded-md px-12 py-2 font-semibold bg-blue-700 text-white     text-[22px]"
          onClick={handleClick}
        >
          Start Order
        </button>
      </div>

      <div className="flex justify-center font-sans mt-5 text-lg w-[50%] leading-8 text-center mx-auto ">
        We manufacture quality custom chenille and embroidery patches, that goes
        on letterman jackets, varsity jackets, hoodies, sweatshirt, and other
        garments. We can also make the iron on patches, which can be heat
        pressed. We make custom letter patches, activity patches, year patches
        and alphabet patches.
      </div>
      <hr className="border-black h-0 m-auto mt-14 w-4/5"></hr>
      <div className=" flex-col py-12">
        <h1 className=" flex justify-center font-sans font-bold text-2xl py-4 ">
          Why order from us
        </h1>
        <div className="flex justify-center">
          <ul className=" list-disc font-medium italic py-1">
            <li className=" py-1">Custom chenille patches made to order.</li>
            <li className=" py-1">
              High quality premium patches at affordable prices.
            </li>
            <li className=" py-1">Up to 6 yarn colors in one patch.</li>
            <li className=" py-1">
              Exquisitely made chenille and embroidery hybrid patches.
            </li>
            <li className=" py-1">Patches as large as 15 in size.</li>
            <li className=" py-1">Choose from 30 standard colors.</li>
            <li className=" py-1">
              Made with premium quality raw material for a long life.
            </li>
            <li className=" py-1">Direct chenille on garment or patch type.</li>
          </ul>
        </div>
      </div>

      <div className=" flex p-2 flex-wrap gap-y-8 justify-evenly ">
        {data.map((pictures, index) => (
          <div className=" sm:w-[100%]  md:w-[50%]">
            <h1
              className="font-sans font-bold text-2xl text-center cursor-pointer"
              onClick={() => handleClick()}
            >
              {pictures.title}
            </h1>
            <div className=" flex items-center justify-center ">
              <NewSlider
                pictures={pictures}
                i={i}
                setI={setI}
                handleClick={() => {
                  setShow(true);
                  setIndex1(index);
                }}
                data={data}
              />
            </div>
          </div>
        ))}
      </div>

      <hr className="border-black h-0 m-auto mt-14 w-4/5"></hr>
      <div className=" flex-col py-12">
        <h1 className=" flex justify-center font-sans font-bold text-2xl py-4 ">
          Customize Options
        </h1>
        <p className="flex justify-center font-medium italic py-4">
          Felt: Single, Double or Triple.<br></br>
          Backing: Normal, Heat Glue or Velcro.<br></br>
          Finish: Old School or Regular.<br></br>
          Border: Merrowed or Embroidered.<br></br>
          Type: Direct Chenille to Garment or Patch.<br></br>
        </p>
        <div className="flex justify-center py-4">
          <button
            className="hover:bg-blue-800 transition-all ease-linear duration-300 rounded-md px-12 py-2 font-semibold bg-blue-700 text-white     text-[22px] "
            onClick={handleClick}
          >
            Start Order
          </button>
        </div>
      </div>

      <div clas="flex-col">
        <h1 className="flex justify-center font-sans font-bold text-2xl">
          Gallery
        </h1>
        <div className="mb-12">
          {/* <Slider type="GA" length={6} /> */}

          <div
            onClick={() => setShowPopup(true)}
            className="relative  mt-2 w-[90%] mx-auto max-h-[500px] min-h-[300px] md:min-h-[400px]  max-w-[450px] px-5  bs1 overflow-hidden flex items-center justify-center"
          >
            <img
              src={galleryData[galleryIndex]}
              className={` w-full object-contain h-[500px] `}
              alt=""
            />

            <img
              onClick={(e) => {
                e.stopPropagation();
                galleryIndex > 0
                  ? setGalleryIndex(galleryIndex - 1)
                  : setGalleryIndex(galleryData.length - 1);
              }}
              src={LeftArrow}
              className="cursor-pointer absolute left-0 top-[50%] w-[40px] h-[40px] "
            />
            <img
              onClick={(e) => {
                e.stopPropagation();
                galleryIndex < galleryData.length - 1
                  ? setGalleryIndex(galleryIndex + 1)
                  : setGalleryIndex(0);
              }}
              src={RightArrow}
              className="cursor-pointer absolute right-0 top-[50%] w-[40px] h-[40px]"
            />
            <div className="absolute bottom-2 w-full flex justify-center">
              {galleryData?.map((p, s) => (
                <div className="  ml-2 w-[15px]  flex items-center justify-center h-[15px] rounded-full border-black border">
                  <div
                    className={`transition-all duration-300 ease-in-out rounded-full ${s == galleryIndex ? "bg-black" : ""
                      }  w-[7px] h-[7px]`}
                  ></div>
                </div>
              ))}
            </div>
          </div>

          {/* <NewSlider
                pictures={galleryData}
                i={i}
                setI={setI}
                handleClick={() => handleClick()}
                data={data}
              /> */}
        </div>
      </div>
      <Footer />
      {show && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShow(false);
          }}
          className="fixed top-0 left-[0%] right-[0%] bottom-0 flex items-center justify-center bg-black/80 "
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShow(false);
            }}
            className="z-[9999] fixed top-4 right-4  "
          >
            <AiOutlineClose size={40} color="white" />
          </button>
          <div
            className="bg-white "
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <motion.img
              key={`${index1}${i}`}
              src={data[index1].images[i]}
              className="mx-auto w-[80%] sm:w-[90%] md:w-[100%] max-w-[600px] max-h-[80vh] object-contain"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            />
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              i > 0 ? setI(i - 1) : setI(data[index1].images.length - 1);
            }}
            className="z-[999]  fixed sm:w-[10%]  top-0 left-0 md:w-[150px] bottom-0 bg-black/10  flex items-center justify-center"
          >
            <AiOutlineLeft
              onClick={() => {
                i > 0 ? setI(i - 1) : setI(data[index1].images.length - 1);
              }}
              size={50}
              color="white"
              cursor={"pointer"}
            />
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              i < data[index1].images.length - 1 ? setI(i + 1) : setI(0);
            }}
            className="z-[999] fixed top-0 sm:w-[10%] md:w-[150px] right-0  bottom-0 bg-black/10  flex items-center justify-center"
          >
            <AiOutlineRight
              onClick={() => {
                i < data[index1].images.length - 1 ? setI(i + 1) : setI(0);
              }}
              size={50}
              color="white"
              cursor={"pointer"}
            />
          </div>
        </div>
      )}

      {showPopup && (
        <>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowPopup(false);
            }}
            className="fixed top-0 left-[0%] right-[0%] bottom-0 flex items-center justify-center bg-black/80 "
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowPopup(false);
              }}
              className="z-[9999] fixed top-4 right-4  "
            >
              <AiOutlineClose size={40} color="white" />
            </button>
            <div
              className="bg-white "
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <motion.img
                key={`${galleryIndex}`}
                src={galleryData[galleryIndex]}
                className="w-[80%] mx-auto sm:w-[90%] md:w-[100%] max-w-[600px] max-h-[80vh] object-contain"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                galleryIndex > 0
                  ? setGalleryIndex(galleryIndex - 1)
                  : setGalleryIndex(galleryData.length - 1);
              }}
              className="z-[999]  fixed sm:w-[10%]  top-0 left-0 md:w-[150px] bottom-0 bg-black/10  flex items-center justify-center"
            >
              <AiOutlineLeft
                onClick={() => {
                  galleryIndex > 0
                    ? setGalleryIndex(galleryIndex - 1)
                    : setGalleryIndex(galleryIndex.length - 1);
                }}
                size={50}
                color="white"
                cursor={"pointer"}
              />
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                galleryIndex < galleryData.length - 1
                  ? setGalleryIndex(galleryIndex + 1)
                  : setGalleryIndex(0);
              }}
              className="z-[999] fixed top-0 sm:w-[10%] md:w-[150px] right-0  bottom-0 bg-black/10  flex items-center justify-center"
            >
              <AiOutlineRight
                onClick={() => {
                  galleryIndex < galleryData.length - 1
                    ? setGalleryIndex(galleryIndex + 1)
                    : setGalleryIndex(0);
                }}
                size={50}
                color="white"
                cursor={"pointer"}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
