import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { convertText } from "../../../../utils/tools";
const Summary = () => {
  const product = useSelector((state) => state.productSummary);
  const {id} = useParams()
  return (
    <div className="  bg-gray-200  mx-auto h-fit  lg:w-[50%] md:w-8/12 w-5/6   uppercase py-8 rounded-3xl">
      <div className=" font-semibold tracking-[.3rem] text-xl text-center">
        Order summary
      </div>
      <div className="mt-8 grid grid-cols-2 tracking-[.02rem]  sm:tracking-[.1rem] justify-around w-full px-4 sm:px-16"></div>
      <div className=" tracking-[.02rem] sm:tracking-[.1rem] text-center">
        Product - {convertText(id)} PATCHES
      </div>
      <div className="ml-4 sm:ml-16 mt-4 bg-brand-icon  h-[.3rem] w-11/12 sm:w-9/12"></div>

      <div className="grid grid-cols-2 pl-8 mt-4 sm:pl-20">
        <span className="normal-case">
          Custom Size {product.long}in X {product.wide}in
        </span>
        <span className="justify-self-center">
          ${product.customPrice.toFixed(2)}
        </span>
      </div>

      {/* <div className="grid grid-cols-2 pl-8 mt-2 normal-case sm:pl-20">
        <span className="w-max">Iron on Backing</span>
        <span className="justify-self-center">
          ${product.backing.show.toFixed(2)}
        </span>
      </div> */}
      {/* <div className="grid grid-cols-2 pl-8 mt-2 normal-case sm:pl-20">
        <span className="w-max">{product.border.label}</span>
        <span className="justify-self-center">
          ${product.border.show.toFixed(2)}
        </span>
      </div> */}
      {/* <div className="grid grid-cols-2 pl-8 mt-2 normal-case sm:pl-20">
        <span className="w-max">Color</span>
        <span className="justify-self-center">
          ${product.color.show.toFixed(2)}
        </span>
      </div> */}
      <div className="grid grid-cols-2 pl-8 font-bold sm:pl-20">
        <span className="normal-case ">Price per patch</span>
        <span className="justify-self-center">
          ${product.pricePerPatch.toFixed(2)}
        </span>
      </div>
      <div className="grid grid-cols-2 pl-8 mt-2 sm:pl-20">
        <span className="normal-case">
          Price for QTY {product.quantity.size}
        </span>
        <span className="justify-self-center">
          ${(product.quantity.size * product.pricePerPatch).toFixed(2)}
        </span>
      </div>
      {/* <div className="grid grid-cols-2 pl-8 mt-2 normal-case sm:pl-20">
        <span className="w-max">Embroidery</span>
        <span className="justify-self-center">
          ${product.embroidary.price.toFixed(2)}
        </span>
      </div> */}
      <div className="grid grid-cols-2 pl-8 mt-2 font-bold sm:pl-20">
        <span className="normal-case ">Subtotal</span>
        <span className="justify-self-center">
          $
          {(
            product.quantity.size * product.pricePerPatch +
            product.embroidary.price
          ).toFixed(2)}
        </span>
      </div>
      <div className="ml-4 sm:ml-16 mt-2 bg-brand-icon  h-[.3rem] w-11/12 sm:w-9/12"></div>
      <div className="grid grid-cols-2 pl-6 mt-3 text-xl font-bold sm:pl-16">
        <span className="tracking-[.3rem] w-max">Total</span>
        <span className="justify-self-center ">${product?.finalPrice.toFixed(2)}</span>
      </div>
    </div>
  );
};

export default Summary;
