import React from 'react'

export const HomeText = () => {
  return (
    <div className='mt-10 max-w-2xl mx-auto flex justify-center flex-col gap-4 p-4 '>
      <p className='font-semibold text-xl text-center'>Peri Apparel and Fabrics is a premium supplier of patches, jackets and garments in the United States and Canada.</p>
      <p className='text-lg'>We specialize in :</p>
      <p className='text-lg'>
        <strong>Patches:</strong> Chenille, Embroidery, and Vegan Leather<br />
        <strong>Jackets:</strong> Varsity, and Leather<br />
        <strong>Hoodies & T-Shirts</strong>
      </p>
    </div>
  )
}
