import React from "react";
import { useLocation } from "react-router-dom";
import Info from "../common/info/Info";
import UploadArtwork from "../common/upload/UploadArtwork";
import { patchPriceFormatVeganLeather } from "../../../utils/tools";
import Customize from "./customize/Customize";

const VeganLeather = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    return (
      <div className="flex flex-col">
        {page === "info" && <Info />}
        {page === "upload" && (
          <UploadArtwork patchPriceFormat={patchPriceFormatVeganLeather} />
        )}
        {page === "customize" && <Customize />}
      </div>
    );
}

export default VeganLeather
