import React, { useState } from 'react'
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
// import { data } from './dataSlider'
import LeftArrow from "./icons/Left-arrow.png";
import RightArrow from "./icons/Right-arrow.png";
import { AnimatePresence, motion } from "framer-motion";

const NewSlider = ({ pictures, setI, i, handleClick, data }) => {
    const [idx, setIdx] = useState(0);

    return (
        <div
            onClick={() => handleClick()}
            className="mt-2 relative w-[350px] sm:w-[450px]  h-[300px] sm:h-[450px] flex items-center bs1">


            <div
                className={`transition-all  ease-out duration-300 rounded-md  mx-auto mt-1 flex  `}>
                <AnimatePresence>
                    <motion.div
                        key={Math.random()}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1}}
                        exit={{ opacity: 0 }}
                        transition={{duration:1 }}
                        className=""
                        >
                            
                        <LazyLoadImage
                            height={500}
                            width={500}
                            src={pictures?.images[idx]}
                            alt="Image"
                            onClick={() => setI(idx)}
                        className={`absolute top-0 left-0 right-0 bottom-0 object-contain inline-block w-[350px] sm:w-[450px]  h-[300px] sm:h-[450px]`} 
                        />
                    </motion.div>
                </AnimatePresence>
                {/* <motion.img
                    onClick={() => setI(idx)}
                    key={idx}
                    src={pictures?.images[idx]}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className={` w-full object-contain h-[500px]`}
                    // width={450}
                    // height={500}
                    alt="" /> */}

            </div>

            <img
                onClick={(e) => { e.stopPropagation(); idx > 0 ? setIdx(idx - 1) : setIdx(pictures.images.length - 1) }}
                src={LeftArrow}
                className="absolute left-2 w-[40px] top-[50%] translate-y-[-15px]  cursor-pointer"
            />
            <img
                onClick={(e) => {
                    e.stopPropagation();
                    if (idx < pictures.images.length - 1) {
                        setIdx(idx + 1)
                    } else {
                        setIdx(0)
                    }

                }}
                src={RightArrow}
                className="absolute right-2  w-[40px] top-[50%] translate-y-[-15px] cursor-pointer"
            />

            <div className='absolute bottom-2 w-full flex justify-center'>
                {pictures.images.map((p, s) => <div className=' ml-2 w-[15px]  flex items-center justify-center h-[15px] rounded-full border-black border'>
                    <div className={`transition-all duration-300 ease-in-out rounded-full ${s == idx ? "bg-black" : ""}  w-[7px] h-[7px]`}></div>
                </div>)}



            </div>
        </div>
    )
}

export default NewSlider
