import React, { useEffect } from "react";
import "./footer.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import dog from "../../components/images/dog.jpg";
import Front from "../../components/images/Front.png";
import Jackets from "../../components/images/Jackets.png";
import { useSelector, useDispatch } from "react-redux";
import activeStep from "../../actions/activeStep";
import productSummary from "../../actions/productSummary";
import stripelogo from "../images/stripe.svg";
import visa from "../images/visa.png";
import mastercard from "../images/mastercard.png";
import amex from "../images/amex.jpg";
import discover from "../images/discover.png";

export default function Footer() {
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleProduct = (prod) => {
    const name = prod;
    let img = "";
    switch (prod) {
      case "Patches":
        img = dog;
        break;
      case "Garments":
        img = Front;
        break;
      case "Jackets":
        img = Jackets;
        break;
      default:
        img = Jackets;
    }

    const n = name.split(" ");

    const category = n[n.length - 1];
    dispatch(productSummary({ ...product, name, img, category }));
    dispatch(activeStep(2));

    if (prod === "Patches") {
      navigate("/info");
    } else if (prod === "Garments") {
      navigate("/garments");
    } else if (prod === "Jackets") {
      navigate("/jackets");
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://seal.starfieldtech.com/getSeal?sealID=XCZnKPWVUYluWXx5oFH6s3OQd96wIiJlNJqGjgWB9Vnt7R0b3WLJu1U68r4n";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <footer className="font-sans mt-4">
      <div className="px-5 pb-4 bg-gray-200 md:px-10 lg:px-14">
        {/* Top area: Blocks */}
        <div className=" lg:w-[100%]  lg:mx-auto align-middle grid sm:grid-cols-12 gap-8 py-8 pt-4 pb-0 md:pt-5 border-t border-gray-300 ">
          {/* 1st block */}
          {/* 5th block */}
          <div className="sm:col-span-6 md:col-span-4 lg:col-span-3 ">
            <h6 className="mb-2 font-medium text-gray-800 md:text-2xl whitespace-nowrap">
              Peri Apparel & Fabrics
            </h6>
            <div className="flex flex-col text-gray-600 md:text-left  w-2/3 md:w-[100%]">
              <div className="mt-2 md:my-0">
                <div className="inline-flex space-x-2 md:text-md md:my-2 items-left">
                  {/* <ion-icon name="location" className="text-xl"></ion-icon> */}
                  <span>500 N Central Expy, Suite 500<br /> Plano, Texas 75074</span>
                </div>
              </div>
              <div className=" md:my-0">
                <div className="inline-flex items-center space-x-2 md:my-1">
                  {/* <ion-icon name="call" className="text-xl"></ion-icon> */}
                  <span>+1 (469) 334-3185</span>
                </div>
              </div>
              <div className="">
                <div className="inline-flex items-center space-x-2 md:my-1">
                  {/* <ion-icon name="mail" className="text-xl"></ion-icon> */}
                  <span>info@periapparel.com </span>
                </div>
              </div>
              {/* <div className="inline-flex items-center space-x-2">
              <ion-icon name="call" className="text-xl"></ion-icon>
              <span>+(123) 456 7890</span>
            </div>
            <div className="inline-flex items-center space-x-2">
              <ion-icon name="mail" className="text-xl"></ion-icon>
              <span>support@periapperal.com </span>
            </div>
            <div className="inline-flex items-center space-x-2">
              <ion-icon name="location" className="text-xl"></ion-icon>
              <span>500 N Central Expy, Suite 500, Plano Texas 75074</span>
            </div>   */}
            </div>
          </div>

          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="mb-2 font-medium text-gray-800 md:text-lg">
              Company
            </h6>
            <ul className="text-sm">
              <li className="mb-2">
                <Link
                  to="/"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Home
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/about-us"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Contact us
                </Link>
              </li>
              <li className="mb-2">
                <a
                  onClick={() => navigate("/")}
                  href="#testimonials"
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Testimonials
                </a>
              </li>
              <li className="mb-2">
                <a
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                  href={`${process.env.PUBLIC_URL}/PeriApparelBrochure.pdf`}
                  download
                >
                  Brochure
                </a>
              </li>
              <li className="mb-2">
                <Link
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                  to={`/refund-policy`}
                  download
                >
                  Refund Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                  to={`/terms-and-conditions`}
                  download
                >
                  Terms of use & Privacy Policy
                </Link>
              </li>

              {/* <li className="mb-2">
              <Link to="/select" className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900">About Us</Link>
            </li> */}
            </ul>
          </div>

          {/* 3rd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="mb-2 font-medium text-gray-800 md:text-lg">
              Products
            </h6>
            <ul className="text-sm">
              <li className="mb-2">
                <Link
                  to="/patches"
                  className="text-gray-600 transition duration-150 ease-in-out cursor-pointer hover:text-gray-900"
                >
                  Patches
                </Link>
              </li>
              <li className="mb-2">
                <span
                  onClick={() => handleProduct("Garments")}
                  className="text-gray-600 transition duration-150 ease-in-out cursor-pointer hover:text-gray-900"
                >
                  Garments
                </span>
              </li>
              <li className="mb-2">
                <span
                  onClick={() => handleProduct("Jackets")}
                  className="text-gray-600 transition duration-150 ease-in-out cursor-pointer hover:text-gray-900"
                >
                  Jackets
                </span>
              </li>
            </ul>
          </div>

          {/* 4th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2 ">
            <h6 className="mb-2 font-medium text-gray-800 md:text-lg">
              Follow Us
            </h6>
            <ul className="flex items-center text-sm">
              <li className="mt-2 ml-4">
                <a
                  href="https://www.instagram.com/periapparelandfabrics/"
                  className="flex items-center justify-center text-gray-600 transition duration-150 ease-in-out bg-white rounded-full shadow hover:text-gray-900 hover:bg-white-100"
                  aria-label="Github"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    className="bi bi-instagram"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />{" "}
                  </svg>
                </a>
              </li>
              <li className="ml-4">
                <a
                  href="https://www.facebook.com/periapparel/"
                  className="flex items-center justify-center text-gray-600 transition duration-150 ease-in-out bg-white rounded-full shadow hover:text-gray-900 hover:bg-white-100"
                  aria-label="Facebook"
                >
                  <svg
                    className="h-10 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>

          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
            <img src={stripelogo} alt="Stripe" className="max-w-[180px]" />
            <div className="flex mt-3 gap-x-3 ">
              {/* <div className="w-[60px] bdr"> */}
              <img
                src={visa}
                alt=""
                width={60}
                className=" object-contain mx-auto sm:w-[60px] h-[45px] bg-white my-auto"
              />
              {/* </div> */}

              {/* <div className="w-[60px]"> */}
              <img
                src={mastercard}
                alt=""
                width={60}
                className="object-contain sm:w-[60px] mx-auto bg-white my-auto h-[45px]"
              />
              {/* </div> */}

              {/* <div className="w-[60px]"> */}
              <img
                src={amex}
                alt=""
                width={60}
                height={40}
                className="my-auto sm:w-[60px] mx-auto object-contain h-[45px] bg-[#F8f8f8]"
              />
              {/* </div> */}

              {/* <div className="w-[60px]"> */}
              <img
                src={discover}
                alt=""
                width={60}
                className="object-contain h-[45px] bg-white my-auto mx-auto sm:w-[60px]"
              />
              {/* </div> */}
            </div>
            <div className="mt-3 mb-3">
              <span id="siteseal"></span>
            </div>
          </div>
          {/* 4th block */}
          {/* <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
          <h6 className="mb-2 font-medium text-gray-800 md:text-lg">Customized Jackets</h6>
          <ul className="text-sm">
            <li className="mb-2">
              <Link to="#" className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-900">Home</Link>
            </li>
            <li className="mb-2"> 
              <p onClick={()=>handleProduct("Jackets")} className="text-gray-600 transition duration-150 ease-in-out cursor-pointer hover:text-gray-900">Get Started</p>  
            </li>
          </ul>
        </div> */}
        </div>

        {/* Bottom area */}
        <div className="pt-0 pb-4 mt-2 md:flex md:items-center md:justify-between md:-mt-8">
          {/* Social links */}
          {/* <ul className="flex md:order-0 md:ml-4 md:mb-0 "> */}
          {/* <li>
            <Link to="#" className="flex items-center justify-center text-gray-600 transition duration-150 ease-in-out bg-white rounded-full shadow hover:text-gray-900 hover:bg-white-100" aria-label="Twitter">
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
              </svg>
            </Link>
          </li> */}
          {/* </ul> */}

          {/* Copyrights note */}
        </div>
      </div>

      <div className="flex flex-col-reverse sm:flex-row gap-y-4 items-start sm:items-center justify-between text-[16px] text-gray-700  bg-gray-200 md:px-10 lg:px-14 px-5 py-5 border-t border-gray-400">
        <span className="">©2024 Peri Apparel and Fabrics. All rights reserved.</span>
        <Link
          className="text-gray-700 transition duration-150 ease-in-out hover:text-gray-900"
          to={`/terms-and-conditions`}
        >
          Terms of use & Privacy Policy
        </Link>
      </div>
    </footer>
  );
}
