/* eslint-disable no-unused-vars */
import React from "react";
import Guides from "@scena/react-guides";
import Ruler from "@scena/react-ruler";
import InfiniteViewer from "react-infinite-viewer";
const Test = () => {
    const viewerRef = React.useRef(null);
    const selectoRef = React.useRef(null);
  return (
    <div className="relative min-h-full flex justify-center p-3">
      <div className="container max-w-5xl">
        <InfiniteViewer
          className="w-full h-[300px] bg-green-400"
          ref={viewerRef}
          margin={0}
          threshold={0}
          rangeX={[0, 10000]}
          rangeY={[0, 10000]}
          onScroll={(e) => {
            console.log(e);
          }}
        >
          <div className="p-2 bg-yellow-500 ">
            <p>Target</p>
            <p>Target</p>
            <p>Target</p>
            <p>Target</p>
            <p>Target</p>
            <p>Target</p>
            <p>Target</p>
            <p>Target</p>
            <p>Target</p>
            <p>Target</p>
            <p>Target</p>
            <p>Target</p>
          </div>
        </InfiniteViewer>
      </div>
    </div>
  );
};

export default Test;
