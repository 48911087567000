
  const quantityPrice = [];
  quantityPrice[9]=5
  quantityPrice[11]=5.15
  quantityPrice[12]=5.15
  quantityPrice[14]=5.30
  quantityPrice[15]=5.30
  quantityPrice[16]=5.35
  quantityPrice[17]=5.45
  quantityPrice[18]=5.45
  quantityPrice[19]=5.50
  quantityPrice[20]=5.50
  quantityPrice[21]=5.60
  quantityPrice[22]=5.60
  quantityPrice[23]=5.70
  quantityPrice[24]=5.70
  quantityPrice[25]=5.75
  quantityPrice[26]=5.85
  quantityPrice[27]=5.85
  quantityPrice[28]=5.90
  quantityPrice[29]=6.00
  quantityPrice[30]=6.00
  quantityPrice[32]=6.05
  quantityPrice[33]=6.10
  quantityPrice[34]=6.20
  quantityPrice[35]=6.20
  quantityPrice[36]=6.25
  quantityPrice[37]=6.25
  quantityPrice[38]=6.40
  quantityPrice[39]=6.40
  quantityPrice[40]=6.50
  quantityPrice[41]=6.50
  quantityPrice[42]=6.50
  quantityPrice[43]=6.60
  quantityPrice[44]=6.60
  quantityPrice[45]=6.65
  quantityPrice[46]=6.65
  quantityPrice[47]=6.80
  quantityPrice[48]=6.80
  quantityPrice[49]=6.85
  quantityPrice[50]=6.90
  quantityPrice[51]=6.90
  quantityPrice[52]=7.00
  quantityPrice[53]=7.00
  quantityPrice[54]=7.10
  quantityPrice[55]=7.15
  quantityPrice[56]=7.20
  quantityPrice[57]=7.20
  quantityPrice[58]=7.25
  quantityPrice[59]=7.35
  quantityPrice[60]=7.35
  quantityPrice[61]=7.35
  quantityPrice[62]=7.50
  quantityPrice[63]=7.50
  quantityPrice[64]=7.55
  quantityPrice[65]=7.60
  quantityPrice[66]=7.65
  quantityPrice[67]=7.65
  quantityPrice[68]=7.65
  quantityPrice[69]=7.80
  quantityPrice[70]=7.80
  quantityPrice[71]=7.80
  quantityPrice[72]=7.90
  quantityPrice[73]=7.90
  quantityPrice[74]=8.00
  quantityPrice[75]=8.00
  quantityPrice[76]=8.00
  quantityPrice[77]=8.15
  quantityPrice[78]=8.20
  quantityPrice[79]=8.25
  quantityPrice[80]=8.25
  quantityPrice[81]=8.30
  quantityPrice[83]=8.30
  quantityPrice[84]=8.50
  quantityPrice[85]=8.50
  quantityPrice[86]=8.65
  quantityPrice[87]=8.65
  quantityPrice[88]=8.65
  quantityPrice[89]=8.75
  quantityPrice[90]=8.75
  quantityPrice[91]=8.80
  quantityPrice[92]=8.80
  quantityPrice[94]=9.00
  quantityPrice[95]=9.00
  quantityPrice[96]=9.00
  quantityPrice[98]=9.15
  quantityPrice[99]=9.20
  quantityPrice[100]=9.20
  quantityPrice[101]=9.20
  quantityPrice[102]=9.40
  quantityPrice[104]=9.40
  quantityPrice[105]=9.45
  quantityPrice[106]=9.60
  quantityPrice[108]=9.60
  quantityPrice[109]=9.70
  quantityPrice[110]=9.70
  quantityPrice[111]=9.75
  quantityPrice[112]=9.75
  quantityPrice[113]=9.85
  quantityPrice[114]=9.85
  quantityPrice[115]=10.00
  quantityPrice[116]=10.00
  quantityPrice[117]=10.00
  quantityPrice[119]=10.15
  quantityPrice[120]=10.15
  quantityPrice[121]=10.20
  quantityPrice[122]=10.20
  quantityPrice[123]=10.40
  quantityPrice[124]=10.40
  quantityPrice[125]=10.40
  quantityPrice[126]=10.50
  quantityPrice[127]=10.50
  quantityPrice[128]=10.60
  quantityPrice[130]=10.60
  quantityPrice[131]=10.70
  quantityPrice[132]=10.70
  quantityPrice[133]=10.85
  quantityPrice[135]=10.85
  quantityPrice[137]=10.85
  quantityPrice[138]=11.00
  quantityPrice[140]=11.00
  quantityPrice[142]=11.25
  quantityPrice[143]=11.25
  quantityPrice[144]=11.25
  quantityPrice[145]=11.35
  quantityPrice[147]=11.50
  quantityPrice[149]=11.50
  quantityPrice[150]=11.50
  quantityPrice[152]=11.75
  quantityPrice[154]=11.75
  quantityPrice[155]=11.75
  quantityPrice[156]=12.00
  quantityPrice[158]=12.00
  quantityPrice[160]=12.00
  quantityPrice[161]=12.15
  quantityPrice[162]=12.15
  quantityPrice[163]=12.25
  quantityPrice[165]=12.25
  quantityPrice[167]=12.35
  quantityPrice[168]=12.35
  quantityPrice[169]=12.35
  quantityPrice[173]=12.50
  quantityPrice[174]=12.50
  quantityPrice[175]=12.50
  quantityPrice[176]=12.60
  quantityPrice[180]=13.00
  quantityPrice[181]=13.00
  quantityPrice[182]=13.00
  quantityPrice[188]=13.50
  quantityPrice[189]=13.50
  quantityPrice[195]=13.75
  quantityPrice[196]=13.75
  quantityPrice[203]=14.00
  quantityPrice[210]=14.30
  quantityPrice[218]=14.60
  quantityPrice[225]=15

  const quantityPriceEmbroidery = [];
  quantityPriceEmbroidery[9]=4
  quantityPriceEmbroidery[11]=4.15
  quantityPriceEmbroidery[12]=4.15
  quantityPriceEmbroidery[14]=4.30
  quantityPriceEmbroidery[15]=4.30
  quantityPriceEmbroidery[16]=4.35
  quantityPriceEmbroidery[17]=4.45
  quantityPriceEmbroidery[18]=4.45
  quantityPriceEmbroidery[19]=4.50
  quantityPriceEmbroidery[20]=4.50
  quantityPriceEmbroidery[21]=4.60
  quantityPriceEmbroidery[22]=4.60
  quantityPriceEmbroidery[23]=4.70
  quantityPriceEmbroidery[24]=4.70
  quantityPriceEmbroidery[25]=4.75
  quantityPriceEmbroidery[26]=4.85
  quantityPriceEmbroidery[27]=4.85
  quantityPriceEmbroidery[28]=4.90
  quantityPriceEmbroidery[29]=5.00
  quantityPriceEmbroidery[30]=5.00
  quantityPriceEmbroidery[32]=5.05
  quantityPriceEmbroidery[33]=5.10
  quantityPriceEmbroidery[34]=5.20
  quantityPriceEmbroidery[35]=5.20
  quantityPriceEmbroidery[36]=5.25
  quantityPriceEmbroidery[37]=5.25
  quantityPriceEmbroidery[38]=5.40
  quantityPriceEmbroidery[39]=5.40
  quantityPriceEmbroidery[40]=5.50
  quantityPriceEmbroidery[41]=5.50
  quantityPriceEmbroidery[42]=5.50
  quantityPriceEmbroidery[43]=5.60
  quantityPriceEmbroidery[44]=5.60
  quantityPriceEmbroidery[45]=5.65
  quantityPriceEmbroidery[46]=5.65
  quantityPriceEmbroidery[47]=5.80
  quantityPriceEmbroidery[48]=5.80
  quantityPriceEmbroidery[49]=5.85
  quantityPriceEmbroidery[50]=5.90
  quantityPriceEmbroidery[51]=5.90
  quantityPriceEmbroidery[52]=6.00
  quantityPriceEmbroidery[53]=6.00
  quantityPriceEmbroidery[54]=6.10
  quantityPriceEmbroidery[55]=6.15
  quantityPriceEmbroidery[56]=6.20
  quantityPriceEmbroidery[57]=6.20
  quantityPriceEmbroidery[58]=6.25
  quantityPriceEmbroidery[59]=6.35
  quantityPriceEmbroidery[60]=6.35
  quantityPriceEmbroidery[61]=6.35
  quantityPriceEmbroidery[62]=6.50
  quantityPriceEmbroidery[63]=6.50
  quantityPriceEmbroidery[64]=6.55
  quantityPriceEmbroidery[65]=6.60
  quantityPriceEmbroidery[66]=6.65
  quantityPriceEmbroidery[67]=6.65
  quantityPriceEmbroidery[68]=6.65
  quantityPriceEmbroidery[69]=6.80
  quantityPriceEmbroidery[70]=6.80
  quantityPriceEmbroidery[71]=6.80
  quantityPriceEmbroidery[72]=6.90
  quantityPriceEmbroidery[73]=6.90
  quantityPriceEmbroidery[74]=7.00
  quantityPriceEmbroidery[75]=7.00
  quantityPriceEmbroidery[76]=7.00
  quantityPriceEmbroidery[77]=7.15
  quantityPriceEmbroidery[78]=7.20
  quantityPriceEmbroidery[79]=7.25
  quantityPriceEmbroidery[80]=7.25
  quantityPriceEmbroidery[81]=7.30
  quantityPriceEmbroidery[83]=7.30
  quantityPriceEmbroidery[84]=7.50
  quantityPriceEmbroidery[85]=7.50
  quantityPriceEmbroidery[86]=7.65
  quantityPriceEmbroidery[87]=7.65
  quantityPriceEmbroidery[88]=7.65
  quantityPriceEmbroidery[89]=7.75
  quantityPriceEmbroidery[90]=7.75
  quantityPriceEmbroidery[91]=7.80
  quantityPriceEmbroidery[92]=7.80
  quantityPriceEmbroidery[94]=8.00
  quantityPriceEmbroidery[95]=8.00
  quantityPriceEmbroidery[96]=8.00
  quantityPriceEmbroidery[98]=8.15
  quantityPriceEmbroidery[99]=8.20
  quantityPriceEmbroidery[100]=8.20
  quantityPriceEmbroidery[101]=8.20
  quantityPriceEmbroidery[102]=8.40
  quantityPriceEmbroidery[104]=8.40
  quantityPriceEmbroidery[105]=8.45
  quantityPriceEmbroidery[106]=8.60
  quantityPriceEmbroidery[108]=8.60
  quantityPriceEmbroidery[109]=8.70
  quantityPriceEmbroidery[110]=8.70
  quantityPriceEmbroidery[111]=8.75
  quantityPriceEmbroidery[112]=8.75
  quantityPriceEmbroidery[113]=8.85
  quantityPriceEmbroidery[114]=8.85
  quantityPriceEmbroidery[115]=9.00
  quantityPriceEmbroidery[116]=9.00
  quantityPriceEmbroidery[117]=9.00
  quantityPriceEmbroidery[119]=9.15
  quantityPriceEmbroidery[120]=9.15
  quantityPriceEmbroidery[121]=9.20
  quantityPriceEmbroidery[122]=9.20
  quantityPriceEmbroidery[123]=9.40
  quantityPriceEmbroidery[124]=9.40
  quantityPriceEmbroidery[125]=9.40
  quantityPriceEmbroidery[126]=9.50
  quantityPriceEmbroidery[127]=9.50
  quantityPriceEmbroidery[128]=9.60
  quantityPriceEmbroidery[130]=9.60
  quantityPriceEmbroidery[131]=9.70
  quantityPriceEmbroidery[132]=9.70
  quantityPriceEmbroidery[133]=9.85
  quantityPriceEmbroidery[135]=9.85
  quantityPriceEmbroidery[137]=9.85
  quantityPriceEmbroidery[138]=10.00
  quantityPriceEmbroidery[140]=10.00
  quantityPriceEmbroidery[142]=10.25
  quantityPriceEmbroidery[143]=10.25
  quantityPriceEmbroidery[144]=10.25
  quantityPriceEmbroidery[145]=10.35
  quantityPriceEmbroidery[147]=10.50
  quantityPriceEmbroidery[149]=10.50
  quantityPriceEmbroidery[150]=10.50
  quantityPriceEmbroidery[152]=10.75
  quantityPriceEmbroidery[154]=10.75
  quantityPriceEmbroidery[155]=10.75
  quantityPriceEmbroidery[156]=11.00
  quantityPriceEmbroidery[158]=11.00
  quantityPriceEmbroidery[160]=11.00
  quantityPriceEmbroidery[161]=11.15
  quantityPriceEmbroidery[162]=11.15
  quantityPriceEmbroidery[163]=11.25
  quantityPriceEmbroidery[165]=11.25
  quantityPriceEmbroidery[167]=11.35
  quantityPriceEmbroidery[168]=11.35
  quantityPriceEmbroidery[169]=11.35
  quantityPriceEmbroidery[173]=11.50
  quantityPriceEmbroidery[174]=11.50
  quantityPriceEmbroidery[175]=11.50
  quantityPriceEmbroidery[176]=11.60
  quantityPriceEmbroidery[180]=12.00
  quantityPriceEmbroidery[181]=12.00
  quantityPriceEmbroidery[182]=12.00
  quantityPriceEmbroidery[188]=12.50
  quantityPriceEmbroidery[189]=12.50
  quantityPriceEmbroidery[195]=12.75
  quantityPriceEmbroidery[196]=12.75
  quantityPriceEmbroidery[203]=13.00
  quantityPriceEmbroidery[210]=13.30
  quantityPriceEmbroidery[218]=13.60
  quantityPriceEmbroidery[225]=14


  const quantityPriceVeganLeather = [];
  quantityPriceVeganLeather[9] = 4.25;
  quantityPriceVeganLeather[11] = 4.40;
  quantityPriceVeganLeather[12] = 4.40;
  quantityPriceVeganLeather[14] = 4.55;
  quantityPriceVeganLeather[15] = 4.55;
  quantityPriceVeganLeather[16] = 4.60;
  quantityPriceVeganLeather[17] = 4.70;
  quantityPriceVeganLeather[18] = 4.70;
  quantityPriceVeganLeather[19] = 4.75;
  quantityPriceVeganLeather[20] = 4.75;
  quantityPriceVeganLeather[21] = 4.85;
  quantityPriceVeganLeather[22] = 4.85;
  quantityPriceVeganLeather[23] = 4.95;
  quantityPriceVeganLeather[24] = 4.95;
  quantityPriceVeganLeather[25] = 5.00;
  quantityPriceVeganLeather[26] = 5.10;
  quantityPriceVeganLeather[27] = 5.10;
  quantityPriceVeganLeather[28] = 5.15;
  quantityPriceVeganLeather[29] = 5.25;
  quantityPriceVeganLeather[30] = 5.25;
  quantityPriceVeganLeather[32] = 5.30;
  quantityPriceVeganLeather[33] = 5.35;
  quantityPriceVeganLeather[34] = 5.45;
  quantityPriceVeganLeather[35] = 5.45;
  quantityPriceVeganLeather[36] = 5.50;
  quantityPriceVeganLeather[37] = 5.50;
  quantityPriceVeganLeather[38] = 5.65;
  quantityPriceVeganLeather[39] = 5.65;
  quantityPriceVeganLeather[40] = 5.75;
  quantityPriceVeganLeather[41] = 5.75;
  quantityPriceVeganLeather[42] = 5.75;
  quantityPriceVeganLeather[43] = 5.85;
  quantityPriceVeganLeather[44] = 5.85;
  quantityPriceVeganLeather[45] = 5.90;
  quantityPriceVeganLeather[46] = 5.90;
  quantityPriceVeganLeather[47] = 6.05;
  quantityPriceVeganLeather[48] = 6.05;
  quantityPriceVeganLeather[49] = 6.10;
  quantityPriceVeganLeather[50] = 6.15;
  quantityPriceVeganLeather[51] = 6.15;
  quantityPriceVeganLeather[52] = 6.25;
  quantityPriceVeganLeather[53] = 6.25;
  quantityPriceVeganLeather[54] = 6.35;
  quantityPriceVeganLeather[55] = 6.40;
  quantityPriceVeganLeather[56] = 6.45;
  quantityPriceVeganLeather[57] = 6.45;
  quantityPriceVeganLeather[58] = 6.50;
  quantityPriceVeganLeather[59] = 6.60;
  quantityPriceVeganLeather[60] = 6.60;
  quantityPriceVeganLeather[61] = 6.60;
  quantityPriceVeganLeather[62] = 6.75;
  quantityPriceVeganLeather[63] = 6.75;
  quantityPriceVeganLeather[64] = 6.80;
  quantityPriceVeganLeather[65] = 6.85;
  quantityPriceVeganLeather[66] = 6.90;
  quantityPriceVeganLeather[67] = 6.90;
  quantityPriceVeganLeather[68] = 6.90;
  quantityPriceVeganLeather[69] = 7.05;
  quantityPriceVeganLeather[70] = 7.05;
  quantityPriceVeganLeather[71] = 7.05;
  quantityPriceVeganLeather[72] = 7.15;
  quantityPriceVeganLeather[73] = 7.15;
  quantityPriceVeganLeather[74] = 7.25;
  quantityPriceVeganLeather[75] = 7.25;
  quantityPriceVeganLeather[76] = 7.25;
  quantityPriceVeganLeather[77] = 7.40;
  quantityPriceVeganLeather[78] = 7.45;
  quantityPriceVeganLeather[79] = 7.50;
  quantityPriceVeganLeather[80] = 7.50;
  quantityPriceVeganLeather[81] = 7.55;
  quantityPriceVeganLeather[83] = 7.55;
  quantityPriceVeganLeather[84] = 7.75;
  quantityPriceVeganLeather[85] = 7.75;
  quantityPriceVeganLeather[86] = 7.90;
  quantityPriceVeganLeather[87] = 7.90;
  quantityPriceVeganLeather[88] = 7.90;
  quantityPriceVeganLeather[89] = 8.00;
  quantityPriceVeganLeather[90] = 8.00;
  quantityPriceVeganLeather[91] = 8.05;
  quantityPriceVeganLeather[92] = 8.05;
  quantityPriceVeganLeather[94] = 8.25;
  quantityPriceVeganLeather[95] = 8.25;
  quantityPriceVeganLeather[96] = 8.25;
  quantityPriceVeganLeather[98] = 8.40;
  quantityPriceVeganLeather[99] = 8.45;
  quantityPriceVeganLeather[100] = 8.45;
  quantityPriceVeganLeather[101] = 8.45;
  quantityPriceVeganLeather[102] = 8.65;
  quantityPriceVeganLeather[104] = 8.65;
  quantityPriceVeganLeather[105] = 8.70;
  quantityPriceVeganLeather[106] = 8.85;
  quantityPriceVeganLeather[108] = 8.85;
  quantityPriceVeganLeather[109] = 8.95;
  quantityPriceVeganLeather[110] = 8.95;
  quantityPriceVeganLeather[111] = 9.00;
  quantityPriceVeganLeather[112] = 9.00;
  quantityPriceVeganLeather[113] = 9.10;
  quantityPriceVeganLeather[114] = 9.10;
  quantityPriceVeganLeather[115] = 9.25;
  quantityPriceVeganLeather[116] = 9.25;
  quantityPriceVeganLeather[117] = 9.25;
  quantityPriceVeganLeather[119] = 9.40;
  quantityPriceVeganLeather[120] = 9.40;
  quantityPriceVeganLeather[121] = 9.45;
  quantityPriceVeganLeather[122] = 9.45;
  quantityPriceVeganLeather[123] = 9.65;
  quantityPriceVeganLeather[124] = 9.65;
  quantityPriceVeganLeather[125] = 9.65;
  quantityPriceVeganLeather[126] = 9.75;
  quantityPriceVeganLeather[127] = 9.75;
  quantityPriceVeganLeather[128] = 9.85;
  quantityPriceVeganLeather[130] = 9.85;
  quantityPriceVeganLeather[131] = 9.95;
  quantityPriceVeganLeather[132] = 9.95;
  quantityPriceVeganLeather[133] = 10.10;
  quantityPriceVeganLeather[135] = 10.10;
  quantityPriceVeganLeather[137] = 10.10;
  quantityPriceVeganLeather[138] = 10.25;
  quantityPriceVeganLeather[140] = 10.25;
  quantityPriceVeganLeather[142] = 10.50;
  quantityPriceVeganLeather[143] = 10.50;
  quantityPriceVeganLeather[144] = 10.50;
  quantityPriceVeganLeather[145] = 10.60;
  quantityPriceVeganLeather[147] = 10.75;
  quantityPriceVeganLeather[149] = 10.75;
  quantityPriceVeganLeather[150] = 10.75;
  quantityPriceVeganLeather[152] = 11.00;
  quantityPriceVeganLeather[154] = 11.00;
  quantityPriceVeganLeather[155] = 11.00;
  quantityPriceVeganLeather[156] = 11.25;
  quantityPriceVeganLeather[158] = 11.25;
  quantityPriceVeganLeather[160] = 11.25;
  quantityPriceVeganLeather[161] = 11.40;
  quantityPriceVeganLeather[162] = 11.40;
  quantityPriceVeganLeather[163] = 11.50;
  quantityPriceVeganLeather[165] = 11.50;
  quantityPriceVeganLeather[167] = 11.60;
  quantityPriceVeganLeather[168] = 11.60;
  quantityPriceVeganLeather[169] = 11.60;
  quantityPriceVeganLeather[173] = 11.75;
  quantityPriceVeganLeather[174] = 11.75;
  quantityPriceVeganLeather[175] = 11.85;
  quantityPriceVeganLeather[176] = 11.85;
  quantityPriceVeganLeather[180] = 12.00;
  quantityPriceVeganLeather[181] = 12.00;
  quantityPriceVeganLeather[182] = 12.00;
  quantityPriceVeganLeather[188] = 12.75;
  quantityPriceVeganLeather[189] = 12.75;
  quantityPriceVeganLeather[195] = 13.00;
  quantityPriceVeganLeather[196] = 13.00;
  quantityPriceVeganLeather[203] = 13.25;
  quantityPriceVeganLeather[210] = 13.55;
  quantityPriceVeganLeather[218] = 13.85;
  quantityPriceVeganLeather[225] = 14.25;
  


  export {quantityPrice , quantityPriceEmbroidery , quantityPriceVeganLeather };
