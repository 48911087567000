import { v4 as uuidv4 } from "uuid";

const dataSlider = [
  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem"
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem"
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem"
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem"
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem"
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem"
  },

];

export default dataSlider;



const data = [
  {
    _id:"1",
    title:"Left Chest patch",
    images:[`${process.env.PUBLIC_URL}/Imgs/LC/LC1_Large.png`,`${process.env.PUBLIC_URL}/Imgs/LC/LC2_Large.png`,`${process.env.PUBLIC_URL}/Imgs/LC/LC3_Large.png`,`${process.env.PUBLIC_URL}/Imgs/LC/LC4_Large.png`]
  },
  {
    _id:"2",
    title:"Name patch",
    images:[`${process.env.PUBLIC_URL}/Imgs/NP/NP1_Large.png`,`${process.env.PUBLIC_URL}/Imgs/NP/NP2_Large.png`,]
  },
  {
    _id:"3",
    title:"Hybrid patch",
    images:[`${process.env.PUBLIC_URL}/Imgs/HP/HP1_Large.png`,`${process.env.PUBLIC_URL}/Imgs/HP/HP2_Large.png`,`${process.env.PUBLIC_URL}/Imgs/HP/HP3_Large.png`,`${process.env.PUBLIC_URL}/Imgs/HP/HP4_Large.png`]
  },
  {
    _id:"4",
    title:"Picture patch",
    images:[`${process.env.PUBLIC_URL}/Imgs/PP/PP1_Large.png`,`${process.env.PUBLIC_URL}/Imgs/PP/PP2_Large.png`,`${process.env.PUBLIC_URL}/Imgs/PP/PP3_Large.png`]
  },
  {
    _id:"5",
    title:"Old style patch",
    images:[`${process.env.PUBLIC_URL}/Imgs/OSP/OSP1_Large.png`,]
  },
  {
    _id:"6",
    title:"Alphabet/Number Patch",
    images:[`${process.env.PUBLIC_URL}/Imgs/ANP/ANP1_Small.png`,`${process.env.PUBLIC_URL}/Imgs/ANP/ANP2_Large.png`,`${process.env.PUBLIC_URL}/Imgs/ANP/ANP3_Large.png`,`${process.env.PUBLIC_URL}/Imgs/ANP/ANP4_Large.png`]
  },
  {
    _id:"7",
    title:"Activity Patch",
    images:[`${process.env.PUBLIC_URL}/Imgs/AP/AP1_Large.png`,`${process.env.PUBLIC_URL}/Imgs/AP/AP2_Large.png`,`${process.env.PUBLIC_URL}/Imgs/AP/AP3_Large.png`]
  },
  {
    _id:"8",
    title:"Direct Chenille to Garment",
    images:[`${process.env.PUBLIC_URL}/Imgs/DC/DC1_Large.png`,`${process.env.PUBLIC_URL}/Imgs/DC/DC2_Large.png`]
  },
]

export {data}