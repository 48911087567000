import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import activeStep from "../../actions/activeStep";
import productSummary from "../../actions/productSummary";
import axios from "axios";
import CheckoutForm from "./CheckoutPage";
import { API_URL } from "../../config";
import CheckoutForm2 from "./CheckoutPage2";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// pk_test_51HBYWxKonB7noqZtuEMGRtJx9ftO82DYRdhvbLFkIMX00buBrwQnukdH9oosuAijzaWEDx8oMRxrQQXI2DGlF1nl00VhXp70Zy
// pk_live_51HBYWxKonB7noqZtD4LLSUu0qJV9QEqY3Wz6YoilPU4OF514cbqm1PhMqLnxOzHsFAs9GLomy4M2tEDKqwzz3zBX00qbQuVQDP
// const stripePromise = loadStripe(
//   "pk_test_51HBYWxKonB7noqZtuEMGRtJx9ftO82DYRdhvbLFkIMX00buBrwQnukdH9oosuAijzaWEDx8oMRxrQQXI2DGlF1nl00VhXp70Zy"
// );

function Payment2() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const appearance = {
    theme: "flat",

    variables: {
      spacingUnit: "5px",
      spacingGridRow: "40px",
      spacingGridColumn: "35px",
      borderRadius: "8px",
      colorDanger: "#df1b41",
    },
    //   colorPrimary: '#0570de',
    //   colorBackground: '#ffffff',
    //   colorText: '#30313d',
    //   fontFamily: 'Ideal Sans, system-ui, sans-serif',
    //   spacingUnit: '2px',
    //   spacingTab:'4px',

    //   // See all possible variables below
    // }
  };
  useEffect(() => {
    fetch(`${API_URL}publicKey`).then(async (r) => {
      const response = await r.json();
      console.log(response);
      setStripePromise(loadStripe(response.key));
    });
  }, []);

  useEffect(() => {
    axios
      .post(API_URL + "payment", {
        amount: Math.round((1 + Number.EPSILON) * 100) / 100,
        // amount:,
      })
      .then((response) => {
        // if (response) {
        console.log(1);
        setClientSecret(response.data.client_secret);
        // }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="mx-2 md:mx-auto mt-24 md:mt-40 md:w-[50%]">
        {clientSecret.length > 0 && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret, appearance }}
          >
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </>
  );
}

export default Payment2;
