/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

import Summary from "./Summary";
import { useSelector} from "react-redux";
import Selection from "./Selection";
import Instructions from "../../common/customize/Instructions";
import Navigation from "../../common/customize/Navigation";
import { convertText } from "../../../../utils/tools";

const Customize = () => {
  const product = useSelector((state) => state.productSummary);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (product.originalImage === null) {
      navigate("/patch/chenille?page=upload")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate(`/patch/${id}?page=info`)
  };



  return (
    <>
      <form onSubmit={handleSubmit} className="pb-10 lg:px-10">
        <div className="flex flex-col gap-12 mt-10 lg:flex-row">
          <div className="flex flex-col w-full">
            <div className="font-sans text-2xl font-semibold uppercase lg:text-3xl xl:text-4xl">
              Step-2 {`(${convertText(id)} patch)`}
            </div>
            <div className="mt-6 text-sm font-medium text-gray-500 lg:text-lg">
              CUSTOMIZE
            </div>
            <div className="mt-4 text-sm font-normal tracking-tight text-justify sm:text-base sm:pl-0 ">
              Customize by selecting the options that best fit your needs. As
              you make selections, the Order Summary on the right will update
              and reflect each choice - any upgrades that add additional cost to
              your order will also be reflected.
            </div>
            <div className="w-full h-1 mt-10 bg-[#dadbd3]"></div>
            <Selection />
            <Instructions/>
          </div>

          <Summary />
        </div>
         <Navigation/>
      </form>
    </>
  );
};

export default Customize;
