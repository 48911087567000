/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useForm } from "react-hook-form";
import ReCaptcha from "../../../Recaptcha";
import Cookies from "js-cookie";
import { getUserInfo, patchUserInfo, postUserInfo } from "../../../../api/form";
import {  useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../../config";
import { useSelector } from "react-redux";
import Form from "./Form";
import { AiFillCaretLeft, AiOutlineLoading3Quarters } from "react-icons/ai";
import { convertText } from "../../../../utils/tools";
import OrderDetail from "./OrderDetail";

const GuestLogin = ({ setIsLoggedIn }) => {
  const product = useSelector((state) => state.productSummary);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isVerified, setIsVarified] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const addToCart = async () => {
    setShowLoader(true);
    try {
      const editImage = new FormData();
      editImage.append("image", product.editImage);
      const resEdit = await axios.post(API_URL + "order/img/upload", editImage);
      const originalImage = new FormData();
      originalImage.append("image", product.originalImage);
      const resOriginal = await axios.post(
        API_URL + "order/img/upload",
        originalImage
      );
      const authUserId = Cookies.get("authUserId");
      const userId = Cookies.get("userId");
      const token = Cookies.get("token");
      if (resEdit.status === 200 && resOriginal.status === 200) {
        // message.warning("Processing......");
        const orderResponse = await axios.post(`${API_URL}order/add`, {
          ...(token ? { authUserId } : { userId }),
          ...(product.instructions && { notes: product.instructions }),
          // deliveryTime: "expedit",
          category: convertText(id),
          imageUrls: resEdit.data.imageUrls,
          originalImage: resOriginal.data.imageUrls[0],
          reasonToPurchase: "some reason",
          quantity: product.quantity.size,
          wide: product.wide,
          long: product.long,
          totalPrice: product.finalPrice,
          embroidary: false,
          backing: false,
          borderEdge: 1,
          color: false,
          skip: product.skipSample,
          other: product.other,
        });
        if (orderResponse.status === 200) {
          setShowMessage(true);
          setShowLoader(false);
        }
        setShowLoader(false);
      }
    } catch (err) {
      setShowLoader(false);
    }
  };
  const onSubmit = async (data) => {
    // e.preventDefault();
    if (isVerified) {
      const { email } = data;
      let userInfo = "";

      userInfo = await getUserInfo(email.replace("+", "%2B"));
      if (userInfo.status === 200) {
        userInfo = await patchUserInfo(data);

        Cookies.set("userId", userInfo?.data?.id);
        localStorage.setItem("email", email);
        await addToCart();
      } else {
        userInfo = await postUserInfo(data);
        Cookies.set("userId", userInfo?.data?.id);
        localStorage.setItem("email", email);
        await addToCart();
      }
    }
  };
  React.useEffect(() => {
    (async () => {
      const token = Cookies.get("token");
      if (token) {
        const { data } = await axios.get(`${API_URL}auth/myself`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (data?.userDetails?.email) {
          setIsLoggedIn(true);
          setValue("firstname", data?.userDetails?.firstname);
          setValue("lastname", data?.userDetails?.lastname);
          setValue("email", data?.userDetails?.email);
        }
      }
    })();
  }, []);
  const checkUser = async () => {
    const email = localStorage.getItem("email");
    if (email) {
      const userInfo = await getUserInfo(email);
      if (userInfo.status === 200) {
        const { firstname, lastname, email, contact, _id } =
          userInfo.data.userExist;
        setValue("firstname", firstname);
        setValue("lastname", lastname);
        setValue("email", email);
        setValue("contact", contact);
        Cookies.set("userId", _id);
      }
    }
  };

  React.useEffect(() => {
    checkUser();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center gap-y-2"
    >
      <p className="text-2xl font-bold text-center ">Enter Details</p>
      <Form setValue={setValue} register={register} errors={errors} />
      <div className="flex items-center justify-center w-full mt-4">
        <ReCaptcha
          OnClick={(isvarify) => setIsVarified(isvarify)}
          // reset={isProcessing}
        />
      </div>
      <div className="flex flex-col items-center justify-center mt-10 mb-20 sm:flex-row gap-y-5 ">
        <button
          className={`flex items-center px-12 text-2xl text-white bg-green-700 rounded-full hover:bg-green-600 w-60 h-14 ${showLoader && 'cursor-not-allowed'}`}
          type="button"
          disabled={showLoader}
          onClick={() => navigate(`/patch/${id}?page=customize`)}
        >
          <AiFillCaretLeft className="mr-2" />
          <span>Previous</span>
        </button>

        {showLoader ? (
          <button
            className="px-12 ml-2 text-2xl text-white rounded-full bg-sky-700 hover:bg-sky-600 py-3  cursor-not-allowed flex items-center gap-3"
            disabled={true}
            type="button"
          >
            <AiOutlineLoading3Quarters className="w-5 h-5 animate-spin" />
            <p>Processing...</p>
          </button>
        ) : (
          <button
            className="px-12 ml-2 text-2xl text-white rounded-full bg-sky-700 hover:bg-sky-600 h-14 w-60 cursor-pointer"
            disabled={!isVerified}
            type="submit"
          >
            <span>Add to Cart</span>
          </button>
        )}
      </div>
      <OrderDetail showMessage={showMessage} setShowMessage={setShowMessage} />
    </form>
  );
};

export default GuestLogin;
