import React from "react";
import { IoClose } from "react-icons/io5";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const PreviewImages = ({ setPreview, preview }) => {
  const [my_swiper, set_my_swiper] = React.useState({});

  return (
    <div className="fixed inset-0 z-[50] bg-black/80 flex ">
      <div className="absolute top-3 right-1 md:right-3 z-50">
        <IoClose
          className="text-white w-10 h-10 cursor-pointer "
          onClick={() => setPreview(null)}
        />
      </div>
      <div
        className="w-[70px] md:w-[180px] bg-[#2e2e2e]  h-full cursor-pointer flex flex-col justify-center items-center"
        onClick={() => my_swiper.slidePrev()}
      >
        <BsChevronLeft className="text-white w-10 h-10"/>
      </div>
      <Swiper
        cssMode={true}
        // navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="w-full h-full mySwiper flex justify-center items-center"
        onInit={(ev) => {
          set_my_swiper(ev);
        }}
        onClick={() => setPreview(null)}
      >
        {preview?.map((el, i) => (
          <SwiperSlide className="w-full  " key={i}>
            <div className="w-full h-full flex items-center justify-center  ">
              <img
                src={el}
                alt="Patches"
                className="object-contain w-full md:w-[40%]  bg-white "
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className=" w-[70px] md:w-[180px] bg-[#2e2e2e] h-full cursor-pointer flex flex-col justify-center items-center"
        onClick={() => my_swiper.slideNext()}
      >
        <BsChevronRight className="text-white w-10 h-10"/>
      </div>
    </div>
  );
};

export default PreviewImages;
