/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Progressbar from "../../components/Progressbar/Progressbar";
import CheckoutSuccess from "../../components/CheckoutPage/checkout";
import Footer from "../../components/footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import activeStep from "../../actions/activeStep";
import productSummary from "../../actions/productSummary";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../config";
import Cookies from "js-cookie";
import { AiFillCaretLeft, AiOutlineClose } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { Spin, message } from "antd";
import FormatArtWork from "./FormatArtWork";
import Summary from "./Summary";
import SelectProductSize from "./SelectProductSize";
import SelectQuantity from "./SelectQuantity";
import UploadImage from "./UploadImage";
import Instructions from "./Instructions";

export default function CustomizePage() {
  const active = useSelector((state) => state.activeStep);
  const [imageUrl, setImageUrl] = useState("");
  const [viewImage, setViewImage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [images, setImage] = useState([]);
  const [instructions, setInstructions] = useState(" ");
  const [dragActive, setDragActive] = useState(false);
  const [place, setPlace] = useState(false);
  const inputRef = useRef(null);
  const [showMessage, setShowMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(activeStep(5));
    navigate("/customize");
  };
  console.log(product.editImage)

  return (
    <>
      <div className="text-black bg-brand ">
        {/* <div className="container h-40 px-1 bg-white lg:mx-auto lg:px-2"> */}
        <Navbar refresh={showLoader} />
        {/* </div> */}
        <div className="flex flex-col" id="steps_container">
          <div className="flex flex-row mx-auto mt-5 xl:mx-40 sm:mt-0">
            <Progressbar />
          </div>
        </div>
        {place && <CheckoutSuccess />}
        {/*----Step 5----*/}
        <form onSubmit={onSubmit} className="flex flex-col gap-10">
          <div className="flex flex-col gap-6 px-4 mt-10 lg:flex-row">
            <div className="flex flex-col w-full h-fit">
              <div className="font-sans text-2xl font-semibold uppercase lg:text-3xl xl:text-4xl">
                Step-3
              </div>

              <div className="my-1 text-sm font-medium text-gray-500 uppercase md:pl-0 lg:text-lg">
                SELECT SIZE, QUANTITY AND UPLOAD ARTWORK.
              </div>
              <div className="">
                Upload the artwork and select the active area for the uploaded
                artwork image for the selected patch size. The selected patch
                size should contain the artwork within it's frame as shown the
                preview.
              </div>
              {product.editImage === null && <SelectProductSize />}

              <SelectQuantity />
              {product.quantity.size !== 0 && (
                <UploadImage setImageUrl={setImageUrl} />
              )}
            </div>

            <Summary />
          </div>
          {product.originalImage && (
            <FormatArtWork
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              setImage={setImage}
              setViewImage={setViewImage}
              uploadFiles={uploadFiles[0]}
            />
          )}
          <Instructions />
          <div className="flex flex-col items-center justify-center mt-10 mb-20 sm:flex-row gap-y-5">
            <Link to="/info">
              <button
                className="flex items-center px-12 text-2xl text-white bg-green-700 rounded-full hover:bg-green-600 w-60 h-14"
                type="button"
              >
                <AiFillCaretLeft className="mr-2" />
                <span>Previous</span>
              </button>
            </Link>
            <button
              className="px-12 ml-4 text-2xl text-white rounded-full bg-sky-700 hover:bg-sky-600 h-14"
              type="submit"
            >
              Continue
            </button>
          </div>
        </form>
        <Footer />
      </div>
    </>
  );
}
