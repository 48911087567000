import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import axios from "axios";
import Cookies from "js-cookie";
import { API_URL } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { GoCreditCard } from "react-icons/go";
import { Spin } from "antd";

const MyCart = () => {
  const [cartData, setCartData] = useState([]);
  const [processing, setProcessing] = useState(false);
  const nav = useNavigate();

  const getCartData = async () => {
    const token = Cookies.get("token");
    if (token) {
      try {
        setProcessing(true);
        const { data } = await axios.get(`${API_URL}order/getCartOrders`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCartData([...data.cart]);
        setProcessing(false);
        // console.log(data);
      } catch (err) {
        // console.log(err);
        setProcessing(false);
      }
    } else {
      const userId = Cookies.get("userId");
      if (userId) {
        try {
          const { data } = await axios.post(`${API_URL}order/getGuestCart`, {
            id: userId,
          });
          setCartData([...data.cart]);
          // console.log(data);
        } catch (err) {
          // console.log(err);
        }
      }
    }
  };

  useEffect(() => {
    getCartData();
  }, []);


  const deleteItem = async (_id) => {
    const token = Cookies.get("token");
    const authUserId = Cookies.get("authUserId");
    const userId = Cookies.get("userId");
    // console.log(token);
    if (token) {
      try {
        setProcessing(true);
        const { data } = await axios.delete(`${API_URL}order/removeMyItem`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { orderId: _id },
        });
        // console.log(data);
        setProcessing(false);
        getCartData();
      } catch (err) {
        setProcessing(false);
        alert("Can't Delete Network Error");
        // console.log(err);
      }
    } else {
      if (userId) {
        try {
          setProcessing(true);
          const { data } = await axios.delete(
            `${API_URL}order/removeGuestItem`,
            {
              headers: { Authorization: `Bearer ${token}` },
              data: { guestId: userId, orderId: _id },
            }
          );
          // console.log(data);
          setProcessing(false);
          getCartData();
        } catch (err) {
          setProcessing(false);
          alert("Can't Delete Network Error");
          // console.log(err);
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar refresh={processing} />
      <div className=" mt-5 w-[90%] mx-auto">
        <p className=" text-2xl font-semibold">Your Cart</p>

        {cartData.length > 0 ? (
          <div className="overflow-x-auto w-full">
            <table className=" border-gray-400 border-[0.5px]  mt-5 w-full">
              <thead>
                <tr className="bg-gray-50 border-b border-gray-400">
                  <th className="py-3 border-r border-gray-400 w-[200px] px-2">
                    {" "}
                    Item
                  </th>
                  <th className="py-3 border-r border-gray-400 px-2">
                    Product Description
                  </th>
                  <th className="py-3 border-r border-gray-400 px-2">
                    Quantity
                  </th>
                  <th className="py-3 px-2">Total</th>
                </tr>
              </thead>
              <tbody>
                {cartData.map((item, index) => (
                  <tr
                    key={item._id}
                    className="text-xs border-b border-gray-400"
                  >
                    <td className=" border-r border-gray-400">
                      <img
                        src={item.imageUrls[0]}
                        className="w-[70px] h-[70px]  object-contain mx-auto"
                        alt=""
                      />
                    </td>
                    <td className="text-center border-r border-gray-400 py-1 px-2">
                      <div className=" text-[14px] leading-[120%]  ">
                        <p className="text-start font-bold">
                          {item.category === "Patches"
                            ? "Chenille"
                            : item.category}{" "}
                          patches
                        </p>
                        <p className="text-start">
                          Height (in inches): {item.long}, Width (in inches):{" "}
                          {item.wide}
                        </p>
                        {item.category === "Patches" ? (
                          <>
                            <p className="text-start">
                              {item.embroidary
                                ? "With Embroidery"
                                : "No Embroidery"}
                              ,{" "}
                              {item.backing ? "Iron on Backing" : "No Backing"},{" "}
                              {item.borderEdge === 1
                                ? "Single Felt"
                                : item.borderEdge === 2
                                ? "Double Felt"
                                : "Triple Felt"}
                            </p>

                            <p className="text-start">
                              {!item.color ? "1-3 Color(s)" : "4-6 Colors"}
                            </p>
                          </>
                        ) : (
                          <div className="flex items-start gap-2">
                            {item?.other?.map((el, i) => (
                              <p key={i}>{el},</p>
                            ))}
                          </div>
                        )}
                        <p
                          onClick={() => deleteItem(item._id)}
                          className="text-red-700  cursor-pointer text-start  flex flex-col w-[50px]"
                        >
                          {" "}
                          remove
                        </p>
                      </div>
                    </td>
                    <td className="text-center border-r border-gray-400 text-[14px] leading-[120%] px-2">
                      {item.quantity}
                    </td>
                    <td className="font-bold text-xl text-center border-r border-gray-400 px-2">
                      ${item.totalPrice.toFixed(2)}
                    </td>
                  </tr>
                ))}
                {cartData.length > 0 && (
                  <tr className="text-xs border-b border-gray-400 bg-gray-50">
                    <td className="text-center border-r border-gray-400 py-3">
                      <Link
                        to="/select"
                        className="flex items-center justify-center"
                      >
                        <BsFillArrowLeftCircleFill size={20} color="green" />
                        <span className="ml-2 text-[14px] leading-[120%]">
                          Continue Shopping
                        </span>
                      </Link>
                    </td>
                    <td className="text-center border-r border-gray-400 py-3"></td>
                    <td className="text-center border-r border-gray-400 py-3"></td>
                    <td className=""></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex flex-col items-center min-h-[300px]  justify-center">
            <img
              src="https://images.contentstack.io/v3/assets/bltd3ac7773b0da8623/bltc4bbcf587bab8ebb/60c31871f77af428924badac/cart-icon-empty1.svg"
              className="w-[20%] max-w-[150px] mt-12"
              alt="Cart"
            />
            <p className="text-center text-2xl mt-4"> Your Cart is empty.</p>
          </div>
        )}
      </div>
      {cartData.length > 0 && (
        <div className="w-[90%] mx-auto mb-5 mt-5">
          <div className="border-[0.5px] border-gray-400 p-4 min-w-[300px] sm:w-[30%] ml-auto">
            <p className="text-xl font-bold text-right">
              Subtotal Amount: $
              {cartData
                .reduce(function (acc, obj) {
                  return acc + obj?.totalPrice;
                }, 0)
                .toFixed(2)}{" "}
            </p>
            <button
              onClick={() => nav("/checkout")}
              className="mt-5 rounded-md w-full bg-[#1D69D3] py-3  text-white flex items-center justify-center"
            >
              <GoCreditCard size={20} />
              <span className="ml-2 font-bold ">
                Proceed to Secured Checkout
              </span>
            </button>
          </div>
        </div>
      )}

      <Footer />
      {processing && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default MyCart;
