import React, { useEffect, useState } from 'react'
// import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core'; 
import { getUserInfo , postUserInfo, patchUserInfo } from '../../api/form'; 
// import CloseIcon from '@material-ui/icons/Close'; 
import { useForm } from 'react-hook-form';
import { API_URL } from '../../config';
import axios from 'axios';

export default function Popup({onClose,title}) {

  const { register, handleSubmit,setValue, formState: { errors } } = useForm();  
 
const onSubmit = async(data) => {

  const {firstname,lastname,email,contact,note}=data
  let dataToSend = { name:firstname+" "+lastname, email:email, contact: contact, message:note };
    // let userInfo =''
    try {

      const { data } = await axios.post(`${API_URL}contacts`, dataToSend);
      // reset({ name: "", email: "", phone: "", message: "" });
      console.log(data);
    } catch (err) {
      console.log(err);
      if (err.message) {
        alert(err.message);
      }
    }
    // userInfo = await getUserInfo(email);
    // if(userInfo.status === 200) {
    //   userInfo = await patchUserInfo(data);  
    // }
    // else{
    //   userInfo= await postUserInfo(data);
    // } 
 
    
    // localStorage.setItem('email', email);  
    onClose(false)  
     
  };

    const handleClose =(e)=>{
        if(e.target.id=='container'){
            onClose(false)
        }
    }
  return (
    <div id="container" onClick={handleClose} 
    className="fixed z-50 bottom-[4.5rem] md:bottom-20 lg:bottom-24 -right-1 sm:right-1 md:right-4 flex items-center  md:justify-center md:pt-20">
      <div className="inline-flex rounded-t-md absolute -z-0 -top-14 md:top-5 h-24 pt-5 pl-5 text-white bg-blue-500 w-[90%] sm:w-[95%] ml-2 md:ml-0 md:w-full">
        Peri Apparel and Fabrics
      </div> 
      <div className="rounded-lg border-b-0 bg-gray-50 border-2 mx-2 md:mx-0 shadow-lg px-4 w-[90%]  sm:w-full"> 
        <form onSubmit={handleSubmit(onSubmit)} className="relative z-10 rounded-sm bg-white flex flex-col border-2 mx-1 sm:mx-2 md:mx-0 md:p-4 py-5 md:py-8 px-4   sm:w-72 space-y-4">
              <div>
                {/* <label className="text-sm">First Name</label>  */}
                <input name="firstname" className="ring-1 ring-gray-300 focus:ring-2 focus:ring-teal-300 w-full rounded-md px-4 mt-1 py-2 outline-none" placeholder="*First Name"
                   {...register("firstname",
                                    {
                                        required: "First Name is required",
                                        minLength: {
                                            value: 2,
                                            message: "Atleast 2 characters",
                                        }
                                    })}  
                />
                <p className="text-red-500 text-xs md:text-sm mt-1 pl-2">{errors.firstname?.message}</p>
              </div>
              <div>
                {/* <label className="text-sm">Last Name</label>  */}
                <input name="lastname" className="ring-1 ring-gray-300 focus:ring-2 focus:ring-teal-300 w-full rounded-md px-4 mt-1 py-2 outline-none" placeholder="*Last Name"
                   {...register("lastname",
                                    {
                                        required: "Last Name is required",
                                        minLength: {
                                            value: 2,
                                            message: "Atleast 2 characters",
                                        }
                                    })}  
                />
                <p className="text-red-500 text-xs md:text-sm mt-1 pl-2">{errors.lastname?.message}</p>
              </div>
              <div>
                {/* <label className="text-sm ">Email Address</label>  */}
                <input name="email" className="ring-1 ring-gray-300 focus:ring-2 focus:ring-teal-300 w-full rounded-md px-4 mt-1 py-2 outline-none" placeholder="*Email"
                   {...register("email",
                                    {
                                        required: "Email is required",
                                        pattern:{
                                          value:/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
                                           message: "Enter a valid email",
                                        }
                                    })}  
                />
                <p className="text-red-500 text-xs md:text-sm mt-1 pl-2">{errors.email?.message}</p>
              </div>  
              <div>
                {/* <label className="text-sm">Contact</label>  */}
                <input name="contact" className="ring-1 ring-gray-300 focus:ring-2 focus:ring-teal-300 w-full rounded-md px-4 mt-1 py-2 outline-none" placeholder="*Phone Number"
                   {...register("contact",
                                    {
                                        required: "Contact is required",
                                        minLength: {
                                            value: 10,
                                            message: "Atleast 10 characters",
                                        },
                                        maxLength: {
                                            value: 11,
                                            message: "Max 11 characters",
                                        }
                                    })}  
                />
                <p className="text-red-500 text-xs md:text-sm mt-1 pl-2">{errors.contact?.message}</p>
              </div>
               <div>                     
              <textarea name="note" rows="4" className="ring-1 ring-gray-300 focus:ring-2 focus:ring-teal-300 w-full rounded-md px-4 mt-1 py-2 outline-none" placeholder="Note"
                   {...register("note",
                                    {
                                        // required: "NOTE is optional",
                                        minLength: {
                                            value: 10,
                                            message: "Atleast 10 characters",
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: "Max 50 characters",
                                        }
                                    })}  
                />
                <p className="text-red-500 text-xs md:text-sm mt-1 pl-2">{errors.note?.message}</p>
              </div>

              <button type="submit" className="inline-block self-center bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-md px-6 py-2 uppercase text-sm">Submit</button>
            </form>
          </div>
    </div>
  );
}