import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../config";
import { Link } from "react-router-dom";
export default function Quadrants({ handleStep1 }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [bannerImage, setBannerImage] = React.useState([]);

  useEffect(() => {
    const getImage = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(`${API_URL}patch/images`, {
          params: { category: "home", title: "HomePage Category" },
        });
        setBannerImage(data.map((el) => el.url));
      } catch (err) {}
      setLoading(false);
    };
    getImage();
    window.scrollTo(0, 0);
  }, []);

  const patches = [
    {
      title: "Chenille",
      nav: "/chenille-patches",
      src: bannerImage[0],
    },
    {
      title: "Embroidery",
      nav: "/patches?&categories=Embroidery",
      src: bannerImage[1],
    },
    {
      title: "Vegan Leather",
      nav: "/patches?&categories=Vegan%20Leather",
      src: bannerImage[2],
    },
  ];
  const garments = [
    {
      title: "Jackets",
      nav: "/jackets",
      src: bannerImage[3],
    },
    {
      title: "Garments",
      nav: "/garments",
      src: bannerImage[4],
    },
  ];

  return (
    <div className="antialiased w-full h-full text-gray-400 font-sans p-10 pb-5">
      <div className="container px-4 mx-auto">
        <div className="mt-3  flex flex-col sm:items-center sm:justify-center space-y-10">
          <div>
            <Link to="/patches" className="font-bold text-2xl text-black text-center md:text-start hover:underline">Patches</Link>
            {loading ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-evenly gap-10 pt-10">
                <div className="bg-gray-300 animate-pulse h-[450px]"></div>
                <div className="bg-gray-300 animate-pulse h-[450px]"></div>
                <div className="bg-gray-300 animate-pulse h-[450px]"></div>
              </div>
            ) : (
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 justify-evenly gap-5 pt-10 ">
                {patches.map((ele, index) => (
                  <div
                    key={index}
                    className={`w-full min-h-[230px] sm:min-w-[280px] h-full border rounded-lg shadow-md hover:scale-110 transition-all  flex flex-col  items-center cursor-pointer pt-1`}
                    onClick={() => navigate(ele.nav)}
                  >
                    <p className="text-lg lg:text-2xl text-black font-sans text-center">{ele.title}</p>
                    <div className="h-[2px] w-full bg-black"></div>
                    <div className="flex h-full items-center justify-center ">
                      <img
                        src={ele.src}
                        alt="patches"
                        className=" object-contain max-h-[230px] w-full md:w-[280px]"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div>
            <h1 className="font-bold text-2xl text-black text-center md:text-start">
              <Link to="/jackets"  className="hover:underline">Jackets </Link > and <Link to="/garments"  className="hover:underline">Garments</Link>
            </h1>
            {loading ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-evenly gap-10 pt-10">
                <div className="bg-gray-300 animate-pulse h-[450px]"></div>
                <div className="bg-gray-300 animate-pulse h-[450px]"></div>
              </div>
            ) : (
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 justify-evenly gap-5 pt-10 place-content-center">
                {garments.map((ele, index) => (
                  <div
                    key={index}
                    className={`w-full min-h-[230px] sm:min-w-[280px] h-full border rounded-lg shadow-md hover:scale-110 transition-all  flex flex-col  items-center cursor-pointer pt-1 `}
                    onClick={() => navigate(ele.nav)}
                  >
                    <p className="text-lg lg:text-2xl text-black font-sans text-center">{ele.title}</p>
                    <div className="h-[2px] w-full bg-black"></div>
                    <div className="flex h-full items-center justify-center ">
                      <img
                        src={ele.src}
                        alt="patches"
                        className=" object-contain  max-h-[200px]  w-full md:w-[280px]"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
