import React from "react";
import WovenFinal from "./WovenFinal";

const Woven = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row mx-5  mb-0 md:mx-20 md:my-0 ">
        <div className="flex flex-col w-2/5 my-10 md:w-2/6  ">
          <span className="mx-auto my-auto font-sans text-xl font-semibold uppercase md:text-2xl lg:text-3xl xl:text-4xl">
            Step-2
          </span>
          <div className="mx-auto my-auto text-xs text-gray-500 capitalize md:text-sm w-3/2 lg:text-lg ">
            Enter Your Information
          </div>
        </div>
        <div className="flex flex-col w-3/5  mx-auto mt-5 md:w-1/2 ">
          <span className="my-4 ml-5 text-lg font-bold uppercase w-10/11 md:ml-0 md:text-2xl">
            Woven Patch Order Inquiry
          </span>
        </div>
      </div>
      <WovenFinal />
      <div className="flex w-full h-32 mt-5 text-justify mb-44 md:my-5 md:text-sm md:px-10 max-w-5xl mx-auto">
        By signing up, I confirm that I have read and agreed to Periapparel's
        User Agreement & Terms of Service. I also agree to signing up via text,
        you consent to receive marketing text messages from Periapperal at the
        number provided, including messages sent by auto dialer. Consent is not
        a condition of any purchase. You can unsubscribe at any time by replying
        STOP
      </div>
    </div>
  );
};

export default Woven;
