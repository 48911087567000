import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import { FiEdit } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../config";
import Cookies from "js-cookie";
import { Spin, message } from "antd";
import ReCaptcha from "../Recaptcha";
import { MdWarning } from "react-icons/md";

const Registeration = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPssword, setConfirmPassword] = useState("");
  const [agreeTC, setAgreeTC] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [isRegistered, setIsRegistered] = useState(false);
  const [isVerified, setIsVarified] = useState(false);
  const [msg, setMsg] = useState("");
  useEffect(()=>{
    const to = Cookies.get('token');
    if(to){
     navigate('/') 
    }
  },[])

  const signUpHandler = async (e) => {
    e.preventDefault();
    setMsg('')
    if (isVerified) {
      const regx =
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;

      const passwordValidation = regx.test(password);
      if (password === confirmPssword) {
        if (!passwordValidation) {
          message.warning("Password should match given format");

          return;
        }
        setIsProcessing(true);
        try {
          const { data } = await axios.post(API_URL + "auth/signup", {
            firstname: firstName,
            lastname: lastName,
            email,
            password,
          });
          setIsProcessing(false);
          if (data?.message === "Signup Success") {
            messageApi.open({
              type: "success",
              content: "Verification email sent to your given email Id.",
              duration: 5,
            });
            setIsRegistered(true);
            // Cookies.set("email", email, { expires: 1 / 24 });
            cleanUp();
          }
          // console.log(data);
        } catch (err) {
          // console.log(err);
          if (err?.response?.data?.message === "Database Error") {
            setMsg("Already registerd with this email. Use another Email.");
            // message.error(
            //   "Already registerd with this email. Use another Email."
            // );
          } else {
            message.error(
              err?.response?.data?.message
                ? err?.response?.data?.message
                : "can't Register "
            );
          }
          setIsProcessing(false);
          cleanUp();
        }
      } else {
        message.error("password and confirm password not matched. ");
      }
    } else {
    }
  };

  const cleanUp = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setAgreeTC(false);
  };
  // console.log(agreeTC);
  return (
    <>
      {contextHolder}
      <Navbar />
      <div className="min-h-[calc(100vh-56px)] sm:min-h-[calc(100vh-96px)] ">
        <form
          onSubmit={signUpHandler}
          autoComplete="off"
          className=" py-4  sm:px-20   w-[90%] sm:w-[90%] md:w-[80%] lg:w-[57%] max-w-[764px] mx-auto"
        >
          <div className="flex items-center ">
            <FiEdit color="#383838" />
            <p className="ml-3 text-2xl text-[#383838]">{isRegistered?"Account Created":"Create Account"}</p>
          </div>
          {isRegistered ? (
            <>
              <p className=" font-bold text-[#388446] mt-12">
                Please follow the link sent to your email address to activate
                your account.
              </p>
              <Link to="/" className="text-[#383838]  ">
                <button className="block m-auto mt-12 border hover:text-black hover:border-black border-[#383838] px-4 py-2 rounded-md">
                  Go to Homepage
                </button>
              </Link>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-y-4 sm:flex-row justify-between items-center mt-8">
                <input
                  type="text"
                  style={{ border: "1px solid #706a6a" }}
                  className=" border border-[#706a6a] px-3 py-3 w-full sm:w-[48%]  rounded-md text-xl outline-none"
                  placeholder="First Name"
                  value={firstName}
                  autoComplete="off"
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  style={{ border: "1px solid #706a6a" }}
                  className=" border border-[#706a6a] px-3 py-3 w-full sm:w-[48%]  rounded-md text-xl outline-none"
                  placeholder="Last Name"
                  value={lastName}
                  autoComplete="off"
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>

              <input
                type="email"
                className=" border border-[#706a6a] px-3 py-3 w-full mt-4 rounded-md text-xl outline-none"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
                required
              />

              <input
                type="password"
                className=" border border-[#706a6a] px-3 py-3 w-full mt-4 rounded-md text-xl outline-none"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
                required
              />

              <input
                type="password"
                className=" border border-[#706a6a] px-3 py-3 w-full mt-4 rounded-md text-xl outline-none"
                placeholder="Confirm Password"
                value={confirmPssword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                autoComplete="off"
              />
              <p className="mt-4 text-xl">
                Password must include the following:
              </p>
              <ul className="list-disc ml-5 mt-2 text-[16px]">
                <li className="text-justify">Atleast 8 characters length</li>
                <li className="text-justify">
                  Include at least one lowercase (a-z) and one uppercase letter
                  (A-Z)
                </li>
                <li className="text-justify">
                  Include at least one special character(e.g. !@#$&)
                </li>
                <li className="text-justify">
                  {
                    "Does not contain blank spaces or the following special characters: < > ,"
                  }
                </li>
                <li className="text-justify">
                  Include at least one digit (0-9)
                </li>
              </ul>
              <div className=" mt-4 flex">
                <input
                  className="cursor-pointer h-6 w-6 inline-block mt-1 outline-none"
                  type="checkbox"
                  required
                  onChange={(e) => {
                    setAgreeTC(e.target.checked);
                  }}
                  value={agreeTC}
                  checked={agreeTC}
                />
                <label className="ml-3 self-start  leading-[120%] text-xl">
                  <Link to="/terms-and-conditions">
                    By Creating an account, you agree to our
                    <span className="underline text-blue-500 cursor-pointer">
                      {" "}
                      Terms & Conditions and Privacy Policy
                    </span>
                  </Link>
                </label>
              </div>
              <div className="mt-4">
                <ReCaptcha
                  OnClick={(isvarify) => setIsVarified(isvarify)}
                  reset={isProcessing}
                />
              </div>
              {msg && (
                <>
                  <div className="mt-4 border border-[#D70F22] text-justify flex items-center mb-4">
                    <div className=" bg-red-200 p-4 self-stretch items-stretch">
                      <MdWarning size={50} color="#D70F22" />
                    </div>
                    <p className="text-[14px] p-4">
                      An account with this email already exists, please{" "}
                      <Link to="/login" className="text-blue-600 hover:text-blue-700">Sign In</Link> , try another email or
                      Reset Your Password.
                    </p>
                  </div>
                </>
              )}

              <button
                disabled={!agreeTC || isProcessing || !isVerified}
                type="submit"
                className={`mt-6 bg-blue-600  text-white text-xl font-semiblod px-8 py-3 rounded-md hover:bg-blue-700 transition-all ease-out duration-100 block mx-auto  disabled:bg-blue-400`}
              >
                Create Account
              </button>

              <p className="text-center text-xl mt-3">
                Already have an account?
                <Link to="/login">
                  <span className="text-blue-500 cursor-pointer fontt-bold">
                    {" "}
                    Sign In
                  </span>
                </Link>
              </p>
            </>
          )}
        </form>
      </div>
      <Footer />
      {isProcessing && (
        <div className="fixed top-0 left-0 right-0 bottom-0  flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default Registeration;
