/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { AiFillCaretLeft } from "react-icons/ai";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import FormatArtWork from "./FormatArtWork";
import Summary from "./Summary";
import SelectProductSize from "./SelectProductSize";
import SelectQuantity from "./SelectQuantity";
import UploadImage from "./UploadImage";
import Instructions from "./Instructions";
import { convertText } from "../../../../utils/tools";

const UploadArtwork = ({ patchPriceFormat }) => {
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();
  const product = useSelector((state) => state.productSummary);
  const [viewImage, setViewImage] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [images, setImage] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (e) => {
    e.preventDefault();
    navigate(`/patch/${id}?page=customize`);
  };
  React.useEffect(()=>{
    window.scrollTo(0, 0);
  },[])


  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-10">
      <div className="flex flex-col gap-6 px-4 mt-10 lg:flex-row">
        <div className="flex flex-col w-full h-fit">
          <div className="font-sans text-2xl font-semibold uppercase lg:text-3xl xl:text-4xl">
            Step-1 {`(${convertText(id)} patch)`}
          </div>

          <div className="my-1 text-sm font-medium text-gray-500 uppercase md:pl-0 lg:text-lg">
            SELECT SIZE, QUANTITY AND UPLOAD ARTWORK.
          </div>
          <div className="">
            Upload the artwork and select the active area for the uploaded
            artwork image for the selected patch size. The selected patch size
            should contain the artwork within it's frame as shown the preview.
          </div>
          {product.editImage === null && (
            <SelectProductSize patchPriceFormat={patchPriceFormat} />
          )}

          <SelectQuantity patchPriceFormat={patchPriceFormat} />
          {product.quantity.size !== 0 && (
            <UploadImage setImageUrl={setImageUrl} />
          )}
        </div>

        <Summary />
      </div>

      {product.originalImage && (
        <FormatArtWork
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          setImage={setImage}
          setViewImage={setViewImage}
          uploadFiles={uploadFiles[0]}
          patchPriceFormat={patchPriceFormat}
        />
      )}
      {/* <Instructions /> */}
      <Instructions />
      <div className="flex flex-col items-center justify-center mt-10 mb-20 sm:flex-row gap-y-5">
        {/* <Link to={`/patch/${id}?page=info`}>
          <button
            className="flex items-center px-12 text-2xl text-white bg-green-700 rounded-full hover:bg-green-600 w-60 h-14"
            type="button"
          >
            <AiFillCaretLeft className="mr-2" />
            <span>Previous</span>
          </button>
        </Link> */}
        <button
          className="px-12 ml-4 text-2xl text-white rounded-full bg-sky-700 hover:bg-sky-600 h-14 w-60"
          type="submit"
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default UploadArtwork;
