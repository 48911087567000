/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import productSummary from "../../../../actions/productSummary";
import { RiDeleteBinLine } from "react-icons/ri";
import { useEffect } from "react";

const UploadImage = ({ setImageUrl }) => {
  const inputRef = React.useRef(null);
  const [dragActive, setDragActive] = React.useState(false);
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else {
      setDragActive(false);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // console.log(e.target.files);
    }
  };
  const onButtonClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };
  const handelUploadImage = (e) => {
    const { files } = e.target;

    if (files.length !== 0) {
      dispatch(
        productSummary({
          ...product,
          originalImage: files[0],
          editImage: files[0],
        })
      );
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      setImageUrl(e.target.result);
    };

    reader.readAsDataURL(files[0]);
  };

  useEffect(() => {
    if (product.editImage !== null) {
      const f = () => {
        const reader = new FileReader();

        reader.onload = (e) => {
          setImageUrl(e.target.result);
        };

        reader.readAsDataURL(product.originalImage);
      };
      f();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col mt-6">
      <div className="text-sm font-medium text-gray-500 uppercase md:pl-0 lg:text-lg">
        Upload artwork
      </div>
      <div className="flex flex-col ">
        <div className="relative">
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            onChange={handelUploadImage}
            required={product.originalImage !== null ? false : true}
            style={{
              position: "absolute",
              width: "1px",
              height: "1px",
              opacity: 0,
              top: "100%",
              left: "12%",
            }}
            accept="image/png, image/jpeg,image/jpg,image/ai,image/eps,image/psd,image/tif,image/tiff,image/pdf"
          />
          <button
            onClick={onButtonClick}
            className="w-[274px] self-start  hover:bg-blue-700 bg-blue-500 py-1 text-white transition-all duration-300 ease-in-out px-5  text-xl  rounded-md whitespace-nowrap mt-3"
          >
            Browse Files
          </button>
        </div>
        <label htmlFor="input-file-upload " className="">
          <div className="w-full">
            <p className="w-full text-sm">
              Allowed extensions *.png *.jpg *.jpeg *.webp *.svg
            </p>
            <p className="w-full text-sm">Max file size 25 MB</p>
          </div>
        </label>
        {dragActive && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              borderRadius: "1rem",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
      {/* {product.editImage && ( */}
      {/*   <p className="font-semibold text-green-600">{product.editImage.name}</p> */}
      {/* )} */}
      {product.editImage && (
        <div className="flex items-center justify-start w-full max-w-sm mt-2 border border-black">
          <div className="flex flex-col items-center gap-2 p-3">
            <RiDeleteBinLine
              cursor="pointer"
              size={25}
              onClick={() => {
                inputRef.current.value = null
                dispatch(
                  productSummary({
                    ...product,
                    editImage: null,
                    originalImage: null,
                  })
                );
              }}
            />
            <p className="text-red-600 text-sm font-bold">Delete</p>
          </div>
          <div className="w-[1px] h-20 bg-black"></div>
          <div className="flex items-center justify-between gap-2 p-3">
            {/* <img
              src={URL.createObjectURL(product.editImage)}
              className="object-contain w-14 h-14"
              alt=""
            /> */}

            <p className="font-semibold text-green-600">
              {product.editImage.name}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
