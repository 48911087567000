import React, { useEffect, useState } from 'react';
import './UpgradePage.css';
import { useNavigate } from "react-router-dom";
import Navbar from '../../components/navbar/Navbar';
import Progressbar from '../../components/Progressbar/Progressbar';
import dog from '../../components/images/dog.jpg';
import Front from '../../components/images/Front.png';
import Jackets from '../../components/images/Jackets.png';
import Footer from '../../components/footer/Footer';
import { useSelector, useDispatch } from 'react-redux';
import activeStep from '../../actions/activeStep';
import productSummary from '../../actions/productSummary';

export default function UpgradePage() {
  const active = useSelector((state) => state.activeStep);
  useEffect(() => {
    console.log("active===>",active)
  //  if(active!==4){
  //  navigate('/select')
  //   }
 }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  
  const [pricePerPatch, setPricePerPatch] = useState(product.pricePerPatch);
  const [quantityPrice, setQuantityPrice] = useState(product.quantity.price);
  const [finalPrice, setFinalPrice] = useState(product.finalPrice);
  const [embroidary, setEmbroidary] = useState({
    label: '',
    price: 0,
    show: 0,
  });
  const [backing, setBacking] = useState({
    label: '',
    price: 0,
    show: 0,
  });
  const [border, setBorder] = useState({
    label: '',
    price: 0,
    show: 0,
  });
  const [color, setColor] = useState({
    label: '',
    price: 0,
    show: 0,
  });

  const handleEmbroidary = (e) => {
    let price = 0;
    const factor=product.quantity.roundedQuantity/25;
    if (e.target.value === 'With Embroidery') {
      price = factor*5;
      // show=
    } 
    setFinalPrice(finalPrice - embroidary.price +  Math.round(((price) + Number.EPSILON) * 100) / 100);
    
    setEmbroidary({
      ...embroidary,
      label: e.target.value,
      price,
    });
  };
  
  const handleBacking = (e) => {
    let price = 0;
    let show=0;
    const factor=product.quantity.size;
    if (e.target.value === 'Iron on Backing') {
      price = factor*0.25;
      show=0.25;
      
    }
    setPricePerPatch(pre=>pre+ show - backing.show)
    setFinalPrice(finalPrice - backing.price +  Math.round(((price) + Number.EPSILON) * 100) / 100);
     
    setBacking({
      ...backing,
      label: e.target.value,
      show,
      price,
    });
  };
  
  const handleBorder = (e) => {
    let price = 0;
    const factor=product.quantity.size;
    let show=0
    if (e.target.value === 'Single Felt') {
      price = 0;
    } else  if (e.target.value === 'Double Felt') {
      price = factor*0.20;
      show=0.20
    } else {
      price = factor*0.40;
      show=0.40
    }
    console.log(pricePerPatch);
    setFinalPrice(finalPrice - border.price +  Math.round(((price) + Number.EPSILON) * 100) / 100); 
    setPricePerPatch(pre=>pre+ show - border.show)
    
    setBorder({
      ...border,
      label: e.target.value,
      price,
      show,
    });
  };

  const handleColor = (e) => {
    let price = 0;
    const factor=product.quantity.size;
    let show=0
    if (e.target.value === '4-6 Colors') {
      price = factor*0.50;
      show=0.50
    }  
    
    setFinalPrice(finalPrice - color.price +  Math.round(((price) + Number.EPSILON) * 100) / 100); 
    setPricePerPatch(pre=>pre+ show - color.show)
    
    setColor({
      ...color,
      label: e.target.value,
      price,
      show,
    });
  };

  const handleStep4 = (e) => {
    e.preventDefault();
    dispatch(
      productSummary({ ...product,finalPrice , embroidary, backing, border,pricePerPatch,color })
    ); 
    dispatch(activeStep(5));
    navigate('/upload')
  };

  return (
    <div className="bg-brand text-black">
      {/*-----navbar-----*/}
      <div className="container h-40 bg-white  lg:mx-auto lg:px-2 px-1">
        <Navbar />
      </div>
      <div className="flex flex-col" id="steps_container">
        <div className=" flex flex-row xl:mx-40">
          <Progressbar />
        </div>
      </div>

      {/*----Step 4----*/}
      <form onSubmit={handleStep4}>
        <div className="grid lg:grid-cols-2 grid-col-1 md:gap-14 lg:gap-20 lg:place-content-around mt-12">
          <div className="ml-2 sm:ml-12 md:ml-24 md:pl-1">
            <div className="mt-12 pl-12 md:pl-20 font-sans font-semibold text-2xl md:text-3xl xl:text-4xl  uppercase">
              Step-4
            </div>
            <div className="text-sm sm:text-lg lg:text-lg pl-14 md:pl-20 lg:pl-24 my-4 text-gray-500 font-medium ">
              Customize
            </div>
            <div className="font-normal text-sm sm:text-base tracking-tight mt-4 pl-2 pr-10 sm:pl-4 w-[95%] sm:w-[23rem] text-justify">
              To get your order started, fill in the options that best fit your
              needs. As you make customization selections the Order Summary on
              the right will update and reflect each choice - any upgrades that
              add additional cost to your order will also be reflected.
            </div>
            <div className=" bg-brand-icon h-[.3rem] my-14 w-[95%] md:w-3/4 lg:w-full"></div>
            <div className="font-bold text-gray-700 uppercase tracking-[0.25em] pl-4 sm:pl-8 md:pl-0">
              Select Embroidery
            </div>
            <div
              className="mt-8 ml-2 grid md:grid-cols-2 grid-col-1 gap-8 w-10/12 uppercase tracking-[0.25em] pl-4 sm:pl-8 md:pl-0"
              onChange={handleEmbroidary}
            >
              <div className="">
                <div htmlFor="embroidary" className="w-max">
                  No Embroidery
                </div>
                  <input
                    required
                    type="radio"
                    name="embroidary"
                    value="No Embroidary"
                    className="ml-16 mt-4"
                  />
              </div>
              <div>
                <div htmlFor="embroidary" className="w-max">With Embroidery</div>
                <input
                  required
                  type="radio"
                  name="embroidary"
                  value="With Embroidery"
                  className="ml-16 mt-4"
                />
              </div>
            </div>
            <div className="font-bold mt-24 md:mt-28 text-gray-700 uppercase tracking-[0.25em] pl-4 sm:pl-8 md:pl-0">
              Select Backing
            </div>
            <div
              className="mt-8 ml-2 grid md:grid-cols-2 grid-col-1 gap-8 w-10/12 uppercase tracking-[0.25em] pl-4 sm:pl-8 md:pl-0"
              onChange={handleBacking}
            >
              <div className="">
                <div className="w-max">No Backing</div>
                <input
                  required
                  type="radio"
                  name="backing"
                  value="No Backing"
                  className="ml-16 mt-4"
                />
              </div>
              <div>
                <div className="w-max">Iron on Backing</div>
                <input
                  required
                  type="radio"
                  name="backing"
                  value="Iron on Backing"
                  className="ml-16 mt-4"
                />
              </div>
            </div>
            <div className="font-bold mt-24 md:mt-28 text-gray-700 uppercase tracking-[0.25em] pl-4 sm:pl-8 md:pl-0">
              Select Border & Edge
            </div>
            <div
              className="mt-8 ml-2 grid md:grid-cols-3 grid-col-1 gap-8 w-10/12 uppercase tracking-[0.25em] pl-4 sm:pl-8 md:pl-0"
              onChange={handleBorder}
            >
              <div className="">
                <div className="w-max">Single Felt</div>
                <input
                  required
                  type="radio"
                  name="border&edge"
                  value="Single Felt"
                  className="ml-16 mt-4"
                />
              </div>
              <div>
                <div className="w-max">Double Felt</div>
                <input
                  required
                  type="radio"
                  name="border&edge"
                  value="Double Felt"
                  className="ml-16 mt-4"
                />
              </div>
              <div>
                <div className="w-max">Triple Felt</div>
                <input
                  required
                  type="radio"
                  name="border&edge"
                  value="Triple Felt"
                  className="ml-16 mt-4"
                />
              </div>
            </div>
            <div className="font-bold mt-24 md:mt-28 text-gray-700 uppercase tracking-[0.25em] pl-4 sm:pl-8 md:pl-0">
              Colours Selection
            </div>
            <div
              className="mt-8 ml-2 grid md:grid-cols-3 grid-col-1 gap-8 w-10/12 uppercase tracking-[0.25em] pl-4 sm:pl-8 md:pl-0"
              onChange={handleColor}
            >
              <div className="">
                <div className="w-max">1-3 Color(s)</div>
                <input
                  required
                  type="radio"
                  name="color"
                  value="1-3 Colors"
                  className="ml-16 mt-4"
                />
              </div> 
              <div className="">
                <div className="w-max">4-6 Colors</div>
                <input
                  required
                  type="radio"
                  name="color"
                  value="4-6 Colors"
                  className="ml-16 mt-4"
                />
              </div> 
            </div>
            {/* <div className="">
              Expedited Order
            </div> */}
          </div>
          {/* card */}
          <div className="mt-20 md:mt-6 bg-gray-200 h- mx-auto h-fit pb-20  lg:w-[80%] md:w-8/12 w-5/6  rounded-[3rem] uppercase">
            {/* <img
              className="h-44 w-44 mt-20 mx-auto rounded-3xl"
              src={product.img}
            ></img> */}
            <div className="mt-8 font-semibold tracking-[.3rem] text-xl text-center">
              Order summary
            </div>
            <div className="mt-8 grid grid-cols-2 tracking-[.02rem]  sm:tracking-[.1rem] justify-around w-full px-4 sm:px-16">
              {/* <span className="justify-self-start ">Product</span>
              <span className="justify-self-end sm:justify-self-end sm:pr-8 text-brand-planetarium cursor-pointer">
                Edit
              </span>
              <span className="mt-4  w-max">Category - {product.category}</span>
              <span className="justify-self-end sm:justify-self-end sm:pr-8 text-brand-planetarium mt-2 cursor-pointer">
                Edit
              </span> */}
            </div>
            <div className="pl-4 sm:pl-16 my-4 tracking-[.02rem]  sm:tracking-[.1rem]">
              Product - {product.name}
            </div>
            <div className="ml-4 sm:ml-16 mt-4 bg-brand-icon  h-[.3rem] w-11/12 sm:w-9/12"></div>
            {/* <div className="pl-4 sm:pl-16 mt-8">
              Embroidered {product.category} Customization
            </div> */}
            {/* <div className="pl-4 sm:pl-16 mt-4">75 %</div> */}
           <div className="pl-8 sm:pl-20 mt-4 grid grid-cols-2">
              <span className="normal-case">  
              Custom Size {product.long}in X {product.wide}in
              </span>
              <span className="justify-self-center">${product.customPrice.toFixed(2)}</span>
            </div>
            
            {backing.label && (
              <div className="pl-8 sm:pl-20 mt-2 grid grid-cols-2 normal-case">
                <span className="w-max">Iron on Backing</span>
                <span className="justify-self-center">${backing.show.toFixed(2)}</span>
              </div>
            )}
            {border.label && (
              <div className="pl-8 sm:pl-20 mt-2 grid grid-cols-2 normal-case">
                <span className="w-max">{border.label}</span>
                <span className="justify-self-center">${border.show.toFixed(2)}</span>
              </div>
            )}
            {color.label && (
              <div className="pl-8 sm:pl-20 mt-2 grid grid-cols-2 normal-case">
                <span className="w-max">Color</span>
                <span className="justify-self-center">${color.show.toFixed(2)}</span>
              </div>
            )}
            <div className="pl-8 sm:pl-20 font-bold mt-2 grid grid-cols-2">
              <span className=" normal-case">  
              Price per patch 
              </span>
              <span className="justify-self-center">${pricePerPatch.toFixed(2)}</span>
            </div>
            <div className="pl-8 sm:pl-20 mt-8 grid grid-cols-2">
              <span className="normal-case"> 
                Price for QTY {product.quantity.size} 
              </span>
              <span className="justify-self-center">${(product.quantity.size*pricePerPatch).toFixed(2)}</span>
            </div>
            {embroidary.label && (
              <div className="pl-8 sm:pl-20 mt-2 grid grid-cols-2 normal-case">
                <span className="w-max">Embroidery</span>
                
                <span className="justify-self-center">${embroidary.price.toFixed(2)}</span>
              </div>
            )}
            

             <div className="pl-8 sm:pl-20 font-bold mt-6 grid grid-cols-2">
              <span className=" normal-case">  
              Subtotal
              </span>
              <span className="justify-self-center">${((product.quantity.size*pricePerPatch)+embroidary.price).toFixed(2)}</span>
            </div>
            <div className="ml-4 sm:ml-16 mt-4 bg-brand-icon  h-[.3rem] w-11/12 sm:w-9/12"></div>
            <div className="pl-6 sm:pl-16 mt-10 grid grid-cols-2 font-bold text-xl">
              <span className="tracking-[.3rem] w-max">Total</span>
              <span className="justify-self-center ">${finalPrice.toFixed(2)}</span>
            </div>
          </div>
        </div>
        <div className="grid justify-center">
        <button
          className=" bg-sky-700 hover:bg-sky-600 text-white text-2xl w-80  mt-16 mb-20 rounded-full h-14"
          type="submit"
        >
          Continue
        </button>
        </div>
      </form>
      <Footer />
    </div>
  );
}
