import React, { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import axios from 'axios';
import { API_URL } from '../config';

const contentStyle = {
  color: '#fff',
  textAlign: 'center',
  maxWidth: "1300px",
  // maxHeight:"calc(100vh - 160px)",
  padding: "0 ",
  margin: "0 auto",
  height: "auto"
};

const MyCarousal = () => {
  const [data, setData] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [staticImg, setStaticImg] = useState("")

  let category = "home"
  useEffect(() => {

    const getData = async () => {
      try {
        // SetLoading(true)
        const { data } = await axios.get(`${API_URL}patch/images`, {
          params: { category: category, title: "HomeBanner" }
        })
        const { data: stImg } = await axios.get(`${API_URL}patch/images`, {
          params: { category: category, title: "HomeBannerStatic" }
        })
        setStaticImg(stImg[0].url)

        setData([...data]);
        // console.log(data);
        SetLoading(false);

      } catch (err) {
        SetLoading(false);
      }

    }

    getData();

  }, [category])

  // console.log(data);
  return (
    <>
      <div className='hidden sm:block  max-w-screen-xl mx-auto px-4 '>
        {

          <Carousel autoplay className={` mt-5 md:min-h-[350px] lg:min-h-[400px] xl:min-h-[500px]`}>
            {loading &&
              <div className='skeleton md:min-h-[350px] lg:min-h-[400px] xl:min-h-[500px]'>

              </div>}
            {data?.map((image, _index) => (<div key={image?._id} className="">
              <img
                style={contentStyle}
                src={image?.url}
                alt=""
                className='  w-full  object-contain '
              />
              {/* <h3 style={contentStyle}>1</h3> */}
            </div>))}

          </Carousel>
        }
      </div>
      <div className='sm:hidden w-full p-2 flex items-center justify-center'>
        <img
          src={staticImg}
          alt="StaticImg"
          className='h-[300px] object-cover'
        />
      </div>
    </>
  )
};
export default MyCarousal;
