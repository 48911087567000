import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import axios from "axios";
import { API_URL } from "../../config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { Spin } from "antd";



const Index = () => {
  const [userDetails, setUserDetails] = useState({});
  const nav = useNavigate();
  const [showMessage, setShowMessagge] = useState(false);
  const [text, setText] = useState("");
  const [updateValue, setUpdateValue] = useState("");
  const [processing, setProcessing] = useState(false);

  useEffect(()=>{
   const token = Cookies.get('token');
   if(!token){
    nav('/')
   }
  },[])
  
  useEffect(() => {
    (async () => {
      const token = Cookies.get("token");
    
      // console.log("token=======>", token);
      try {
        setProcessing(true);
        if (token) {
          const { data } = await axios.get(`${API_URL}auth/myself`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          setUserDetails({ ...data?.userDetails });
        }
        setProcessing(false);
      } catch (err) {
        setProcessing(false);
        console.log(err);
      }
      setProcessing(false);
    })();
  }, []);

  const updateHandler = () => {
    (async () => {
      setProcessing(true);
      try {
        let passData = {
          ...(updateValue === "firstname" && { firstname: text }),
          ...(updateValue === "lastname" && { lastname: text }),
        };
        const token = Cookies.get("token");
        const { data } = await axios.patch(`${API_URL}auth/myself`, passData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (data.message === "Updated!") {
          setUserDetails({
            ...userDetails,
            ...(updateValue === "firstname" && { firstname: text }),
            ...(updateValue === "lastname" && { lastname: text }),
          });
          setShowMessagge(false);
        }
        setProcessing(false);

        // console.log(data);
      } catch (err) {
        console.log(err);
        setProcessing(false);
      }
      setProcessing(false);
    })();
  };

  // console.log(userDetails);
  return (
    <>
      <Navbar />
      <div className="w-[90%] mx-auto  min-h-[calc(100vh-100px)]">
        <h2 className="text-center font-bold text-3xl mt-10 ">My Profile </h2>
        <div className="max-w-[500px] mx-auto mt-5">
          <div className=" mx-auto flex border border-[rgba(0, 0, 0, 0.788)] justify-between p-5">
            <div className="">
              <p className="font-bold ">First Name</p>
              <p className="">{userDetails.firstname}</p>
            </div>
            <button
              onClick={() => {
                setShowMessagge(true);
                setUpdateValue("firstname");
                setText(userDetails?.firstname);
              }}
              className="self-center border border-black rounded-sm px-8 py-1"
            >
              {" "}
              Edit
            </button>
          </div>

          <div className=" flex border border-[rgba(0, 0, 0, 0.788)] justify-between p-5">
            <div className="">
              <p className="font-bold ">Last Name</p>
              <p className="">{userDetails?.lastname}</p>
            </div>
            <button
              onClick={() => {
                setShowMessagge(true);
                setUpdateValue("lastname");
                setText(userDetails?.lastname);
              }}
              className="border rounded-sm px-8 py-1 self-center border-black"
            >
              {" "}
              Edit
            </button>
          </div>

          <div className=" flex border border-[rgba(0, 0, 0, 0.788)] justify-between p-5">
            <div className="">
              <p className="font-bold ">Email</p>
              <p className="">{userDetails?.email}</p>
            </div>
            {/* <button className="border border-black rounded-sm px-8 py-1 self-center">
              {" "}
              Edit
            </button> */}
          </div>

          <div className=" flex border border-[rgba(0, 0, 0, 0.788)] justify-between p-5">
            <div className="">
              <p className="font-bold ">Password</p>
              <p className="">*********** </p>
            </div>
            {/* <button className="border border-black rounded-sm px-8 py-1 self-center">
              {" "}
              Edit
            </button> */}
          </div>

          <div className=" flex border border-[rgba(0, 0, 0, 0.788)] justify-between p-5">
            <div className="">
              <p className="font-bold ">Address</p>
              <p className="">{userDetails.billId}</p>
            </div>
            <button
              onClick={() => nav("/addaddress")}
              className="border border-black rounded-sm px-8 py-1 self-center"
            >
              {" "}
              Edit
            </button>
          </div>
        </div>
      </div>

      <Footer />

      {showMessage && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#312c2ca4] flex items-center justify-center">
          <div className="p-5 w-[90%] md:w-[400px]  bg-white shadow rounded-lg">
            <div className="">
              <AiOutlineClose
                cursor={"pointer"}
                onClick={() => setShowMessagge(false)}
                size={25}
                className="font-bold ml-auto "
              />
            </div>

            <p className="font-bold">
              {updateValue === "firstname" && "First Name"}{" "}
              {updateValue === "lastname" && "Last Name"}
            </p>
            <input
              style={{ border: "1px solid #312c2ca4" }}
              type="text"
              placeholder={
                updateValue === "firstname" ? "First Name" : "Last Name"
              }
              className=" mt-2 border border-black rounded-sm p-2 w-full"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <div className="flex items-center justify-center gap-x-4 mt-5">
              <button
                disabled={processing}
                onClick={() => updateHandler()}
                className="disabled:bg-green-400 px-8 py-2 bg-green-500 text-white rounded-sm "
              >
                Save
              </button>
              <button
                onClick={() => setShowMessagge(false)}
                className=" px-8 py-2 bg-blue-500 text-white rounded-sm "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {processing && (
        <div className="fixed top-0 left-0 right-0 bottom-0  flex items-center justify-center z-[99999]">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default Index;
