import React from "react";
import { API_URL } from "../../config";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const PatchDetail = () => {
  const [bannerImage, setBannerImage] = React.useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categories = searchParams.getAll("categories");

  const assignSelected = categories[0]?.toLowerCase().replaceAll(" ", "-");
  const [selected, setSelected] = React.useState(assignSelected || "");

  const navigate = useNavigate();
  React.useEffect(() => {
    const getImage = async () => {
      try {
        const { data } = await axios.get(`${API_URL}patch/images`, {
          params: { category: "home", title: "Patch Banner" },
        });

        setBannerImage(data[0]?.url);
      } catch (err) {}
    };
    getImage();
  }, []);

  const handleClick = () => {
    if (selected === "woven") {
      navigate(`/patch/${selected}`);
    } else {
      navigate(`/patch/${selected}?page=upload`);
    }
  };

  return (
    <div className="flex flex-col gap-3 w-full ">
      <div className="bg-gray-500 flex flex-col h-[350px] relative justify-center items-center">
        <img
          src={bannerImage}
          alt="Patches"
          className="w-full h-full object-cover"
        />
        <h1 className="absolute inset-0 font-bold text-white text-6xl flex justify-center items-center">
          Patches
        </h1>
      </div>
      <div className="flex flex-col justify-center font-sans mt-10 text-lg w-[90%] sm:w-[75%] leading-8 text-center mx-auto ">
        <p className="font-bold">
          Custom chenille, embroidery and vegan leather patches.
        </p>
        <p className="mt-2">
          Our customer-centric website along with our transparent pricing and
          seamless patch order system, provides easy customization for all patch
          orders.
        </p>
      </div>
      <div className="flex flex-col items-center justify-center py-2 mt-5 gap-3 flex-wrap">
        <p className="mt-2 font-bold">Get started with your order.</p>
        <select
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
          className="px-4 rounded-md border border-black/40 w-60 h-12"
        >
          <option value="" selected hidden disabled>
          Select patch type
          </option>
          <option value="chenille">Chenille</option>
          <option value="embroidery">Embroidery</option>
          <option value="woven">Woven(Coming Soon)</option>
          <option value="vegan-leather">Vegan Leather</option>
        </select>
        <button
          className=" w-60 hover:bg-blue-800 transition-all ease-linear duration-300 rounded-md px-12 py-2 font-semibold bg-blue-700 text-white text-[22px] disabled:bg-blue-300"
          onClick={handleClick}
          disabled={selected.length === 0 && true}
        >
          {selected === "woven" ? "Inquire Order" : "Start Order"}
        </button>
      </div>
    </div>
  );
};

export default PatchDetail;
