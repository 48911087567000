import React from "react";
import { useSelector, useDispatch } from "react-redux";
import productSummary from "../../../../actions/productSummary";
import { quantityPrice } from "../../../utils";
// import { quantityPriceEmbroidery } from "../../../utils";

const Selection = () => {
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col gap-16 mt-8">
      {/* ..................Embroidery.............................. */}
      <div
        className="flex gap-10"
        onChange={(e) => {
          let price = 0;
          const factor = product.quantity.roundedQuantity / 25;
          if (e.target.value === "With Embroidery") {
            price = factor * 5;
          }
          dispatch(
            productSummary({
              ...product,
              embroidary: {
                ...product.embroidary,
                label: e.target.value,
                price,
              },
              other: [
                ...product.other.filter(
                  (item) => !["No Embroidery", "With Embroidery"].includes(item)
                ),
                e.target.value,
              ],
              finalPrice:
                e.target.value === "With Embroidery"
                  ? product.finalPrice + price
                  : product.finalPrice - product.embroidary.price,
            })
          );
        }}
      >
        <div className=" font-bold text-gray-700 uppercase tracking-[0.25em] max-w-sm w-full">
          Select Embroidery
        </div>
        <div className="flex flex-col gap-4 mt-1">
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              checked={product.embroidary.label === "No Embroidary" && true}
              name="embroidary"
              value="No Embroidary"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="embroidary" className="ml-4 w-max">
              No Embroidery
            </div>
          </div>
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              checked={product.embroidary.label === "With Embroidery" && true}
              name="embroidary"
              value="With Embroidery"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="embroidary" className="ml-4 w-max">
              With Embroidery
            </div>
          </div>
        </div>
      </div>
      {/* ..................SELECT BACKING.............................. */}
      <div
        className="flex gap-10 "
        onChange={(e) => {
          let price = 0;
          const factor = product.quantity.size;
          if (e.target.value === "Iron on Backing") {
            price =
              factor *
              parseFloat(
                (
                  quantityPrice[
                    Math.round(
                      parseFloat(product.wide) * parseFloat(product.long)
                    )
                  ] * 0.05
                ).toFixed(2)
              );
          }
          if (e.target.value === "Velcro Backing") {
            price =
              factor *
              parseFloat(
                (
                  quantityPrice[
                    Math.round(
                      parseFloat(product.wide) * parseFloat(product.long)
                    )
                  ] * 0.1
                ).toFixed(2)
              );
          }

          dispatch(
            productSummary({
              ...product,
              other: [
                ...product.other.filter(
                  (item) =>
                    ![
                      "No Backing",
                      "Iron on Backing",
                      "Velcro Backing",
                    ].includes(item)
                ),
                e.target.value,
              ],
              backing: {
                ...product.backing,
                label: e.target.value,
                price,
              },
              finalPrice:
                e.target.value === "No Backing"
                  ? product.finalPrice - product.backing.price
                  : product.finalPrice - product.backing.price + price,
            })
          );
        }}
      >
        <div className=" font-bold text-gray-700 uppercase tracking-[0.25em] max-w-sm w-full">
          SELECT BACKING
        </div>
        <div className="flex flex-col gap-4 mt-1">
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="backing"
              checked={product.backing.label === "No Backing" && true}
              value="No Backing"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="backing" className="ml-4 w-max">
              No Backing
            </div>
          </div>
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="backing"
              checked={product.backing.label === "Iron on Backing" && true}
              value="Iron on Backing"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="backing" className="ml-4 w-max">
              Iron on Backing
            </div>
          </div>
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="backing"
              checked={product.backing.label === "Velcro Backing" && true}
              value="Velcro Backing"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="backing" className="ml-4 w-max">
              Velcro Backing
            </div>
          </div>
        </div>
      </div>
      {/* ..................SELECT BORDER & EDGE............................. */}
      <div
        className="flex gap-10 "
        onChange={(e) => {
          let price = 0;
          const factor = product.quantity.size;
          if (e.target.value === "Single Felt") {
            price = 0;
          } else if (e.target.value === "Double Felt") {
            price = factor * 0.2;
          } else {
            price = factor * 0.4;
          }
          dispatch(
            productSummary({
              ...product,
              other: [
                ...product.other.filter(
                  (item) =>
                    !["Single Felt", "Double Felt", "Triple Felt"].includes(
                      item
                    )
                ),
                e.target.value,
              ],
              border: {
                ...product.backing,
                label: e.target.value,
                price,
              },
              finalPrice:
                e.target.value === "Single Felt"
                  ? product.finalPrice - product.border.price
                  : product.finalPrice - product.border.price + price,
            })
          );
        }}
      >
        <div className=" font-bold text-gray-700 uppercase tracking-[0.25em] max-w-sm w-full">
          SELECT FELT TYPE
        </div>
        <div className="flex flex-col gap-4 mt-1">
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="border&edge"
              checked={product.border.label === "Single Felt" && true}
              value="Single Felt"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="border&edge" className="ml-4 w-max">
              Single Felt
            </div>
          </div>
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              checked={product.border.label === "Double Felt" && true}
              name="border&edge"
              value="Double Felt"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="border&edge" className="ml-4 w-max">
              Double Felt
            </div>
          </div>
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="border&edge"
              checked={product.border.label === "Triple Felt" && true}
              value="Triple Felt"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="border&edge" className="ml-4 w-max">
              Triple Felt
            </div>
          </div>
        </div>
      </div>
      {/* ..................COLORS SELECTION.............................. */}
      <div
        className="flex gap-10 "
        onChange={(e) => {
          let price = 0;
          const factor = product.quantity.size;
          if (e.target.value === "4-6 Colors") {
            price = factor * 0.5;
          }

          dispatch(
            productSummary({
              ...product,
              other: [
                ...product.other.filter(
                  (item) => !["1-3 Colors", "4-6 Colors"].includes(item)
                ),
                e.target.value,
              ],
              color: {
                ...product.color,
                label: e.target.value,
                price,
              },
              finalPrice:
                e.target.value === "4-6 Colors"
                  ? product.finalPrice + price
                  : product.finalPrice - product.color.price,
            })
          );
        }}
      >
        <div className=" font-bold text-gray-700 uppercase tracking-[0.25em] max-w-sm w-full">
          SELECT COLORS
        </div>
        <div className="flex flex-col gap-4 mt-1">
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="color"
              checked={product.color.label === "1-3 Colors" && true}
              value="1-3 Colors"
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="color" className="ml-4 w-max">
              1-3 Colors
            </div>
          </div>
          <div className="flex items-center gap-2">
            <input
              required
              type="radio"
              name="color"
              value="4-6 Colors"
              checked={product.color.label === "4-6 Colors" && true}
              className=""
              disabled={product.quantity.size < 25}
            />
            <div htmlFor="color" className="ml-4 w-max">
              4-6 Colors
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Selection;
