/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./CustomizePage.css";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Progressbar from "../../components/Progressbar/Progressbar";
import Footer from "../../components/footer/Footer";
import Summary from "./Summary";
import Embroidery from "./Embroidery";
import { AiFillCaretLeft, AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { Spin, message } from "antd";
import axios from "axios";
import { API_URL } from "../../config";
import Cookies from "js-cookie";
import { TiTick } from "react-icons/ti";
import productSummary from "../../actions/productSummary";
import { initialStateProductSummary } from "../../reducers/productSummaryReducer";

export default function CustomizePage() {
  const [instructions, setInstructions] = useState("");
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    message.warning("Processing......");
    setShowLoader(true);
    try {
      const editImage = new FormData();
      editImage.append("image", product.editImage);
      const resEdit = await axios.post(API_URL + "order/img/upload", editImage);
      const originalImage = new FormData();
      originalImage.append("image", product.originalImage);
      const resOriginal = await axios.post(
        API_URL + "order/img/upload",
        originalImage
      );
      const authUserId = Cookies.get("authUserId");
      const userId = Cookies.get("userId");
      const token = Cookies.get("token");
      if (resEdit.status === 200 && resOriginal.status === 200) {
        message.warning("Processing......");
        const orderResponse = await axios.post(`${API_URL}order/add`, {
          ...(token ? { authUserId } : { userId }),
          ...(instructions && { notes: instructions }),
          // deliveryTime: "expedit",
          category: product.category,
          imageUrls: resEdit.data.imageUrls,
          originalImage: resOriginal.data.imageUrls[0],
          reasonToPurchase: "some reason",
          quantity: product.quantity.size,
          wide: product.wide,
          long: product.long,
          totalPrice: product.finalPrice,
          embroidary:
            product.embroidary.label === "With Embroidery" ? true : false,
          backing: product.backing.label === "Iron on Backing" ? true : false,
          borderEdge:
            product.border.label === "Single Felt"
              ? 1
              : product.border.label === "Double Felt"
              ? 2
              : 3,
          color: product.color.label === "4-6 Colors" ? true : false,
          skip: product.skipSample,
        });
        if (orderResponse.status === 200) {
          setShowMessage(true);
          // console.log("orderResponse", orderResponse);
          message.success("Item Added to the Cart");
          setShowLoader(false);
        }
        setShowLoader(false);
      }
    } catch (err) {
      setShowLoader(false);
    }
  };

  return (
    <>
      <div className="text-black bg-brand">
        {/*-----navbar-----*/}
        <div className="px-1 bg-white lg:mx-auto lg:px-2">
          <Navbar />
        </div>
        <div className="flex flex-col" id="steps_container ">
          <div className="flex flex-row xl:mx-40">
            <Progressbar />
          </div>
        </div>

        <form onSubmit={handleSubmit} className="pb-10 lg:px-10">
          <div className="flex flex-col gap-12 mt-10 lg:flex-row">
            <div className="flex flex-col w-full">
              <div className="font-sans text-2xl font-semibold uppercase lg:text-3xl xl:text-4xl">
                Step-4
              </div>
              <div className="mt-6 text-sm font-medium text-gray-500 lg:text-lg">
                CUSTOMIZE
              </div>
              <div className="mt-4 text-sm font-normal tracking-tight text-justify sm:text-base sm:pl-0 ">
                Customize by selecting the options that best fit your needs. As
                you make selections, the Order Summary on the right will update
                and reflect each choice - any upgrades that add additional cost
                to your order will also be reflected.
              </div>
              <div className="w-full h-1 mt-10 bg-[#dadbd3]"></div>
              <Embroidery />
              <div className="font-bold  text-gray-700 mt-10 uppercase tracking-[0.25em] pl-4 sm:pl-8 md:pl-0 xl:flex flex-col">
                Add instructions
                <textarea
                  className="mt-5 w-[85%] sm:w-3/4 outline-0  ml-4 sm:ml-8 md:ml-0 h-[100px]  border border-[#807b7b] rounded-md p-2 placeholder:text-[#807b7b] placeholder:font-medium font-medium"
                  placeholder="Add instructions for better customization "
                  name="instructions"
                  onChange={(e) => {
                    setInstructions(e.target.value);
                  }}
                  value={instructions.length === 0 ? undefined : instructions}
                  // {...register("instructions", {
                  //   // required: "Instructions is required",
                  // })}
                ></textarea>
              </div>
            </div>

            <Summary />
          </div>
          <div className="flex flex-col items-center justify-center mt-10 mb-20 sm:flex-row gap-y-5 ">
            <Link to="/upload">
              <button
                className="flex items-center px-12 text-2xl text-white bg-green-700 rounded-full hover:bg-green-600 w-60 h-14"
                type="button"
              >
                <AiFillCaretLeft className="mr-2" />
                <span>Previous</span>
              </button>
            </Link>
            <button
              className="px-12 ml-2 text-2xl text-white rounded-full bg-sky-700 hover:bg-sky-600 h-14"
              type="submit"
            >
              Add to Cart
            </button>
          </div>
        </form>
        <Footer />
      </div>
      {showMessage && (
        <div
          onClick={() => navigate("/select")}
          className="bg-[#180e0e7e] top-0 left-0 right-0 bottom-0 fixed z-[99999] flex items-center justify-center"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-[90%] mx-auto bg-white md:w-[450px] shadow-lg rounded-md"
          >
            <div className="flex items-center p-2 bg-gray-50  justify-between border-b-black border-[1px] border-b ">
              <p className="flex items-center justify-center">
                <TiTick color="green" size={25} />
                <span className="ml-2 text-xl font-semibold text-green-600">
                  Item has been added to your Cart
                </span>
              </p>
              <AiOutlineClose
                size={25}
                cursor="pointer"
                onClick={() => setShowMessage(false)}
              />
            </div>
            <div className="flex items-center w-full p-4 gap-x-5">
              <img
                src={URL.createObjectURL(product.editImage)}
                className="w-[30%]"
                alt=""
              />

              <div className="self-start px-2 ">
                <p className="font-bold text-[18px] leading-[120%]">
                  Chenille patches
                </p>
                <p>Height : {product.long}</p>
                <p>Width : {product.wide}</p>
                <p>Qty : {product.quantity.size}</p>
                <p>Price : ${product.finalPrice}</p>
              </div>
            </div>
            <div className="flex items-center px-4 pb-4 gap-x-5">
              <button
                type="button"
                onClick={() => {
                  navigate("/select");
                  dispatch(productSummary({ ...initialStateProductSummary }));
                }}
                className=" border-black border-[0.5px]  p-1 w-1/2 rounded-sm"
              >
                Continue Shopping
              </button>
              <button
                onClick={() => {
                  navigate("/cart");
                  dispatch(productSummary({ ...initialStateProductSummary }));
                }}
                className="bg-[#38A217] border-[#38A217] hover:bg-[#308317] border-[0.5px] text-white p-1 w-1/2 rounded-sm"
              >
                View Cart {">>"}
              </button>
            </div>
          </div>
        </div>
      )}
      {showLoader && (
        <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center ">
          <Spin size="large" />
        </div>
      )}
    </>
  );
}
