/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import "./header.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import playbutton from "../../components/images/playbutton.png";
import kk from "../../components/images/kk.png";
import one from "../../components/images/one.png";
import two from "../../components/images/two.png";
import three from "../../components/images/three.png";
import fours from "../../components/images/fours.png";
import fifth from "../../components/images/fifth.png";
import six from "../../components/images/six.png";
import seven from "../../components/images/seven.png";
import yoga from "../../components/images/yoga.png";
import four from "../../components/images/four.png";
import patches from "../../components/images/patches.png";
import hoodie from "../../components/images/hoodie.png";
import dog from "../../components/images/dog.jpg";
import Front from "../../components/images/Front.png";
import Jackets from "../../components/images/Jackets.png";
import { useSelector, useDispatch } from "react-redux";
import activeStep from "../../actions/activeStep";
import productSummary from "../../actions/productSummary";
import Popup from "../Popup/PopupForm";
import { MdFormatQuote } from "react-icons/md";
import Footer from "../../components/footer/Footer";
import { getFeedback } from "../../api/feedback";
import Quadrants from "../quadrants/Quadrants";
import sampleimg from "../images/sampleimg.jpeg";
import MyCarousal from "../MyCarousal";
import axios from "axios";
import { API_URL } from "../../config";
import MessageBox from "../MessageBox/MessageBox";
import { message } from "antd";
import { HomeText } from "./HomeText";

const patchesItems = [
  { title: "Chenille", url: "/chenille-patches" },
  { title: "Embroidery", url: "/patches?&categories=Embroidery" },
  { title: "Woven", url: "/patches?&categories=Woven" },
  { title: "Vegan Leather", url: "/patches?&categories=Vegan%20Leather" },
];
const jacketsItems = [
  { title: "Varsity", url: "/jackets?&categories=Varsity" },
  { title: "Poly Satin", url: "/jackets?&categories=PolySatin" },
  { title: "Leather", url: "/jackets?&categories=Leather" },
  { title: "PolyTwill", url: "/jackets?&categories=PolyTwill" },
  { title: "CottonTwill", url: "/jackets?&categories=CottonTwill" },
];
const garmentsItems = [
  { title: "Hoodies", url: "/garments?&categories=Hoodies" },
  { title: "Sweatshirt", url: "/garments?&categories=Sweatshirt" },
];

const images = [three, seven, one, fours, fifth, six, two];
const Carousel = () => {
  const [currentImage, setCurrentImage] = React.useState(0);
  const refs = images.reduce((acc, val, i) => {
    acc[i] = React.createRef();
    return acc;
  }, {});

  const scrollToImage = (i) => {
    setCurrentImage(i);

    refs[i].current.scrollIntoView({
      behavior: "smooth",
      // block: 'nearest',
      // inline: 'start',
    });
  };

  const totalImages = images.length;

  const nextImage = () => {
    if (currentImage >= totalImages - 1) {
      scrollToImage(0);
    } else {
      scrollToImage(currentImage + 1);
    }
  };

  const previousImage = () => {
    if (currentImage === 0) {
      scrollToImage(totalImages - 1);
    } else {
      scrollToImage(currentImage - 1);
    }
  };

  const arrowStyle = "absolute mx-auto text-4xl font-bold text-gray-400";

  const sliderControl = (isLeft) => (
    <button
      type="button"
      onClick={isLeft ? previousImage : nextImage}
      className={`${arrowStyle} ${isLeft ? "left-1" : "right-1"} `}
      style={{ top: "50%" }}
    >
      <span role="img" aria-label={`Arrow ${isLeft ? "left" : "right"}`}>
        {isLeft ? "<" : ">"}
      </span>
    </button>
  );

  //   useEffect(()=> {
  //     const changeSlide = setInterval(() => setCurrentImage(nextImage => 1-nextImage) , 20) // change your switch time here.
  //     return () => clearInterval(changeSlide);
  //  }, [])

  return (
    <div className="px-2 mt-20 rounded-full">
      <div className="relative w-full mx-auto my-auto">
        <div className="carousel">
          {sliderControl(true)}
          {images.map((img, i) => (
            <div className="flex-shrink-0 w-full" key={img} ref={refs[i]}>
              <img src={img} className="object-contain w-full" />
            </div>
          ))}
          {sliderControl()}
        </div>
      </div>
    </div>
  );
};

export default function Header() {
  const [show, setShow] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showMessage, setShowMessage] = useState(false);
  const [googleReviews, setGoogleReviews] = useState([]);
  useEffect(() => {
    const verifyHandler = async () => {
      const token = searchParams.get("token");
      const email = searchParams.get("email");
      localStorage.setItem("useremail", email);
      if (token) {
        try {
          const { data } = await axios.post(API_URL + "auth/verify", {
            token: token,
          });
          if (data?.message === "Email Verified...") {
            setShowMessage(true);
          }
        } catch (err) {
          message.error("Wrong Token");
          navigate("/verifyemail");
        }
      }
    };

    verifyHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const f = async () => {
      try {
        const { data } = await axios.get(`${API_URL}reviews`);

        for (let i = 0; i < data?.result?.reviews.length; i++) {
          let temp;
          const number = Math.floor(Math.random() * 5);
          temp = data?.result?.reviews[i];
          data.result.reviews[i] = data?.result?.reviews[number];
          data.result.reviews[number] = temp;
        }

        setGoogleReviews([...data?.result?.reviews]);
      } catch (err) {}
    };

    f();
  }, []);

  const handleStep1 = (prod) => {
    const name = prod;
    let img = "";
    switch (prod) {
      case "Chennile Patches":
        img = dog;
        break;
      case "Hoodies":
        img = Front;
        break;
      case "Jackets":
        img = Jackets;
        break;
      default:
        img = Jackets;
    }

    const n = name.split(" ");

    const category = n[n.length - 1];
    dispatch(productSummary({ ...product, name, img, category }));
    dispatch(activeStep(2));
    navigate(prod.toLowerCase());
  };

  const handleClick = () => {
    dispatch(activeStep(1));
    navigate("/select");
  };
  // const res= await getFeedback();
  // if (res.status === '200') {
  //   setFeedback(res);
  // }

  const getFeed = async () => {
    const res = await getFeedback();
    if (res.status === 200) {
      setFeedback(res.data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getFeed();
  }, []);

  return (
    <div className="relative font-sans text-black bg-brand 0">
      <div className="h-40  lg:mx-auto ">
        <Navbar refreshFlag={showMessage} />

        <button
          onClick={() => setShow(!show)}
          title="Contact Sale"
          className={`fixed z-10  bottom-2 lg:bottom-6 right-2 md:right-4  from-blue-500 to-blue-600 bg-blue-500 w-14 h-14 rounded-full drop-shadow-lg flex justify-center items-center text-white ${
            show ? "text-[40px]" : "text-3xl"
          } hover:bg-blue-600 ${
            show ? "hover:text-[46px]" : "hover:text-[34px]"
          } hover:drop-shadow-2xl cursor-pointer`}
        >
          <ion-icon
            name={show ? "close-outline" : "mail"}
            className=""
          ></ion-icon>
        </button>

        {show && <Popup title="Peri Apparel and Fabrics" onClose={setShow} />}

        <div className="p-2 r">
          <div className=" grid grid-cols-3 py-[4px] md:py-1 text-center text-slate-700 bg-gray-200 font-bold font-sans text-sm md:text-2xl rounded-lg ">
            <p className="group relative">
              <span
                onClick={() => navigate("/patches")}
                className="cursor-pointer hover:underline underline-offset-4 decoration-2"
              >
                Patches
              </span>
              <div className="text-[18px] absolute bg-gray-200 top-50 left-0 w-full h-0 opacity-0 overflow-hidden transition-all duration-300 group-hover:h-[180px] group-hover:opacity-100 z-10 rounded-b-lg p-3">
                <ul className="flex flex-col h-full items-center justify-around">
                  {patchesItems.map((ele, index) => (
                    <li
                      className="hover:bg-[#F3F4F6] transition-all p-1 rounded-lg w-full"
                      key={index}
                    >
                      <span
                        className="hover:underline hover:underline-offset-4 cursor-pointer"
                        onClick={() => navigate(ele.url)}
                      >
                        {ele.title}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </p>

            <p className="group relative">
              <span
                onClick={() => navigate("/jackets")}
                className="cursor-pointer hover:underline underline-offset-4 decoration-2"
              >
                Jackets
              </span>

              <div className="text-[18px] absolute bg-gray-200 top-50 left-0 w-full h-0 opacity-0 overflow-hidden transition-all duration-300 group-hover:h-[220px] group-hover:opacity-100 z-10 rounded-b-lg p-3">
                <ul className="flex flex-col h-full items-center justify-around">
                  {jacketsItems.map((ele, index) => (
                    <li
                      className="hover:bg-[#F3F4F6] transition-all p-1 rounded-lg w-full"
                      key={index}
                    >
                      <span
                        className="hover:underline hover:underline-offset-4 cursor-pointer"
                        onClick={() => navigate(ele.url)}
                      >
                        {ele.title}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </p>

            <p className="group relative">
              <span
                onClick={() => navigate("/garments")}
                className="cursor-pointer hover:underline underline-offset-4 decoration-2"
              >
                Garments
              </span>
              <div className="text-[18px] absolute bg-gray-200 top-50 left-0 w-full h-0 opacity-0 overflow-hidden transition-all duration-300 group-hover:h-[100px] group-hover:opacity-100 z-10 rounded-b-lg p-3">
                <ul className="flex flex-col h-full items-center justify-around">
                  {garmentsItems.map((ele, index) => (
                    <li
                      className="hover:bg-[#F3F4F6] transition-all p-1 rounded-lg w-full"
                      key={index}
                    >
                      <span
                        className="hover:underline hover:underline-offset-4 cursor-pointer"
                        onClick={() => navigate(ele.url)}
                      >
                        {ele.title}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </p>
          </div>
        </div>

        {/* carousel with dynamic images */}
        <MyCarousal />

        <div className="">
          <HomeText />
          <Quadrants handleStep1={handleStep1} />

          <div
            id="testimonials"
            className="w-full pt-10 pb-0 mt-5   max-w-7xl mx-auto p-2"
          >
            <div className="text-black  font-bold font-sans tracking-wide text-3xl md:text-3xl">
              Customer Says
            </div>
            <div className="flex w-auto pb-10 mt-10 overflow-x-scroll hide-scroll-bar ">
              <div className="flex flex-nowrap">
                {/* <GoogleReviews apiKey={"AIzaSyDxGqzI3Vhhu8LKJkh4HamfrLiNgku1XIk"} placeId={"ChIJH3ovSnUZTIYRFnO0SsQ_TBY"}/> */}
                {googleReviews.map((item , i) => {
                  // if (item.isVisible) {
                  return (
                    <div className="inline-block px-5 md:px-10" key={i}>
                    <div className="w-[15rem] md:w-[20rem] h-[20rem] md:h-[22rem] text-center overflow-hidden rounded-md shadow-md bg-slate-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                      <div className="mt-5 ">
                        <MdFormatQuote className="mx-auto text-xl text-blue-400 md:text-4xl w-fit" />
                      </div>
                      <div className="w-[80%] mt-6 text-md tracking-wide mx-auto">
                        {item?.text}
                      </div>
                      <div className="mt-5 text-lg font-semibold uppercase md:mt-10">
                        {item?.author_name}
                      </div>
                      <div className="mt-2 mb-10 font-medium tracking-widest text-gray-400 uppercase text-md">
                        {item?.location}
                      </div>
                    </div>
                  </div>
                  );
                  // }
                })}
              </div>
            </div>
          </div>

          <div className="mt-20 bg-green-600">
            <Footer />
          </div>
        </div>
        {showMessage && (
          <MessageBox
            message="Email address is verified. Please login to continue to your account."
            close={() => {
              setShowMessage(false);
              navigate("/login");
            }}
            emailverify
          />
        )}
      </div>
    </div>
  );
}
