import React from "react";
import { useLocation } from "react-router-dom";

const BreadCrumb = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page");
  const color = (value) => {
    const allPage = ["upload", "customize", "info", "cart"];
    const currentIndex = allPage.indexOf(page);
    const valueIndex = allPage.indexOf(value);
    let currentColor;
    if (value === page) {
      currentColor = "bg-brand-success";
    } else if (currentIndex > valueIndex) {
      currentColor = "bg-brand-planetarium";
    } else if (currentIndex < valueIndex) {
      currentColor = "bg-brand-button";
    }
    return currentColor;
  };
  return (
    <div className="flex max-w-xl mx-auto ">
      <div className="flex flex-col items-center justify-center gap-2">
        <div
          className={`flex items-center justify-end p-4 text-white rounded-full px-[26px]  ${color(
            "upload"
          )}`}
        >
          <p className="text-2xl font-extrabold">1</p>
        </div>
        <p className="text-lg">UPLOAD</p>
      </div>
      <div className={`w-[100px] h-1 mt-7 ${color("upload")}`}></div>
      <div className="flex flex-col items-start justify-center relative">
        <div
          className={`flex items-center justify-end p-4 text-white rounded-full px-[26px]  ${color(
            "customize"
          )} mb-8`}
        >
          <p className="text-2xl font-extrabold">2</p>
        </div>
        <p className="absolute -bottom-[0.8px] text-lg   uppercase -right-4">customize</p>
      </div>
      <div className={`w-[100px] h-1 mt-7 ${color("customize")} `}></div>
      <div className="flex flex-col items-center justify-center gap-2">
        <div
          className={`flex items-center justify-end p-4 text-white rounded-full px-[26px]   ${color(
            "info"
          )}`}
        >
          <p className="text-2xl font-extrabold">3</p>
        </div>
        <p className="text-lg uppercase">INFO</p>
      </div>
      <div className={`w-[100px] h-1 mt-7 ${color("info")}`}></div>

      <div className="flex flex-col items-center justify-center ">
        <div
          className={`flex items-center justify-end p-4 text-white rounded-full px-[26px]  ${color(
            "cart"
          )}`}
        >
          <p className="text-2xl font-extrabold">4</p>
        </div>
        <p className="text-lg">CART</p>
      </div>
    </div>
  );
};

export default BreadCrumb;
