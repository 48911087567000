import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { useParams } from "react-router-dom";
import Embroidery from "./embroidery/Embroidery";
import BreadCrumb from "./common/BreadCrumb";
import Chenille from "./chenille/Chenille";
import VeganLeather from "./veganLeather/VeganLeather";
import Woven from "./woven/Woven";
import WovenBreadCrumb from "./woven/WovenBreadCrumb";

const PatchesOrder = () => {
  const { id } = useParams();
  return (
    <>
      <Navbar />
      <div className="flex flex-col w-full min-h-screen gap-2">
        {id === "woven" ? <WovenBreadCrumb /> : <BreadCrumb />}
        {id === "chenille" && <Chenille />}
        {id === "embroidery" && <Embroidery />}
        {id === "vegan-leather" && <VeganLeather />}
        {id === "woven" && <Woven />}
      </div>
      <Footer />
    </>
  );
};

export default PatchesOrder;
