import axios from "axios";
import { API_URL } from "../config/index";

const apiUrl = API_URL + "user/info";

export const getUserInfo = async (email) => {
  try {
    const response = await axios.get(apiUrl + "?email=" + email);

    return response;
  } catch (err) {
    return err.response.data;
  }
};

export const postUserInfo = async (form) => {
  const { firstname, lastname, email, contact } = form;

  try {
    const response = await axios.post(apiUrl, {
      firstname,
      lastname,
      email,
      contact,
    });
    return response;
  } catch (err) {
    // console.log(err.response);
    return err.response;
  }
};

export const patchUserInfo = async (form) => {
  const { firstname, lastname, email, contact } = form;

  try {
    const response = await axios.patch(apiUrl, {
      firstname,
      lastname,
      email,
      contact,
    });

    return response;
  } catch (err) {
    // console.log(err.response);
    return err.response;
  }
};
