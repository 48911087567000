export const initialStateProductSummary  = {
  name: '',
  img: '',
  category: '',
  wide: 0,
  long: 0,
  quantity: {
    size: 0,
    price: 0,
  },
  finalPrice: 0,
  embroidary: {
    label: '',
    price: 0,
  },
  backing: {
    label: '',
    price: 0,
  },
  border: {
    label: '',
    price: 0,
  },
  color: {
    label: '',
    price: 0,
  },
  other:[],
  customPrice:0,
  pricePerPatch:0,
  originalImage:null,
  editImage:null,
  instructions:''
};
const ProductSummary = (state = initialStateProductSummary , action) => {
  switch (action.type) {
    case 'PRODUCT_SUMMARY':
      return action.payload;
    default:
      return state;
  }
};

export default ProductSummary;
