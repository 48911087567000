import React, { useEffect, useState } from "react";
import "./navbar.css";
import logo from "../../components/images/logo.png";
import dog from "../../components/images/dog.jpg";
import Front from "../../components/images/Front.png";
import Jackets from "../../components/images/Jackets.png";
import { useSelector, useDispatch } from "react-redux";
import activeStep from "../../actions/activeStep";
import productSummary from "../../actions/productSummary";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Cookies from "js-cookie";
import { BsCart3 } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { API_URL } from "../../config";
import { Spin } from "antd";

export default function Navbar({ landing, refreshFlag, refresh }) {
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const active = useSelector((state) => state.activeStep);
  const [open, setOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [cart, setCart] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);

  const getUserDetails = () => {
    const t = Cookies.get("token");
    const e = Cookies.get("email");
    // console.log(t);
    setToken(t);
    setEmail(e);
  };

  let numberOfItemsInCart = cartData.length;

  useEffect(() => {
    getUserDetails();
    (async () => {
      if (token) {
        const { data } = await axios.get(`${API_URL}auth/myself`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUserDetails({ ...data?.userDetails });
      }
    })();
  }, [refreshFlag]);

  const handleClick = () => {
    dispatch(activeStep(0));
    navigate("/");
  };

  // const handleProduct = (prod) => {
  //   const name = prod;
  //   let img = "";
  //   switch (prod) {
  //     case "Chennile Patches":
  //       img = dog;
  //       break;
  //     case "Hoodies":
  //       img = Front;
  //       break;
  //     case "Jackets":
  //       img = Jackets;
  //       break;
  //     default:
  //       img = Jackets;
  //   }

  //   const n = name.split(" ");

  //   const category = n[n.length - 1];
  //   dispatch(productSummary({ ...product, name, img, category }));
  //   dispatch(activeStep(2));
  //   if (prod == "Chennile Patches") {
  //     navigate("/info");
  //   } else if (prod == "Hoodies") {
  //     navigate(`/${prod.toLowerCase()}`);
  //   } else if (prod == "Jackets") {
  //     navigate(`/${prod.toLowerCase()}`);
  //   }

  //   setOpen(false);
  //   setDropdown(false);
  // };

  const logout = () => {
    setIsProcessing(true);
    setShowMenu(false);
    setTimeout(() => {
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("authUserId");
      navigate("/");
      setToken("");
      setEmail("");
      setOpen(false);
      setIsProcessing(false);
    }, 3000);
  };

  const getCartData = async () => {
    const token = Cookies.get("token");
    if (token) {
      try {
        const { data } = await axios.get(`${API_URL}order/getCartOrders`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCartData([...data.cart]);
        // console.log(data);
      } catch (err) {
        // console.log(err);
      }
    } else {
      const userId = Cookies.get("userId");
      if (userId) {
        try {
          const { data } = await axios.post(`${API_URL}order/getGuestCart`, {
            id: userId,
          });
          setCartData([...data?.cart]);
          // console.log(data);
        } catch (err) {
          // console.log(err);
        }
      }
    }
  };
  useEffect(() => {
    getCartData();
  }, [cart, refresh]);

  const deleteItem = async (_id) => {
    const token = Cookies.get("token");
    const authUserId = Cookies.get("authUserId");
    const userId = Cookies.get("userId");
    // console.log(token);
    if (token) {
      try {
        const { data } = await axios.delete(`${API_URL}order/removeMyItem`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { orderId: _id },
        });
        // console.log(data);
        getCartData();
      } catch (err) {
        alert("Can't Delete Network Error");
        // console.log(err);
      }
    } else {
      if (userId) {
        try {
          const { data } = await axios.delete(
            `${API_URL}order/removeGuestItem`,
            {
              headers: { Authorization: `Bearer ${token}` },
              data: { guestId: userId, orderId: _id },
            }
          );
          // console.log(data);
          getCartData();
        } catch (err) {
          alert("Can't Delete Network Error");
          // console.log(err);
        }
      }
    }
  };

  return (
    <>
      <nav
        className={`${landing
          ? "bg-transparent  font-bold  text-white"
          : "text-black bg-white "
          } `}
      >
        <div className="flex items-center justify-around font-semibold px-9">
          <div className="z-30 flex justify-between w-full px-5 ">
            <div className="flex flex-row" onClick={handleClick}>
              <img
                className="cursor-pointer h-14 w-14 sm:h-24 sm:w-24"
                src={logo}
                alt="logo"
              />
              {landing ? (
                <h1 className="my-auto font-sans text-sm text-white cursor-pointer md:px-1 lg:px-2 w-max md:text-xl md:text-center">
                  Peri Apparel and Fabrics
                </h1>
              ) : (
                <h1 className="my-auto font-sans text-sm text-gray-900 cursor-pointer md:px-1 lg:px-2 w-max md:text-xl md:text-center">
                  Peri Apparel and Fabrics
                </h1>
              )}
            </div>
            <div className="text-2xl md:hidden">
              <button
                onClick={() => setOpen(!open)}
                className={`block w-min md:hidden md:px-10 ${open ? "pt-2" : "pt-4"
                  } py-1 pr-2 `}
              >
                {open ? (
                  <>
                    <svg
                      className="relative w-8 h-8 text-gray-600"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18" />
                      <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                  </>
                ) : (
                  <div className="space-y-2">
                    <div className="w-8 h-0.5 bg-gray-600"></div>
                    <div className="w-8 h-0.5 bg-gray-600"></div>
                    <div className="w-8 h-0.5 bg-gray-600"></div>
                  </div>
                )}
              </button>
            </div>
          </div>
          <ul className="items-center hidden gap-10 font-sans md:flex">
            <li>
              <div className="relative inline-block dropdown" >
                <div className="inline-flex items-center pr-2 cursor-pointer group relative">
                  <span className="mr-1 md:text-xl">Products</span>

                  <ion-icon name="chevron-down-outline" className="group-hover:hidden block"></ion-icon>

                  <ul className="opacity-0 group-hover:opacity-100 group-hover:h-[130px] h-0 absolute top-5 left-0 z-20 px-4 mt-2 bg-white border-2 rounded shadow-md dropdown-menu w-max transition-all duration-300 overflow-hidden">
                    <li>
                      <p
                        className="block py-2 cursor-pointer hover:underline underline-offset-4"
                        onClick={() => navigate("/patches")}
                      >
                        Patches
                      </p>
                    </li>
                    <li>
                      <p
                        className="block py-2 cursor-pointer hover:underline underline-offset-4"
                        onClick={() => navigate("/jackets")}
                      >
                        Jackets
                      </p>
                    </li>
                    <li>
                      <p
                        className="block py-2 cursor-pointer hover:underline underline-offset-4"
                        onClick={() => navigate("/garments")}
                      >
                        Garments
                      </p>
                    </li>
                  </ul>

                </div>
              </div>
            </li>

            {/* <li>
            <Link to="/select" onClick={()=>dispatch(activeStep(1))} className="inline-block px-3 py-7 lg:pr-8 w-max">
              Track Your Order
            </Link>
          </li>  */}

            <li>
              <Link
                to="/about-us"
                className="inline-block px-3 py-7 lg:pr-8 w-max md:text-xl"
              >
                Contact Us
              </Link>
            </li>

            {token ? (
              <div className="relative">
                {email && (
                  <div
                    onClick={() => {
                      setShowMenu(!showMenu);
                    }}
                    className="w-[40px] h-[40px] bg-blue-600 text-white text-xl font-bold rounded-full flex items-center justify-center cursor-pointer uppercase"
                  >
                    {email[0]}
                  </div>
                )}
                <ul
                  className={`${showMenu ? "block" : "hidden"
                    } transition-all ease-in-out duration-300 w-[200px] shadow-xl rounded-md absolute right-[0%] z-[999] bg-white top-[120%]`}
                >
                  <li
                    onClick={() => navigate("/myprofile")}
                    className="px-4 py-2 mt-4 cursor-pointer hover:bg-gray-300"
                  >
                    My Profile
                  </li>
                  <li
                    onClick={() => {
                      navigate("/orders");
                    }}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-300"
                  >
                    My Orders
                  </li>
                  <li
                    onClick={logout}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-300"
                  >
                    Logout
                  </li>
                </ul>
              </div>
            ) : (
              <li>
                <Link
                  to="/login"
                  className="inline-block px-3 py-7 lg:pr-8 w-max md:text-xl"
                >
                  Login
                </Link>
              </li>
            )}

            <li>
              <button onClick={() => setCart(true)} className="relative">
                <span
                  className={`-top-[40%] text-white absolute text-xs px-2 rounded-[4px] ${numberOfItemsInCart ? "bg-red-600" : "bg-green-500"
                    }`}
                >
                  {numberOfItemsInCart}
                </span>
                <BsCart3 size={25} />
              </button>
            </li>
          </ul>

          {/* Mobile nav */}
          <ul
            className={`
        md:hidden bg-white bg-opacity-[0.85] fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4 z-20
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
          >
            {/* <li>
            <Link to="/select" onClick={() => { 
            setOpen(!open)
            dispatch(activeStep(1)) 
            }} 
            className="inline-block px-3 py-7">
              Track Your Order
            </Link>
          </li>   */}
            <li>
              <div className="relative inline-block dropdown">
                <button
                  className="inline-flex items-center px-3 py-7"
                  onClick={() => setDropdown(!dropdown)}
                >
                  <span className="mr-1">Products</span>
                  <ion-icon
                    name={
                      dropdown ? "chevron-up-outline" : "chevron-down-outline"
                    }
                  ></ion-icon>
                </button>
                {dropdown && (
                  <ul className="absolute z-20 px-4 mt-1 bg-white border-2 rounded shadow-md dropdown-menu w-max">
                    <li>
                      <p
                        className="block py-2 cursor-pointer hover:text-gray-500"
                        onClick={() => navigate("/patches")}
                      >
                        Patches
                      </p>
                    </li>
                    <li>
                      <p
                        className="block py-2 cursor-pointer hover:text-gray-500"
                        onClick={() => navigate("/jackets")}
                      >
                        Jackets
                      </p>
                    </li>
                    <li>
                      <p
                        className="block py-2 cursor-pointer hover:text-gray-500"
                        onClick={() => navigate("/garments")}
                      >
                        Garments
                      </p>
                    </li>
                  </ul>
                )}
              </div>
            </li>
            <li>
              <Link
                to="/about-us"
                onClick={() => {
                  setOpen(!open);
                  dispatch(activeStep(8));
                }}
                className="inline-block px-3 py-7"
              >
                Contact Us
              </Link>
            </li>
            {token ? (
              <>
                {" "}
                <li>
                  <Link to="/myprofile" className="inline-block px-3 py-7">
                    My Profile
                  </Link>
                </li>
                <li>
                  <Link to="/orders" className="inline-block px-3 py-7">
                    My Orders
                  </Link>
                </li>
                <li>
                  <p
                    onClick={logout}
                    className="inline-block px-3 cursor-pointer py-7"
                  >
                    Logout
                  </p>
                </li>
                {/* <div className="ml-3 w-[30px] h-[30px] bg-blue-600 text-white text-xl font-bold rounded-full flex items-center justify-center cursor-pointer uppercase">
                  {email[0]}
                </div> */}
              </>
            ) : (
              <li>
                <Link to="/login" className="inline-block px-3 py-7">
                  Login
                </Link>
              </li>
            )}
            <li>
              <Link to="/cart" className="inline-block px-3 py-7">
                View Cart
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      {cart && (
        <div
          onClick={() => setCart(false)}
          className=" top-0 left-0 right-0 bottom-0 fixed z-[999] bg-[#0000007a]"
        ></div>
      )}
      <div
        className={`min-h-screen absolute top-0 right-0 bg-white z-[9999] transition-all ease-in-out duration-300 overflow-hidden ${cart ? "w-[400px] " : "w-[0px]"
          }`}
      >
        <div className="flex justify-between p-2 py-5 bg-blue-900 ">
          <div className="flex">
            <BsCart3 size={28} cursor="pointer" color="white" />
            {userDetails?.firstname && (
              <p className="ml-2 text-white">Hi, {userDetails?.firstname}</p>
            )}
          </div>
          <AiOutlineClose
            size={28}
            cursor="pointer"
            color="white"
            onClick={() => setCart(false)}
          />
        </div>
        <div className="px-2">
          {numberOfItemsInCart === 0 && (
            <div className="flex flex-col items-center min-h-[300px]  justify-center">
              <img
                src="https://images.contentstack.io/v3/assets/bltd3ac7773b0da8623/bltc4bbcf587bab8ebb/60c31871f77af428924badac/cart-icon-empty1.svg"
                className="w-[20%]"
                alt="Cart"
              />
              <p className="mt-4 text-2xl text-center"> Your Cart is empty.</p>
            </div>
          )}
          {numberOfItemsInCart > 0 && (
            <>
              {cartData.map((item, _index) => (
                <div
                  key={item._id}
                  className="flex items-center justify-between px-2 my-4 text-xs"
                >
                  {/* {console.log(item)} */}
                  <div className="flex items-center gap-x-4 ">
                    <img
                      src={item.imageUrls[0]}
                      className="w-[70px] h-[70px] object-contain"
                      alt=""
                    />
                    <div className="">
                      <p className="">
                        {item.quantity}{" "}
                        {item.category === "Patches"
                          ? "Chennile"
                          : item.category}{" "}
                        patches
                      </p>
                      <p className="">
                        Height: {item.long} in, Width: {item.wide} in
                      </p>
                      <p
                        onClick={() => deleteItem(item._id)}
                        className="text-xs text-red-600 cursor-pointer w-[40px]"
                      >
                        {" "}
                        remove
                      </p>
                    </div>
                  </div>
                  <p className="text-xl font-bold">
                    ${item.totalPrice.toFixed(2)}
                  </p>
                </div>
              ))}

              <p className="flex items-center justify-between w-full px-2 mb-4 text-2xl font-bold">
                <span>Total Price :</span>
                <span>
                  $
                  {cartData
                    .reduce(function(acc, obj) {
                      return acc + obj?.totalPrice;
                    }, 0)
                    .toFixed(2)}
                </span>
              </p>
              <Link to="/cart">
                <button className="block w-full px-8 py-3 mx-auto mt-2 font-bold text-white transition-all duration-300 ease-in-out bg-blue-800 rounded-md hover:bg-blue-900">
                  View Cart
                </button>
              </Link>
              <Link to="/select">
                <button className="my-2 bg-[#aa35c2] hover:bg-[#88239c] text-white mx-auto block px-8  py-3 rounded-md font-bold w-full transition-all ease-in-out duration-300">
                  Continue Shopping
                </button>
              </Link>
            </>
          )}
        </div>
      </div>

      {isProcessing && (
        <div className="fixed top-0 left-0 right-0 bottom-0  flex items-center justify-center z-[99999]">
          <Spin size="large" />
        </div>
      )}
    </>
  );
}
