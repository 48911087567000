import React, { useEffect } from 'react'
import { AiFillCloseCircle, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs'
import LeftArrow from '../../components/Slider/icons/Left-arrow.png';
import RightArrow from '../../components/Slider/icons/Right-arrow.png';

const Modal = ({ show, setShow, id, setId, type, setType, length }) => {
    useEffect(() => {
        // alert(`/Imgs/${type}/${type}${id}_Large.png`)
    }, [])
    return (<>
        {show && <div className='fixed top-0 left-0 right-0 bottom-0 bg-black/70 flex items-center justify-center'>


            <div className='flex w-full justify-between items-center'>
                <button onClick={() => setShow(false)} className="fixed top-4 right-4  "><AiFillCloseCircle size={50} color='white' /></button>
                <div className='ml-10' >
                    <AiOutlineRight
                        onClick={() => { id > 0 && setId((prevId) => prevId - 1) }}
                        size={50}
                        color="white"
                    />
                </div>
                <img
                    src={process.env.PUBLIC_URL + `/Imgs/${type}/${type}${id}_Large.png`}
                    className="w-[70%] h-screen object-contain"
                    alt=""
                />
                <div className='mr-10 bg-red-500' >
                    <AiOutlineLeft
                        onClick={() => setId((prevId) => prevId + 1)}
                        size={50}
                        color="white" />
                </div>
            </div>
        </div>

        }
    </>
    )
}

export default Modal
