import React from "react";
import ProductSize from "./ProductSize";
import { GrPowerReset, GrZoomIn, GrZoomOut } from "react-icons/gr";
const EditorTool = ({
  setCrop,
  setFitImage,
  imageDimensions,
  width,
  height,
}) => {
  const imageRatio = imageDimensions.width / imageDimensions.height;
  const patchRatio = width / height;
  return (
    <div className="flex items-start justify-between px-3 py-2">
      <ProductSize
        setCrop={setCrop}
        setFitImage={setFitImage}
        width={width}
        height={height}
        patchRatio={patchRatio}
        imageRatio={imageRatio}
      />
      <div className="flex gap-2">
        <div className="flex flex-col space-y-5">
          <p className="mt-1 text-sm">Image Zoom</p>
          <p className="text-sm text-end">Image Reset</p>
        </div>
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="bg-[#e5e7eb] p-2 rounded font-semibold "
              onClick={() => setFitImage((prev) => parseFloat(prev + 20.9))}
            >
              {" "}
              <GrZoomIn />
            </button>
            <button
              type="button"
              className="bg-[#e5e7eb] p-2 rounded font-semibold "
              onClick={() =>
                setFitImage((prev) => parseFloat((prev - 20.9).toFixed(1)))
              }
            >
              {" "}
              <GrZoomOut />
            </button>
          </div>
          <div>
            <button
              type="button"
              className="p-2 font-semibold  rounded bg-[#e5e7eb]"
              onClick={() => {
                setFitImage(imageRatio >= patchRatio ? height : width);
                setCrop({
                  x: 0,
                  y: 0,
                  width: width,
                  height: height,
                  unit: "px",
                });
              }}
            >
              {" "}
              <GrPowerReset />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorTool;
