import React from "react";
import Navbar from "../navbar/Navbar";
import PatchDetail from "./PatchDetail";
import FilterPatch from "./FilterPatch";
import Patch from "./Patch";
import Footer from "../footer/Footer";
import { getPatches } from "../../api/patches";
import { useLocation } from "react-router-dom";

const Patches = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categories = searchParams.getAll("categories");
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const f = async () => {
      const res = await getPatches();
      if (res.status === 201) {
        setData(res.data.patch);
      }
    };
    f();
  }, []);

  const filterData =
    categories.length === 0
      ? data
      : data.filter((el) => categories.includes(el.category));

  return (
    <>
      <Navbar />
      <div className="relative lg:px-4">
        <PatchDetail />
        <div className="flex flex-col mt-6 lg:space-x-10 lg:flex-row p-4">
          <FilterPatch />
          <Patch data={filterData} />
        </div>
      </div>
      <div className="h-[40px]"></div>
      <Footer />
    </>
  );
};

export default Patches;
