import React from "react";

const Form = ({setValue,register,errors}) => {
  const set = (label) => {
    return ({ target: { value } }) => {
      setValue(label, value);
    };
  };
  return (
    <>
      <div className="flex items-center mx-auto mt-5 uppercase gap-x-2 ">
        <span className=" md:text-md font-sans w-[100px] inline-block font-medium">
          First Name
        </span>
        <div className="h-10 my-2 ">
          <input
            className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
            name="firstname"
            type="text"
            placeholder="First Name"
            onChange={set("firstname")}
            {...register("firstname", {
              required: "First Name is required",
              minLength: {
                value: 2,
                message: "Atleast 2 characters",
              },
            })}
          ></input>
          <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
            {errors?.firstname?.message}
          </p>
        </div>
      </div>
      <div className="flex items-center mx-auto my-5 uppercase gap-x-2">
        <span className=" font-medium md:text-md font-sans w-[100px] inline-block">
          Last Name
        </span>
        <div className="h-10 my-2 ">
          <input
            className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
            name="lastname"
            type="text"
            placeholder="Last Name"
            onChange={set("lastname")}
            {...register("lastname", {
              required: "Last Name is required",
              minLength: {
                value: 2,
                message: "Atleast 2 characters",
              },
            })}
          ></input>
          <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
            {errors?.lastname?.message}
          </p>
        </div>
      </div>

      <div className="flex items-center mx-auto uppercase gap-x-2">
        <span className=" font-medium md:text-md font-sans w-[100px] inline-block">
          Email
        </span>
        <div className="h-10 my-2 ">
          <input
            className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
            name="email"
            type="email"
            placeholder="Email"
            onChange={set("email")}
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: "Enter a valid email",
              },
            })}
          ></input>
          <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
            {errors?.email?.message}
          </p>
        </div>
      </div>

      <div className="flex items-center mx-auto my-5 uppercase gap-x-2">
        <span className=" font-medium md:text-md font-sans w-[100px] inline-block">
          Phone
        </span>
        <div className="h-10 my-2 ">
          <input
            className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
            name="contact"
            type="number"
            placeholder="Phone"
            onChange={set("contact")}
            {...register("contact", {
              required: "Contact is required",
              minLength: {
                value: 10,
                message: "Min 10 characters",
              },
              maxLength: {
                value: 11,
                message: "Max 11 characters",
              },
            })}
          ></input>
          <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
            {errors?.contact?.message}
          </p>
        </div>
      </div>
    </>
  );
};

export default Form;
