import React from "react";
import { useSelector, useDispatch } from "react-redux";
import productSummary from "../../actions/productSummary";
import { patchPriceFormat } from "../../utils/tools";
const SelectQuantity = () => {
  const product = useSelector((state) => state.productSummary);
  const [customQuantity, setCustomQuantity] = React.useState(false);
  const dispatch = useDispatch();
  const quantity = [50, 100, 200, 300, 400, 500];

  const handelQuantity = (value) => {
    const formatQuantity = patchPriceFormat({
      quantity: { size: Number(value) },
      wide: parseFloat(product.wide),
      long: parseFloat(product.long),
    });
    dispatch(
      productSummary({
        ...product,
        finalPrice: formatQuantity.finalPrice,
        quantity: {
          size: formatQuantity.size,
          price: formatQuantity.finalPrice,
          roundedQuantity: formatQuantity.roundedQuantity,
        },
        customPrice: formatQuantity.pricePerPatch,
        pricePerPatch: formatQuantity.pricePerPatch,
      })
    );
  };

  return (
    <div className="flex flex-col gap-10 mt-6 lg:flex-row">
      <div className="space-y-4">
        <h3 className="text-xl uppercase tracking-[0.25em]">
          Select quantity
        </h3>

        <div className="flex gap-10">
          {quantity.map((el, i) => (
            <div className="flex flex-col gap-3" key={i}>
              <input
                checked={product.quantity.size === el ? true : false}
                type="radio"
                name="quantity"
                required
                value={el}
                className="justify-self-center"
                onChange={(e) => handelQuantity(e.target.value)}
                disabled={
                  customQuantity || !(product.long > 0 && product.wide > 0)
                }
              />
              <p>{el}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="space-y-4 lg:mt-7">
        <div className="flex items-center gap-3 mt-2">
          <input
            type="checkbox"
            name="custom"
            value="yes"
            checked={customQuantity}
            onClick={() => setCustomQuantity(!customQuantity)}
            className="w-5 h-5"
            disabled={!(product.long > 0 && product.wide > 0)}
          ></input>
          <label
            htmlFor="custom"
            className="text-xl text-gray-700 uppercase tracking-[0.25em]"
          >
            Custom quantity
          </label>
        </div>
        {customQuantity && (
          <div className="">
            <input
              name="customQuantity"
              className="p-2 rounded-md bg-[#e5e7eb]"
              type="number"
              placeholder="Custom Quantity"
              required
              onChange={(e) => handelQuantity(e.target.value)}
              value={quantity.size}
            ></input>
            {!(product.quantity.size >= 25) && (
              <p className="pl-2 mt-2 text-red-500 text-md md:text-sm">
                Quantity must be greater than 25
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectQuantity;
