/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserInfo, patchUserInfo, postUserInfo } from "../../../api/form";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API_URL } from "../../../config/index";
import Cookies from "js-cookie";
import ReCaptcha from "../../Recaptcha";

export default function Information() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [isVerified, setIsVarified] = React.useState("");
  const [customQuantity, setCustomQuantity] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const active = useSelector((state) => state.activeStep);
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const set = (label) => {
    return ({ target: { value } }) => {
      setValue(label, value);
    };
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      const token = Cookies.get("token");
      if (token) {
        const { data } = await axios.get(`${API_URL}auth/myself`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (data?.userDetails?.email) {
          setIsLoggedIn(true);
          setValue("firstname", data?.userDetails?.firstname);
          setValue("lastname", data?.userDetails?.lastname);
          setValue("email", data?.userDetails?.email);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUser = async () => {
    const email = localStorage.getItem("email");
    if (email) {
      const userInfo = await getUserInfo(email);
      if (userInfo.status === 200) {
        const { firstname, lastname, email, contact, _id } =
          userInfo.data.userExist;
        setValue("firstname", firstname);
        setValue("lastname", lastname);
        setValue("email", email);
        setValue("contact", contact);
        Cookies.set("userId", _id);
      }
    }
  };

  useEffect(() => {
    checkUser();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.category, product.name]);

  const onSubmit = async (data) => {
    let userInfo = "";
    userInfo = await getUserInfo(data.email.replace("+", "%2B"));
    if (userInfo.status === 200) {
      userInfo = await patchUserInfo(data);
      Cookies.set("userId", userInfo?.data?.id);
    } else {
      userInfo = await postUserInfo(data);
      Cookies.set("userId", userInfo?.data?.id);
    }

    const res = await axios.post(API_URL + "order/emailOrder/", {
      userId:
        userInfo?.data?.userExist?._id === undefined
          ? userInfo.data.id
          : userInfo.data.userExist._id,
      category: "Woven Patches",
      quantity: data.quantityOfProduct,
      subCategory: "nil",
    });
    if (res.status === 200) {
      navigate("/final");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center gap-y-2"
    >
      <p className="text-2xl font-bold text-center ">Enter Details</p>
      <div className="flex items-center mx-auto mt-5 uppercase gap-x-2 ">
        <span className=" md:text-md font-sans w-[100px] inline-block font-medium">
          First Name
        </span>
        <div className="h-10 my-2 ">
          <input
            className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
            name="firstname"
            type="text"
            placeholder="First Name"
            onChange={set("firstname")}
            {...register("firstname", {
              required: "First Name is required",
              minLength: {
                value: 2,
                message: "Atleast 2 characters",
              },
            })}
          ></input>
          <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
            {errors?.firstname?.message}
          </p>
        </div>
      </div>
      <div className="flex items-center mx-auto my-5 uppercase gap-x-2">
        <span className=" font-medium md:text-md font-sans w-[100px] inline-block">
          Last Name
        </span>
        <div className="h-10 my-2 ">
          <input
            className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
            name="lastname"
            type="text"
            placeholder="Last Name"
            onChange={set("lastname")}
            {...register("lastname", {
              required: "Last Name is required",
              minLength: {
                value: 2,
                message: "Atleast 2 characters",
              },
            })}
          ></input>
          <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
            {errors?.lastname?.message}
          </p>
        </div>
      </div>

      <div className="flex items-center mx-auto uppercase gap-x-2">
        <span className=" font-medium md:text-md font-sans w-[100px] inline-block">
          Email
        </span>
        <div className="h-10 my-2 ">
          <input
            className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
            name="email"
            type="email"
            placeholder="Email"
            onChange={set("email")}
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: "Enter a valid email",
              },
            })}
          ></input>
          <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
            {errors?.email?.message}
          </p>
        </div>
      </div>

      <div className="flex items-center mx-auto my-5 uppercase gap-x-2">
        <span className=" font-medium md:text-md font-sans w-[100px] inline-block">
          Phone
        </span>
        <div className="h-10 my-2 ">
          <input
            className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
            name="contact"
            type="number"
            placeholder="Phone"
            onChange={set("contact")}
            {...register("contact", {
              required: "Contact is required",
              minLength: {
                value: 10,
                message: "Min 10 characters",
              },
              maxLength: {
                value: 11,
                message: "Max 11 characters",
              },
            })}
          ></input>
          <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
            {errors?.contact?.message}
          </p>
        </div>
      </div>

      <div className="flex flex-col mx-auto my-2 uppercase">
        <span className="mx-2 font-sans md:font-black md:text-md">
          Quantity of product
        </span>
        <div className="h-10 my-2 ">
          <select
            className="block w-full px-4 py-2 pr-8 leading-tight bg-gray-200 border border-white rounded-full shadow appearance-none hover:border-gray-200 focus:outline-none focus:shadow-outline"
            name="quantityOfProduct"
            {...register("quantityOfProduct", {
              required: "This field is required",
            })}
            defaultValue={Number(watch("quantityOfProduct"))}
          >
            <option value="" selected hidden> ---Choose here--- </option>
            <option>50</option>
            <option>100</option>
            <option>250</option>
            <option>500</option>
            <option>1000</option>
          </select>
          <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
            {errors.quantityOfProduct?.message}
          </p>
        </div>
      </div>
      <div className="flex flex-col mx-auto my-5 uppercase">
        <span className="font-sans md:font-black md:text-md flex items-center">
          <input
            type="checkbox"
            name="custom"
            style={{ height: '20px', width: '20px' }}
            value="yes"
            checked={customQuantity}
            onClick={() => setCustomQuantity(!customQuantity)}
          ></input>
          <label htmlFor="custom" className="ml-4">
            Custom quantity
          </label>
        </span>
        {customQuantity && (
          <div className="h-10 my-2 ">
            <input
              className="w-full px-3 py-2 leading-tight text-gray-700 bg-gray-200 border rounded-full shadow appearance-none focus:outline-none focus:shadow-outline"
              name="customQuantity"
              type="number"
              placeholder="Custom Quantity"
              onChange={set("quantityOfProduct")}
              {...register("quantityOfProduct", {
                required: "Custom Quantity is required",
                min: {
                  value: 50,
                  message: "Min Quantity 50",
                },
                // maxLength: {
                //     value: 11,
                //     message: "Max 11 characters",
                // }
              })}
            ></input>
            <p className="pl-2 mt-2 text-xs text-red-500 md:text-sm">
              {errors.customQuantity?.message}
            </p>
            {!(Number(watch("quantityOfProduct")) > 50) && (
              <p className="pl-2 mt-2 text-red-500 text-md md:text-sm">
                Quantity must be 50 or above.
              </p>
            )}
          </div>
        )}
      </div>

      <div className="flex items-center justify-center w-full mt-4 ">
        <ReCaptcha
          OnClick={(isvarify) => setIsVarified(isvarify)}
        // reset={isProcessing}
        />
      </div>
      <div className="items-center w-full my-10 text-center uppercase md:text-md max-w-5xl mx-auto">
        Note:- PLEASE SEND THE TECH PACK AND ART WORK TO{" "}
        <span
          className="font-semibold cursor-pointer"
          onClick={() => navigator.clipboard.writeText("info@periapparel.com")}
        >
          INFO@PERIAPPAREL.COM
        </span>{" "}
        AND SOMEONE WILL GET IN TOUCH WITH YOU REGARDING order.
      </div>

      <button
        disabled={!isVerified}
        className="self-start block px-10 py-2 mx-auto text-2xl font-semibold text-white transition-all duration-300 ease-in-out bg-blue-600 rounded-md disabled:bg-blue-400 hover:bg-blue-700 "
        type="submit"
      >
        Submit
      </button>
    </form>
  );
}
