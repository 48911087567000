import { createStore, combineReducers, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
import productSummary from './reducers/productSummaryReducer';
import activeStep from './reducers/activeStepReducer';
import landing from './reducers/landingReducer';
import details from './reducers/detailsReducer';
import global from './reducers/globalReducer';
const reducer = combineReducers({ productSummary, activeStep, landing , details, global});

 
// const userInfoFromStorage = localStorage.getItem('userInfo')
//   ? JSON.parse(localStorage.getItem('userInfo'))
//   : null;

// const initialState = {
//   userLogin: { userInfo: userInfoFromStorage },
// };

// const middleware = [thunk];

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
