import axios from "axios";
import { API_URL } from "../config";

export const getPatches = async() =>{
  try{
   const res =  await axios.get(`${API_URL}patcheSubcategory/all`)
   return res
  }catch(err){

  }
}
export const getPatchesFilter = async() =>{
  try{
   const res =  await axios.get(`${API_URL}patches/filter?category=${'Chenille'}`)
   return res
  }catch(err){

  }
}