import axios from 'axios';
import { API_URL } from '../config/index';

const apiUrl = API_URL + 'feedback'; 

export const getFeedback = async () => { 

  try { 
      
    const response = await axios.get(apiUrl+"/get");
  
    return response;

  } catch (err) {  
    return err.response.data
  }
};



export const postFeedback = async (form) => { 

  const {message,email,location,name} = form;
  
  try { 
    const response = await axios.post(apiUrl+"/add",{ 
         message,
         username:name,
         location,
         isVisible:false 
    });      
    return response;
    
  } catch (err) {   
    // console.log(err.response);
    return err.response;
  }
};

