import React, { useEffect } from "react";
import Navbar from "../navbar/Navbar";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import Footer from "../footer/Footer";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API_URL } from "../../config";
import { useState } from "react";

const ContactUs = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  console.log(errors);

  const onSubmit = async (data) => {
    console.log(data);
    const { name, email, phone, message } = data;
    let dataToSend;
    if (message) {
      dataToSend = { name, email, contact: phone, message };
    } else {
      dataToSend = { name, email, contact: phone };
    }

    try {
      setIsSubmitting(true);

      const { data } = await axios.post(`${API_URL}contacts`, dataToSend);
      reset({ name: "", email: "", phone: "", message: "" });
      setIsSubmitting(false);
      console.log(data);
    } catch (err) {
      setIsSubmitting(false);
      console.log(err);
      if (err.message) {
        alert(err.message);
      }
    }
  };
  return (
    <div className="min-h-screen">
      <Navbar />

      <div className="px-6 sm:px-12">
        <h1 className="border-t-[2px] border-t-blue-900 bg-gray-300 py-4 text-center text-3xl font-semibold">
          Contact US
        </h1>
        <p className="mt-20">
          Any questions on the chenille patches , jackets or garments , please
          submit your question and we will get back to you as soon as possible.
        </p>
        <ul className="list-disc ml-5">
          <li>
            On a custom chenille patch, we can run up to six colors and on the
            felts we can have up to 3 layers of felt. You can also submit an
            artwork or design vector diagram.
          </li>
          <li>
            For the chenille patch orders, either submit the art work or a
            picture of your current patch you have, and please provide as much
            detail as possible, including the colors and layout.
          </li>
        </ul>
        <div className="flex flex-col-reverse sm:flex-row ">
          <div className="w-full sm:w-[50%]">
            <h2 className="text-blue-600 text-xl font-bold mt-8">
              CONTACT CHENILLE CUSTOM PATCHES
            </h2>
            <div className="mt-8 flex items-center gap-x-[15px] sm:gap-x-[30px] ">
              <div className="w-[20%] sm:w-[10%]">
                <div className=" flex items-center justify-center text-white bg-blue-600 w-[70px] h-[70px] rounded-full">
                  <AiOutlineMail size={30} />
                </div>
              </div>
              <div className="w-[80%]">
                <p className="text-blue-600 underline">Email</p>
                <p className="">info@periapparel.com</p>
                <p className="">or use our quick contact form</p>
              </div>
            </div>

            <div className="mt-8 flex items-center gap-x-[15px] sm:gap-x-[30px] ">
              <div className="w-[20%] sm:w-[10%]">
                <div className=" flex items-center justify-center text-white bg-blue-600  w-[70px] h-[70px] rounded-full">
                  <BsFillTelephoneFill size={30} />
                </div>
              </div>

              <div className="w-[80%]">
                <p className="text-blue-600 underline">Phone</p>
                <p className="">+1 (469) 334-3185</p>
                <p className=""> PLEASE EMAIL US IF YOU CAN'T GET THROUGH</p>
              </div>
            </div>

            <div className="mt-8 flex items-center gap-x-[15px] sm:gap-x-[30px] ">
              <div className="w-[20%] sm:w-[10%]">
                <div className=" flex items-center justify-center text-white bg-blue-600 w-[70px] h-[70px] rounded-full">
                  <ImLocation size={30} />
                </div>
              </div>
              <div className="w-[80%]">
                <p className="text-blue-600 underline">Location</p>
                <p className="">
                  500 N Central Expy, Suite 500, Plano Texas 75074
                </p>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-[50%]">
            <h2 className="mt-8 pb-2 border-b-blue-600 text-blue-600 font-bold text-xl border-b-[2px]">
              WE'RE HERE TO HELP
            </h2>
            <div className="pt-2 w-[90%]">
              <p className="">
                Name <span>*</span>
              </p>
              <input
                {...register("name", { required: true, maxLength: 30 })}
                type="text"
                className="border-black border-[4px] px-2 py-1 w-full"
                style={{ border: "1px solid black" }}
              />
              {errors?.name?.type == "required" && (
                <p className="text-red-500">Please fill the Name</p>
              )}
            </div>
            <div className="pt-2 w-[90%]">
              <p className="">
                Email <span>*</span>
              </p>
              <input
                {...register("email", {
                  required: true,
                  maxLength: 30,
                  pattern:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                type="email"
                className="border-black border-[4px] px-2 py-1 w-full"
                style={{ border: "1px solid black" }}
              />
              {errors?.email?.type == "required" && (
                <p className="text-red-500">Please fill the Email</p>
              )}
              {errors?.email?.type == "pattern" && (
                <p className="text-red-500">Please enter a valid Email</p>
              )}
            </div>
            <div className="pt-2 w-[90%]">
              <p className="">
                Phone <span>*</span>
              </p>
              <input
                {...register("phone", { required: true })}
                type="text"
                className="border-black border-[4px] px-2 py-1 w-full"
                style={{ border: "1px solid black" }}
              />
              {errors?.phone?.type == "required" && (
                <p className="text-red-500">Please fill the Phone</p>
              )}
            </div>

            <div className="pt-2 w-[90%]">
              <p className="">Message</p>
              <textarea
                className="border-black border-[4px] h-[100px]  px-2 py-1 w-full"
                style={{ border: "1px solid black" }}
                {...register("message", { required: false })}
              ></textarea>
            </div>

            <div className="flex my-3 mb-8 ">
              <button
                disabled={isSubmitting}
                onClick={handleSubmit(onSubmit)}
                className="boxshadow1 bg-red-600 text-white font-bold px-4 sm:px-10 py-2 shadow-md"
              >
                {isSubmitting ? "Sending...." : "SEND MESSAGE"}
              </button>
              <button
                className="boxshadow1 ml-8 sm:ml-8 bg-gray-300 text-black font-bold px-4 sm:px-10 py-2 shadow-lg"
                onClick={() =>
                  reset({ name: "", email: "", phone: "", message: "" })
                }
              >
                RESET
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
