import React from "react";
import { useSelector, useDispatch } from "react-redux";
import productSummary from "../../actions/productSummary";
import { patchPriceFormat } from "../../utils/tools";
const SelectProductSize = () => {
  const options = Array.from(
    { length: (15 - 3) / 0.5 + 1 },
    (_, index) => 3 + index * 0.5
  );
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-6 mt-6">
      <h4 className="text-xl  uppercase tracking-[0.25em]">
        Select patch size and quantity
      </h4>
      <div className="flex gap-10 ">
        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
          <select
            className={`bg-[#e5e7eb] p-2 rounded text-center font-semibold ${
              product.editImage !== null && "cursor-not-allowed"
            }`}
            disabled={product.editImage !== null && true}
            required
            value={
              Number(product.wide) === 0 ? "Select Width" : Number(product.wide)
            }
            onChange={(e) => {
              if (product.quantity.size !== 0) {
                dispatch(
                  productSummary({
                    ...product,
                    wide: e.target.value,
                    finalPrice: patchPriceFormat({
                      quantity: product.quantity,
                      wide: e.target.value,
                      long: product.long,
                    }).finalPrice,
                    quantity: {
                      ...product.quantity,
                      price: patchPriceFormat({
                        quantity: product.quantity,
                        wide: e.target.value,
                        long: product.long,
                      }).finalPrice,
                    },
                    customPrice: patchPriceFormat({
                      quantity: product.quantity,
                      wide: e.target.value,
                      long: product.long,
                    }).pricePerPatch,
                    pricePerPatch: patchPriceFormat({
                      quantity: product.quantity,
                      wide: e.target.value,
                      long: product.long,
                    }).pricePerPatch,
                  })
                );
              } else {
                dispatch(productSummary({ ...product, wide: e.target.value }));
              }
            }}
          >
            <option  value="">
              Select Width
            </option>
            {options.map((el, i) => (
              <option key={i} value={el} className="bg-white">
                {el}
              </option>
            ))}
          </select>
          <p className="">Width (in inches)</p>
        </div>
        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
          <select
            className={`bg-[#e5e7eb] p-2 rounded text-center font-semibold ${
              product.editImage !== null && "cursor-not-allowed"
            }`}
            disabled={product.editImage !== null && true}
            required
            value={
              Number(product.long) === 0
                ? "Select Height"
                : Number(product.long)
            }
            onChange={(e) => {
              if (product.quantity.size !== 0) {
                dispatch(
                  productSummary({
                    ...product,
                    long: e.target.value,
                    finalPrice: patchPriceFormat({
                      quantity: product.quantity,
                      wide: product.wide,
                      long: e.target.value,
                    }).finalPrice,
                    quantity: {
                      ...product.quantity,
                      price: patchPriceFormat({
                        quantity: product.quantity,
                        wide: product.wide,
                        long: e.target.value,
                      }).finalPrice,
                    },
                    customPrice: patchPriceFormat({
                      quantity: product.quantity,
                      wide: product.wide,
                      long: e.target.value,
                    }).pricePerPatch,
                    pricePerPatch: patchPriceFormat({
                      quantity: product.quantity,
                      wide: product.wide,
                      long: e.target.value,
                    }).pricePerPatch,
                  })
                );
              } else {
                dispatch(productSummary({ ...product, long: e.target.value }));
              }
            }}
          >
            <option value="">
              Select Height
            </option>
            {options.map((el, i) => (
              <option key={i} value={el} className="bg-white">
                {el}
              </option>
            ))}
          </select>
          <p className="">Height (in inches)</p>
        </div>
      </div>
    </div>
  );
};

export default SelectProductSize;
