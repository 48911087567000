import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import activeStep from "../../actions/activeStep";
import productSummary from "../../actions/productSummary";
import { API_URL } from "../../config/index";
import { gtag } from "ga-gtag";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

export default function Information() {
  const global = useSelector((state) => state.global);
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const orderId = global?.orderId;

  useEffect(() => {
    // <!-- Event snippet for Purchase conversion page -->
    gtag("event", "conversion", {
      send_to: "AW-10852884839/YZO5CNalhoUYEOfCh7co",
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const apiUrl = API_URL;

  const handleClick = (e) => {
    dispatch(activeStep(0));
    navigate("/");
  };

  return (
    <>
      <Navbar />
      <div className="text-center  min-h-[calc(100vh-96px)]  flex flex-col items-center justify-center">
        <p className="text-[#029e02] text-2xl  font-bold ">
        Order Inquiry Submitted Successful.
        </p>
        <p className="mt-2">Thank you for your order interest. </p>
        {orderId && (
          <p
            className=""
           
          >
            Order Id : {orderId}
          </p>
        )}
        <p className="mt-2">
        Someone from our team will reach out to you to discuss about your order.
        </p>
        <p className="mt-2">
          To provide more information email us at{" "}
          <span
            className="font-semibold cursor-pointer"
            onClick={() =>
              navigator.clipboard.writeText("info@periapparel.com")
            }
          >
            info@periapparel.com
          </span>
          {/* <ion-icon name="mail" className="text-xl"></ion-icon> */}
        </p>
        <div className="flex items-center justify-center w-full mt-5 mb-2 gap-x-4">
          <button
            className="p-2 border-2 cursor-pointer hover:text-gray-600 border-slate-200 "
            onClick={handleClick}
          >
            Continue shopping
          </button>
          {Cookies.get("token") && (
            <>
              <Link to="/orders">
                <button className="p-2 border-2 hover:text-gray-600 border-slate-200">
                  View Orders
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
