/* eslint-disable no-unused-vars */
import React from "react";
import ReactCrop from "react-image-crop";
import Ruler from "@scena/react-ruler";
import EditorTool from "./EditorTool";
import { useSelector, useDispatch } from "react-redux";
const ImageEditor = ({
  imgRef,
  imageUrl,
  setImageDimensions,
  width,
  height,
  fitImage,
  setFitImage,
  imageDimensions,
  crop,
  setCrop,
}) => {
  const getImageDimensions = (e) => {
    setImageDimensions({
      width: e.target.naturalWidth,
      height: e.target.naturalHeight,
    });
  };

  const imageRatio = imageDimensions.width / imageDimensions.height;
  const patchRatio = width / height;

  const value =
    imageRatio >= patchRatio
      ? {
          height: `${fitImage}px`,
          objectFit: "cover",
          objectPosition: "0% 50%",
        }
      : {
          width: `${fitImage}px`,
          objectFit: "cover",
          objectPosition: "0% 50%",
        };

  return (
    <div className="lg:w-[55%] border-2 rounded h-fit">
      <EditorTool
        setCrop={setCrop}
        imageDimensions={imageDimensions}
        width={width}
        height={height}
        setFitImage={setFitImage}
      />

      <div className="flex">
        <div className=" w-[30px] h-[30px] bg-gray-500"></div>
        <Ruler
          type={"horizontal"}
          style={{
            height: "30px",
            width: "100%",
          }}
          zoom={41.8}
          unit={1}
          scrollPos={0}
          range={[0, 15]}
        />
      </div>
      <div className="flex justify-start">
        <Ruler
          type={"vertical"}
          style={{
            width: "30px",
          }}
          zoom={41.8}
          unit={1}
          range={[0, 15]}
        />
        <div className=" h-[650px] w-full ">
          {imageUrl.length !== 0 && (
            <ReactCrop
              crop={crop}
              className="w-full h-full"
              onChange={(c) => setCrop({ ...c, width: width, height: height })}
            >
              <img
                ref={imgRef}
                src={imageUrl}
                alt=""
                style={{ ...value }}
                onLoad={(e) => getImageDimensions(e)}
              />
            </ReactCrop>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;
