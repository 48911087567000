import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const FilterPatch = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categories = searchParams.getAll("categories");
  const url = location.search;
  return (
    <div className="flex flex-col gap-4 lg:flex-row lg:space-x-10 p-2 md:p-0">
      <div className="flex flex-col gap-6">
        <h3 className="text-xl font-bold">Categories</h3>
        <div className="flex flex-col gap-2 ">
          <div className="flex gap-4 text-gray-500 ">
            <input
              type="checkbox"
              name="Chenille"
              value="Chenille"
              checked={categories.includes("Chenille")}
              onChange={() => {
                if (categories.includes("Chenille")) {
                  navigation(
                    `/patches${location.search.replace(
                      "&categories=Chenille",
                      ""
                    )}`
                  );
                } else {
                  navigation(
                    `/patches?${url.replace("?", "")}&categories=Chenille`
                  );
                }
              }}
            />
            <label className="text-lg">Chenille</label>
          </div>
          <div className="flex gap-4 text-gray-500 ">
            <input
              type="checkbox"
              name="Embroidery"
              value="Embroidery"
              checked={categories.includes("Embroidery")}
              onChange={() => {
                if (categories.includes("Embroidery")) {
                  navigation(
                    `/patches${location.search.replace(
                      "&categories=Embroidery",
                      ""
                    )}`
                  );
                } else {
                  navigation(
                    `/patches?${url.replace("?", "")}&categories=Embroidery`
                  );
                }
              }}
            />
            <label className="text-lg">Embroidery</label>
          </div>
          <div className="flex gap-4 text-gray-500 ">
            <input
              type="checkbox"
              name="Woven"
              value="Woven"
              checked={categories.includes("Woven")}
              onChange={() => {
                if (categories.includes("Woven")) {
                  navigation(
                    `/patches${location.search.replace(
                      "&categories=Woven",
                      ""
                    )}`
                  );
                } else {
                  navigation(
                    `/patches?${url.replace("?", "")}&categories=Woven`
                  );
                }
              }}
            />
            <label className="text-lg">Woven</label>
          </div>
          <div className="flex gap-4 text-gray-500">
            <input
              type="checkbox"
              name="Vegan Leather"
              value="Vegan Leather"
              checked={categories.includes("Vegan Leather")}
              onChange={() => {
                if (categories.includes("Vegan Leather")) {
                  navigation(
                    `/patches${location.search.replace(
                      "&categories=Vegan%20Leather",
                      ""
                    )}`
                  );
                } else {
                  navigation(
                    `/patches?${url.replace("?", "")}&categories=Vegan Leather`
                  );
                }
              }}
            />
            <label className="w-full text-lg  lg:w-[120px]">Vegan Leather</label>
          </div>
        </div>
      </div>
      <div className="w-1 min-h-full bg-[#d1d5db]"></div>
    </div>
  );
};

export default FilterPatch;
