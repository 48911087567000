import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Header from "./components/header/Header";
import Getstarted from "./components/getstarted/Getstarted";
import Information from "./components/information/Information";
import CustomizePage from "./components/CustomizePage/CustomizePage";
import UpgradePage from "./components/UpgradePage/UpgradePage";
import ArtworkPage from "./components/ArtworkPage/ArtworkPage";
import PaymentPage from "./components/PaymentPage/PaymentPage";
import Payment from "./components/CheckoutPage/Payment";
import FinalPage from "./components/FinalPage/FinalPage";
import AboutUsPage from "./components/AboutUsPage/AboutUsPage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChenilleLandingPage from "./components/ChenilleLandingPage/ChenilleLandingPage";
import ContactUs from "./components/ContactUs/ContactUs";
import { Link } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import NotFound from "./components/NotFound";
import Payment2 from "./components/CheckoutPage/Payment2";
import RefundPolicy from "./components/RefundPolicy";
import Login from "./components/LoginPage/Login";
import Registeration from "./components/Registeration/Registeration";
import ForgotPassword from "./components/LoginPage/ForgotPassword";
import Tc from "./components/Tc";
import PP from "./components/PP";
import MyProfile from "./components/MyProfile";
import AddAddress from "./components/Registeration/AddAddress";
import Cart from "./components/Cart/MyCart";
import Checkout from "./components/Checkout/Checkout";
import MyOrders from "./components/Orders/MyOrders";
import VerifyEmail from "./components/VerifyEmail";
import JacketsDemo from "./components/Jackets/JacketsDemo";
import Cookies from "js-cookie";
import Garments from "./components/Garments/Garments";
import Test from "./test/Test";
import Patches from "./components/Patches/Patches";
import PatchesOrder from "./components/PatchesOrder/PatchesOrder";

function App() {
  const active = useSelector((state) => state.activeStep);
  const [token, setToken] = useState("");
  useEffect(() => {
    const to = Cookies.get("token");
    if (to) {
      setToken(to);
    } else {
      setToken("");
    }
  }, []);

  return (
    <div className="w-full mx-auto max-w-[85rem]">
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="/select" element={<Getstarted />} />

        <Route path="/jackets" element={<JacketsDemo />} />
        <Route path="/patches" element={<Patches />} />
        <Route path="/patch/:id" element={<PatchesOrder />} />
        <Route path="/garments" element={<Garments />} />

        <Route path="/register" element={<Registeration />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />

        <Route path="/myprofile" element={<MyProfile />} />

        <Route path="/addaddress" element={<AddAddress />} />
        <Route path="/orders" element={<MyOrders />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
        <Route path="/test" element={<Test />} />

        {active >= 2 && <Route path="/info" element={<Information />} />}App

        {active >= 5 && <Route path="/customize" element={<CustomizePage />} />}

        {active >= 4 && <Route path="/special" element={<UpgradePage />} />}

        {active >= 3 && <Route path="/upload" element={<ArtworkPage />} />}

        {active >= 6 && (
          <Route path="/shipping-info" element={<PaymentPage />} />
        )}

        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />

        <Route path="/ksjsjfklalkfjjaljlfjlja" element={<Payment2 />} />

        <Route path="/about-us" element={<ContactUs />} />

        <Route path="/final" element={<FinalPage />} />

        <Route path="/chenille-patches" element={<ChenilleLandingPage />} />

        <Route path="/refund-policy" element={<RefundPolicy />} />

        <Route path="/terms-and-conditions" element={<Tc />} />

        <Route path="/privacypolicy" element={<PP />} />

        {/* <Route path="/jackets-template" element={<JacketsDemo />} /> */}

        {/* <Route path="/garments" element={<Garments />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
