import { quantityPrice, quantityPriceEmbroidery, quantityPriceVeganLeather } from "../components/utils";

export const pixelsToInches = (pixels) => {
  const pixelsPerInch = 41.8;
  const inches = pixels / pixelsPerInch;
  return Math.round(inches * 2) / 2;
};

export const inchesToPixels = (inches) => {
  const pixelsPerInch = 41.8;
  const pixels = inches * pixelsPerInch;
  return pixels;
};
export const convertText = (word) => {
  return word
    .replace("-", " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const patchPriceFormat = ({ quantity, wide, long }) => {
  const size = quantity.size;
  const key = Math.round(wide * long);
  let roundedQuantity;
  let pricePerPatch = quantityPrice[key];
  switch (true) {
    case size >= 25 && size <= 34:
      roundedQuantity = 25;
      break;
    case size >= 35 && size <= 59:
      roundedQuantity = 50;
      pricePerPatch -= 0.75;
      break;
    case size >= 60 && size <= 84:
      roundedQuantity = 75;
      pricePerPatch -= 1;
      break;
    case size >= 85 && size <= 119:
      roundedQuantity = 100;
      pricePerPatch = pricePerPatch - 1.25;
      // console.log(pricePerPatch);
      break;
    case size >= 120 && size <= 169:
      roundedQuantity = 150;
      pricePerPatch -= 1.5;
      break;
    case size >= 170 && size <= 219:
      roundedQuantity = 200;
      pricePerPatch -= 1.75;
      break;
    case size >= 220 && size <= 274:
      roundedQuantity = 250;
      pricePerPatch -= 2;
      break;
    case size >= 275 && size <= 300:
      roundedQuantity = 300;
      pricePerPatch -= 2.25;
      break;
    case size > 300:
      roundedQuantity = 300;
      pricePerPatch -= 2.25;
      break;
    default:
  }
  pricePerPatch =
    Math.round((pricePerPatch * 1.04 + Number.EPSILON) * 100) / 100;
  pricePerPatch = Math.round((pricePerPatch + Number.EPSILON) * 100) / 100;
  return {
    roundedQuantity,
    pricePerPatch,
    finalPrice: Math.round((pricePerPatch * size + Number.EPSILON) * 100) / 100,
    size,
  };
};
export const patchPriceFormatEmbroidery = ({ quantity, wide, long }) => {
  const size = quantity.size;
  const key = Math.round(wide * long);
  let roundedQuantity;
  let pricePerPatch = quantityPriceEmbroidery[key];
  switch (true) {
    case size >= 25 && size <= 49:
      roundedQuantity = 25;
      break;
    case size >= 50 && size <= 69:
      roundedQuantity = 51;
      break;
    case size >= 70 && size <= 119:
      roundedQuantity = 100;
      pricePerPatch -= 0.75;
      break;
    case size >= 120 && size <= 169:
      roundedQuantity = 150;
      pricePerPatch = pricePerPatch - 1;
      // console.log(pricePerPatch);
      break;
    case size >= 170 && size <= 239:
      roundedQuantity = 200;
      pricePerPatch -= 1.25;
      break;
    case size >= 240 && size <= 339:
      roundedQuantity = 300;
      pricePerPatch -= 1.50;
      break;
    case size >= 340 && size <= 439:
      roundedQuantity = 400;
      pricePerPatch -= 1.75;
      break;
    case size >= 440 && size <= 699:
      roundedQuantity = 500;
      pricePerPatch -= 2;
      break;
    case size >= 700 && size <= 1000:
      roundedQuantity = 1000;
      pricePerPatch -= 2.25;
      break;
    case size <= 1000:
      roundedQuantity = 1000;
      pricePerPatch -= 2.25;
      break;
    default:
  }
  pricePerPatch =
    Math.round((pricePerPatch  * 1.04 + Number.EPSILON) * 100) / 100;
  pricePerPatch = Math.round((pricePerPatch + Number.EPSILON) * 100) / 100;


  return {
    roundedQuantity,
    pricePerPatch,
    finalPrice: Math.round((pricePerPatch * size + Number.EPSILON) * 100) / 100,
    size,
  };
};
export const patchPriceFormatVeganLeather = ({ quantity, wide, long }) => {
  const size = quantity.size;
  const key = Math.round(wide * long);
  let roundedQuantity;
  let pricePerPatch = quantityPriceVeganLeather[key];
  console.log(pricePerPatch)
  switch (true) {
    case size >= 25 && size <= 49:
      roundedQuantity = 25;
      break;
    case size >= 50 && size <= 69:
      roundedQuantity = 51;
      break;
    case size >= 70 && size <= 119:
      roundedQuantity = 100;
      pricePerPatch -= 0.75;
      break;
    case size >= 120 && size <= 169:
      roundedQuantity = 150;
      pricePerPatch = pricePerPatch - 1;
      // console.log(pricePerPatch);
      break;
    case size >= 170 && size <= 239:
      roundedQuantity = 200;
      pricePerPatch -= 1.25;
      break;
    case size >= 240 && size <= 339:
      roundedQuantity = 300;
      pricePerPatch -= 1.50;
      break;
    case size >= 340 && size <= 439:
      roundedQuantity = 400;
      pricePerPatch -= 1.75;
      break;
    case size >= 440 && size <= 699:
      roundedQuantity = 500;
      pricePerPatch -= 2;
      break;
    case size >= 700 && size <= 1000:
      roundedQuantity = 1000;
      pricePerPatch -= 2.25;
      break;
    case size <= 1000:
      roundedQuantity = 1000;
      pricePerPatch -= 2.25;
      break;
    default:
  }
  pricePerPatch =
    Math.round((pricePerPatch  * 1.04 + Number.EPSILON) * 100) / 100;
  pricePerPatch = Math.round((pricePerPatch + Number.EPSILON) * 100) / 100;


  return {
    roundedQuantity,
    pricePerPatch,
    finalPrice: Math.round((pricePerPatch * size + Number.EPSILON) * 100) / 100,
    size,
  };
};

export const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const convertCanvasToFile = (canvas, name, type) => {
  const dataUrl = canvas.toDataURL(type);
  const blob = dataURItoBlob(dataUrl);

  const file = new File([blob], name, { type: type });

  return {
    url: URL.createObjectURL(blob),
    file: file,
  };
};

export const imageWidthHeight = async (file) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();

      // Event listener for when the FileReader has successfully read the file
      reader.onload = function (e) {
        const image = new Image();

        // Event listener for when the image has been loaded
        image.onload = function () {
          // Now, you can get the width and height of the image
          const width = image.width;
          const height = image.height;

          console.log("Width:", width);
          console.log("Height:", height);

          // Resolve the promise with the width and height
          resolve({ width, height });
        };

        // Event listener for handling image load errors
        image.onerror = function () {
          // Reject the promise if there's an error loading the image
          reject("Error loading image");
        };

        // Set the source of the image to the result of the FileReader
        image.src = e.target.result;
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    } else {
      // Reject the promise if no file is provided
      reject("No file provided");
    }
  });
};
