import React from "react";
import { useDispatch, useSelector } from "react-redux";
import productSummary from "../../../../actions/productSummary";

const Instructions = () => {
  const product = useSelector((state) => state.productSummary);
  const dispatch = useDispatch();
  return (
    <div className="font-bold  text-gray-700 mt-10 uppercase tracking-[0.25em] pl-4 sm:pl-8 md:pl-0 xl:flex flex-col">
      Add instructions
      <textarea
        className="mt-5 w-[85%] sm:w-3/4 outline-0  ml-4 sm:ml-8 md:ml-0 h-[100px]  border border-[#807b7b] rounded-md p-2 placeholder:text-[#807b7b] placeholder:font-medium font-medium"
        placeholder="Add instructions for better customization "
        name="instructions"
        onChange={(e) => {
          dispatch(
            productSummary({ ...product, instructions: e.target.value })
          );
        }}
        value={
          product.instructions.length === 0 ? undefined : product.instructions
        }
      ></textarea>
    </div>
  );
};

export default Instructions;
