import React, { useEffect, useState } from "react";
import { states } from "../PaymentPage/PaymentPage";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import axios from "axios";
import { API_URL } from "../../config";
import { Spin, message } from "antd";
import Cookies from "js-cookie";

const AddAddress = () => {
  const [state, setState] = useState(states[0]);
  const [processing, setProcessing] = useState(false);
  const token = Cookies.get("token");
  const nav = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // e.preventDefault();
    // console.log(data);
    const {
      firstname,
      lastname,
      address,
      contact,
      zip,
      city,
      apartment,
      company,
    } = data;
    try {
      setProcessing(true);
      const response = await axios.post(API_URL + "address/add", {
        country: "United States",
        firstname,
        lastname,
        address,
        contact,
        zip,
        city,
        state,
        ...(apartment && { apartment }),
        ...(company && { company }),
      });
      // console.log(response?.data);
      if (response.data.id) {
        const updteProfileResponse = await axios.patch(
          `${API_URL}auth/myself`,
          { shipId: response.data.id, billId: response.data.id },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
      setProcessing(false);
      message.success("Address added ");
      reset();
      nav("/myprofile");
    } catch (err) {
      // console.log(err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      const token = Cookies.get("token");
      // console.log("token=======>", token);
      try {
        if (token) {
          setProcessing(true);
          const { data } = await axios.get(`${API_URL}auth/myself`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (data.userDetails.shipId) {
            const addressResponse = await axios.get(`${API_URL}address/fetch`, {
              params: { _id: data.userDetails.shipId },
            });
            if (addressResponse.data.addressExist._id) {
              const {
                address,
                city,
                company,
                contact,
                firstname,
                lastname,
                state,
                zip,
                apartment,
              } = addressResponse.data.addressExist;
              setValue("firstname", firstname);
              setValue("lastname", lastname);
              setValue("address", address);
              setValue("city", city);
              setValue("company", company);
              setValue("contact", contact);
              setValue("zip", zip);
              setValue("company", company);
              setValue("apartment", apartment);
              setState(state);
            }
          }
        }
        setProcessing(false)
      } catch (err) {
        // console.log(err);
        setProcessing(false)
      }
      setProcessing(false)
    })();
  }, []);

  return (
    <>
      <Navbar />
      <div className="min-h-screen   mx-auto">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full md:w-[70%] bg-white p-4 rounded-xl  self-start mx-auto"
        >
          <p className="font-bold text-xl">Add Address </p>
          {
            //    (!shipid && clientSecret.length<=0 )&&

            <div className="">
              <div className="flex mt-4 gap-x-5">
                <div className="w-1/2">
                  <div
                    className={`border  ${
                      errors?.firstname?.message
                        ? "border-red-600"
                        : "border-black"
                    } relative  w-full rounded-[4px]`}
                  >
                    <p
                      className={`text-xs ${
                        errors?.firstname?.message
                          ? "text-red-600"
                          : "text-black"
                      } bg-white -top-[20%] left-2 leading-[100%] px-2  absolute `}
                    >
                      First Name*
                    </p>
                    <input
                      className="w-full outline-none p-[6px] rounded-[4px]"
                      alt=""
                      name="firstname"
                      type="text"
                      {...register("firstname", {
                        required: "First Name is required",
                        minLength: {
                          value: 2,
                          message: "Atleast 2 characters",
                        },
                        maxLength: {
                          value: 50,
                          message: "Max 50 characters",
                        },
                      })}
                    />
                  </div>
                  <p className="text-red-600 text-xs">
                    {errors?.firstname?.message}
                  </p>
                </div>
                <div className="w-1/2">
                  <div
                    className={`border  ${
                      errors?.lastname?.message
                        ? "border-red-600"
                        : "border-black"
                    } relative  w-full rounded-[4px]`}
                  >
                    <p
                      className={`text-xs bg-white ${
                        errors?.lastname?.message
                          ? "text-red-600"
                          : "text-black"
                      } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                    >
                      Last Name*
                    </p>
                    <input
                      className="w-full outline-none p-[6px] rounded-[4px]"
                      alt=""
                      {...register("lastname", {
                        required: "Last Name is required",
                        minLength: {
                          value: 2,
                          message: "Atleast 2 characters",
                        },
                        maxLength: {
                          value: 50,
                          message: "Max 50 characters",
                        },
                      })}
                    />
                  </div>
                  <p className="text-red-600 text-xs">
                    {errors?.lastname?.message}
                  </p>
                </div>
              </div>

              <div
                className={` mt-4 border ${
                  errors?.address?.message ? "border-red-600" : "border-black"
                } relative   rounded-[4px]`}
              >
                <p
                  className={`text-xs bg-white ${
                    errors?.address?.message ? "text-red-600" : "text-black"
                  } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                >
                  Address*
                </p>
                <input
                  className="w-full outline-none p-[6px] rounded-[4px]"
                  alt=""
                  {...register("address", {
                    required: "address is required",
                    minLength: {
                      value: 5,
                      message: "Atleast 5 characters",
                    },
                    maxLength: {
                      value: 60,
                      message: "Max 60 characters",
                    },
                  })}
                />
              </div>
              <p className="text-red-600 text-xs">{errors?.address?.message}</p>
              <div className="flex mt-4 gap-x-5">
                <div className="w-1/2">
                  <div
                    className={`border ${
                      errors?.zip?.message ? "border-red-600" : "border-black"
                    } relative  w-full rounded-[4px]`}
                  >
                    <p
                      className={`text-xs bg-white ${
                        errors?.zip?.message ? "text-red-600" : "text-black"
                      } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                    >
                      Zip Code*
                    </p>
                    <input
                      className="w-full outline-none p-[6px] rounded-[4px]"
                      alt=""
                      {...register("zip", {
                        required: "Zip Code is required",
                        minLength: {
                          value: 5,
                          message: "Atleast 5 characters",
                        },
                        maxLength: {
                          value: 10,
                          message: "Max 10 characters",
                        },
                      })}
                    />
                  </div>
                  <p className="text-red-600 text-xs">{errors?.zip?.message}</p>
                </div>
                <div className="w-1/2">
                  <div
                    className={`border ${
                      errors?.apartment?.message
                        ? "border-red-600"
                        : "border-black"
                    } relative  w-full rounded-[4px]`}
                  >
                    <p
                      className={`text-xs bg-white ${
                        errors?.apartment?.message
                          ? "text-red-600"
                          : "text-black"
                      } -top-[20%] left-2 leading-[100%] px-2  absolute `}
                    >
                      Apt/Suit
                    </p>
                    <input
                      className="w-full outline-none p-[6px] rounded-[4px]"
                      alt=""
                      {...register("apartment", {
                        minLength: {
                          value: 2,
                          message: "Atleast 2 characters",
                        },
                        maxLength: {
                          value: 50,
                          message: "Max 50 characters",
                        },
                      })}
                    />
                  </div>
                  <p className="text-red-600 text-xs">
                    {errors?.apartment?.message}
                  </p>
                </div>
              </div>

              <div className="flex mt-4 gap-x-5">
                <div className="w-1/2">
                  <div
                    className={`border ${
                      errors?.city?.message ? "border-red-600" : "border-black"
                    } relative  w-full rounded-[4px]`}
                  >
                    <p
                      className={`text-xs ${
                        errors?.city?.message ? "text-red-600" : "text-black"
                      } bg-white -top-[20%] left-2 leading-[100%] px-2  absolute `}
                    >
                      City*
                    </p>
                    <input
                      className="w-full outline-none p-[6px] rounded-[4px]"
                      alt=""
                      {...register("city", {
                        required: "City is required",
                        minLength: {
                          value: 2,
                          message: "Atleast 2 characters",
                        },
                        maxLength: {
                          value: 50,
                          message: "Max 50 characters",
                        },
                      })}
                    />
                  </div>
                  <p className="text-red-600 text-xs">
                    {errors?.city?.message}
                  </p>
                </div>

                <div className="w-1/2">
                  <div className="border border-black relative  w-full rounded-[4px]">
                    <p className="text-xs bg-white -top-[20%] left-2 leading-[100%] px-2  absolute ">
                      State*
                    </p>
                    <select
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                      value={state}
                      className="w-full outline-none p-[6px] rounded-[4px]"
                      alt=""
                    >
                      {states.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex mt-4 gap-x-5">
                <div className="w-1/2">
                  <div
                    className={`border ${
                      errors?.contact?.message
                        ? "border-red-600"
                        : "border-black"
                    } relative  w-full rounded-[4px]`}
                  >
                    <p
                      className={`text-xs ${
                        errors?.contact?.message ? "text-red-600" : "text-black"
                      } bg-white -top-[20%] left-2 leading-[100%] px-2  absolute `}
                    >
                      Mobile Number*
                    </p>
                    <input
                      className="w-full outline-none p-[6px] rounded-[4px]"
                      alt=""
                      {...register("contact", {
                        required: "Contact is required",
                        minLength: {
                          value: 10,
                          message: "Atleast 10 characters",
                        },
                        maxLength: {
                          value: 11,
                          message: "Max 11 characters",
                        },
                      })}
                    />
                  </div>
                  <p className="text-red-600 text-xs">
                    {errors?.contact?.message}
                  </p>
                </div>
                <div className="w-1/2">
                  <div className=" border border-black relative  w-full rounded-[4px]">
                    <p className="text-xs bg-white -top-[20%] left-2 leading-[100%] px-2  absolute ">
                      Company
                    </p>
                    <input
                      className="w-full outline-none p-[6px] rounded-[4px]"
                      alt=""
                      {...register("company", {})}
                    />
                  </div>
                </div>
              </div>
              <button className="mt-4 rounded-3xl  font-bold  border  px-8 block mx-auto py-3 bg-blue-600 text-white ">
                Confirm
              </button>
            </div>
          }
        </form>
      </div>
      <Footer />
      {processing && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center ">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default AddAddress;
