import React from "react";
import { useParams } from "react-router-dom";
import { convertText } from "../../../../utils/tools";
import Login from "./Login";
import GuestLogin from "./GuestLogin";

const Info = () => {
  const { id } = useParams();
  const [showPopUp, setShowPopUp] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row mx-5  mb-0 md:mx-20 md:my-0 ">
          <div className="flex flex-col w-2/5 my-10 md:w-2/6  ">
            <span className="mx-auto my-auto font-sans text-xl font-semibold uppercase md:text-2xl lg:text-3xl xl:text-4xl">
              Step-3
            </span>
          </div>
          <div className="flex flex-col w-3/5  mx-auto mt-5 md:w-1/2 ">
            <span className="my-4 ml-5 text-lg font-bold uppercase w-10/11 md:ml-0 md:text-2xl">
              Info & Add to Cart
            </span>
          </div>
        </div>
        <div className="space-y-4">
          {!isLoggedIn && (
            <div className="w-full ">
              <button
                type="button"
                onClick={() => setShowPopUp(true)}
                className="self-start block px-10 py-2 mx-auto text-2xl font-semibold text-white transition-all duration-300 ease-in-out bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Login{" "}
              </button>
            </div>
          )}
          <p className="text-xl font-bold text-center ">Or </p>
          <GuestLogin setIsLoggedIn={setIsLoggedIn} />
        </div>
        <div className="flex w-full h-32 mt-5 text-justify mb-44 md:my-5 md:text-sm md:px-10 max-w-4xl mx-auto ">
          By signing up, I confirm that I have read and agreed to Periapparel's
          User Agreement & Terms of Service. I also agree to signing up via
          text, you consent to receive marketing text messages from Periapperal
          at the number provided, including messages sent by auto dialer.
          Consent is not a condition of any purchase. You can unsubscribe at any
          time by replying STOP
        </div>
      </div>
      {showPopUp && <Login setShowPopUp={setShowPopUp} />}
    </>
  );
};

export default Info;
