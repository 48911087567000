import React, {useEffect} from "react";
import Navbar from "./navbar/Navbar";
import Footer from './footer/Footer'

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Navbar />
    <div className="px-3 sm:px-10 min-h-screen">
      
      <h1 className="text-2xl text-center font-bold mt-5">
        RETURNS and ORDER CANCELLATION POLICY
      </h1>
      <p className="mt-5 text-xl max-w-[750px] mx-auto text-justify">
        We are custom manufacturers, with orders made exclusively based on
        customer’s technical specification. We are not responsible for wrong
        color selection, spelling errors, or incorrect size inputs. We only
        proceed to production after the approval of the sample or approved tech
        pack. Any cancellation request must be made only through email to
        info@periapparel.com within 24 hours after the order is placed. No
        cancellation is allowed after 24 hours of the order placement.
        <br />
        <br />
        Any product defect should be notified to info@periapparel.com within 24
        hours of receiving the shipment. The replacement order or refund will be
        processed after the undamaged/unused product is received in the
        warehouse.
        <br />
        <br />
        Any problem or defect from heat adhesives or any other adhesives is not
        covered for any return or refund.
      </p>
    </div>
    <Footer/>
    </>
  );
};

export default RefundPolicy;
